import React, { useState } from "react";
import { styled, Grid, Typography, Button } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import CateringService from "../../../../../../services/catering.service";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { ca } from "date-fns/locale";

const Root = styled("div")(({ theme }) => ({
  ["& .header"]: {
    fontSize: 14,
    color: "#9e9e9e",
  },
  ["& .desc"]: {
    fontSize: 20,
  },
}));

const maintenanceType = ["", "งานซ่อมแซมทั่วไป", "งานไฟฟ้า", "งานประปา"];

const Result = ({ data, catering }) => {
  const [cateringType, setCateringType] = useState([]);
  console.log(data, "Result", cateringType);
  useEffect(() => {
    CateringService.getCateringType().then((res) => {
      if (res.data) {
        setCateringType(res.data.data);
      }
    });
  }, []);
  return (
    <Root>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography fontSize={32} textAlign="center">
          {catering
            ? "แก้ไขคำขออนุมัติจัดเลี้ยงสําเร็จ"
            : "เพิ่มคำขออนุมัติจัดเลี้ยงเรียบร้อย"}
        </Typography>
        <CheckCircleRoundedIcon
          style={{ color: "#4BB543", fontSize: "40px", marginLeft: "5px" }}
        />
      </div>
      {/* <Typography textAlign="center" marginBottom={2}>คำขอของคุณอยู่ระหว่างรอการยืนยัน</Typography> */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          sx={(theme) => ({
            display: "flex",
            boxShadow:
              "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
            border: "1px solid #EEEEEE",
            backgroundColor: "#FBFCFC",
            marginTop: "30px",
            // marginBottom: "20px",
            marginLeft: "0px",
            padding: "0 25px 25px 0",

            borderRadius: "15px",
            [theme.breakpoints.down("sm")]: {},
            [theme.breakpoints.down("md")]: {},
          })}
        >
          {catering ? (
            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                variant="contained"
                size="large"
                component={Link}
                to={`/catering/requested/${catering.idCateringRequest}`}
              >
                รายละเอียด
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                variant="contained"
                size="large"
                component={Link}
                to={`/home`}
              >
                กลับไปหน้าหลัก
              </Button>
            </Grid>
          )}
          <Grid container maxWidth={960} spacing={3}>
            {cateringType && (
              <Grid item xs={12} sm={6}>
                <Typography className="header">ประเภทงานจัดเลี้ยง</Typography>
                <Typography className="desc">
                  {
                    cateringType.find(
                      (x) => x.idCateringType === data?.cateringType
                    )?.Type
                  }
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Typography className="header">ชื่อผู้ขออนุมัติ</Typography>
              <Typography className="desc">{data?.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="header">เบอร์ติดต่อ</Typography>
              <Typography className="desc">{data?.phoneNumber}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="header">Email</Typography>
              <Typography className="desc">{data?.email}</Typography>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
							<Typography className="header">สถานที่</Typography>
							<Typography className="desc">{data?.location}</Typography>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography className="header">รายละเอียดสถานที่</Typography>
							<Typography className="desc">{data?.locationDetail}</Typography>
						</Grid> */}
            <Grid item xs={12} sm={6}>
              <Typography className="header">วันที่</Typography>
              <Typography className="desc">
                {dayjs(data?.date).locale("th").format("D MMMM YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="header">เวลา</Typography>
              <Typography className="desc">
                {data?.startTimeHour.toString().padStart(2, "0") +
                  ":" +
                  data?.startTimeMinute.toString().padStart(2, "0")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography className="header">สถานที่</Typography>
              <Typography className="desc">{data?.sendTo}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="header">ชื่อผู้ที่อนุมัติ</Typography>
              <Typography className="desc">{data?.nameApproved}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="header">บริษัทผู้ที่อนุมัติ</Typography>
              <Typography className="desc">{data?.companyApproved}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="header">งบประมาณการจัดเลี้ยง</Typography>
              <Typography className="desc">
                {data?.budget == null || data?.budget == ""
                  ? "-"
                  : data?.budget + " บาท"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="header">วัตถุประสงค์</Typography>
              <Typography className="desc">{data?.objective}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="header">
                รายการจัดเลี้ยงเพิ่มเติม
              </Typography>

              {data?.extraCatering == null ||
              data?.extraCatering.length <= 0 ? (
                <Typography className="desc">-</Typography>
              ) : (
                <Typography className="desc">
                  {data.extraCatering.map((x) => x.nameOption).join(", ")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="header">รายละเอียด</Typography>
              <Typography className="desc">
                {data?.description == null || data?.description == ""
                  ? "-"
                  : data?.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};

export default Result;
