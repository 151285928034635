import React, { useState } from "react";
import { styled, Grid, Typography, Button } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { Link } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const Root = styled("div")(({ theme }) => ({
  marginTop: 12,
  ["& .header"]: {
    fontSize: 14,
    color: "#9e9e9e",
  },
  ["& .desc"]: {
    fontSize: 20,
  },
}));

const maintenanceType = ["", "งานซ่อมแซมทั่วไป", "งานไฟฟ้า", "งานประปา"];

const Result = ({ data, maintenance }) => {
  console.log(data);
  return (
    <Root>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography fontSize={32} textAlign="center">
            {maintenance
              ? "แก้ไขงานซ่อมแซมเรียบร้อย"
              : "แจ้งงานซ่อมแซมเรียบร้อย"}
          </Typography>
          <CheckCircleRoundedIcon
            style={{ color: "#4BB543", fontSize: "40px", marginLeft: "5px" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography textAlign="center" marginBottom={2}>
            งานของคุณอยู่ระหว่างรอการยืนยัน
          </Typography>
        </Grid>

        <Grid
          container
          item
          spacing={3}
          sx={(theme) => ({
            display: "flex",
            boxShadow:
              "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
            border: "1px solid #EEEEEE",
            backgroundColor: "#FBFCFC",
            marginTop: "10px",
            padding: "0 25px 25px 0",
            marginBottom: "20px",
            marginInline: "150px",
            borderRadius: "15px",
            [theme.breakpoints.down("sm")]: {},
            marginInline: "10px",
            [theme.breakpoints.down("md")]: {
              marginInline: "20px",
            },
          })}
        >
          <Grid item xs={12} container justifyContent="flex-end">
            <Button
              variant="contained"
              size="large"
              component={Link}
              to={`/maintenances/detail/${data?.idMaintenances}`}
            >
              รายละเอียด
            </Button>
          </Grid>

          <Grid item xs={12} container justifyContent="center">
            <Grid container maxWidth={960} spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography className="header">ประเภทงานซ่อมแซม</Typography>
                <Typography className="desc">
                  {maintenanceType[data?.maintenanceType || 0]}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="header">ชื่อผู้แจ้ง</Typography>
                <Typography className="desc">{data?.requestorName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="header">เบอร์ติดต่อ</Typography>
                <Typography className="desc">{data?.phoneNumber}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="header">Email</Typography>
                <Typography className="desc">{data?.email}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="header">สถานที่</Typography>
                <Typography className="desc">{data?.location}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="header">รายละเอียดสถานที่</Typography>
                <Typography className="desc">{data?.locationDetail}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="header">วันที่และเวลา</Typography>
                <Typography className="desc">
                  {dayjs(data?.startDate)
                    .locale("th")
                    .format("D MMMM YYYY (hh.mmน.)")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="header">รายละเอียด</Typography>
                <Typography className="desc">{data?.description}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};

export default Result;
