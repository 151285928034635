import { httpClient } from "./httpClient";

const getAllCrossAreaCarPoolBookings = () => {
  return httpClient.get("cross_area_car_pool_bookings");
};

const getCrossAreaCarPoolBookingById = (id) => {
  return httpClient.get("cross_area_car_pool_booking/" + id);
};

const getCrossAreaCarPoolBookingByIdUser = (idUser) => {
  return httpClient.get("cross_area_car_pool_booking_byIdUser/" + idUser);
};

const getCrossAreaCarPoolBookingByIdDriver = (idDriver) => {
  return httpClient.get("cross_area_car_pool_booking_byIdDriver/" + idDriver);
};

const getCrossAreaCarPoolBookingByStartDate = (date) => {
  return httpClient.post("cross_area_car_pool_booking_ByStartDate", date);
};

const getCrossAreaCarPoolBookingByStartDateAndEndDate = (date) => {
  return httpClient.post(
    "cross_area_car_pool_booking_ByStartDateAndEndDate",
    date
  );
};

const postNewCrossAreaCarPoolBooking = (newpoolbooking) => {
  return httpClient.post("add_newcross_area_car_pool_booking", newpoolbooking);
};
const postUpdateCrossAreaCarPoolBooking = (newpoolbooking) => {
  return httpClient.post("update_cross_area_car_pool_booking", newpoolbooking);
};
const deleteCarCrossAreaCarPoolBooking = (delCar) => {
  return httpClient.post("delete_car_cross_area_car_pool_booking", delCar);
};
const postApprovedlCrossAreaCarPoolBooking = (approved) => {
  return httpClient.post("approved_cross_area_car_pool_booking", approved);
};

const getCrossAreaCarPoolBookingByIdUserForRating = (idUser) => {
  return httpClient.get(
    "cross_area_car_pool_booking_byIdUser_ForRating/" + idUser
  );
};

const getCrossAreaCarPoolBookingByFilter = (filter) => {
  return httpClient.post("cross_area_car_pool_booking_ByFilter", filter);
};

const getCrossAreaCarPoolBookingByFilterByIdDriver = (filter) => {
  return httpClient.post(
    "cross_area_car_pool_booking_ByFilter_ByIdDriver",
    filter
  );
};

export default {
  getAllCrossAreaCarPoolBookings,
  getCrossAreaCarPoolBookingById,
  getCrossAreaCarPoolBookingByIdUser,
  getCrossAreaCarPoolBookingByIdDriver,
  getCrossAreaCarPoolBookingByStartDate,
  getCrossAreaCarPoolBookingByStartDateAndEndDate,
  postNewCrossAreaCarPoolBooking,
  postUpdateCrossAreaCarPoolBooking,
  deleteCarCrossAreaCarPoolBooking,
  postApprovedlCrossAreaCarPoolBooking,
  getCrossAreaCarPoolBookingByIdUserForRating,
  getCrossAreaCarPoolBookingByFilter,
  getCrossAreaCarPoolBookingByFilterByIdDriver,
};
