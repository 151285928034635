import React, { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";

import { styled } from "@mui/styles";

import { Typography, Button, Grid, IconButton } from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ModalShowmap from "./showMap/index.js";

require("dayjs/locale/th");
dayjs.locale("th");

const DivCoverReturn = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: "20px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "80px",
}));

const BackButton = styled(Button)(({ theme }) => ({
  // position: "absolute",
  color: "black",
  fontSize: "20px",
  // left: "5vw",
}));

const EditButton = styled(IconButton)(({ theme }) => ({
  fontSize: "20px",

  "&:hover": {
    color: "black",
  },
}));

const DivButton = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "90%",
  maxWidth: "1100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: "30px",
}));

const DivContent = styled("div")(({ theme }) => ({
  width: "90%",
  maxWidth: "1100px",
  marginBottom: "50px",
  paddingBottom: "50px",
}));

const DivTopic = styled("div")(({ theme }) => ({
  display: "flex",
  borderBottom: "1px solid #1565c0",
  marginTop: "24px",
}));

const TextMainTopic = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "bold",
  color: theme.palette.text.primary,
}));

const TextTopic = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "bold",
}));

const TextData = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  marginLeft: "10px",
  color: theme.palette.text.primary,
}));

const DivTopicAndData = styled("div")(({ theme }) => ({
  display: "flex",
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  marginTop: "0px",
}));

const DivTypeOfFlight = styled("div")(({ theme }) => ({
  width: "calc(100% - 68px)",
  marginTop: "24px",
}));

const DivTextFlight = styled("div")(({ theme }) => ({
  width: "100%",
  borderBottom: "1px solid #DCDCDC",
  marginTop: "5px",
}));

const DivPurpose = styled("div")(({ theme }) => ({
  width: "calc(100% - 68px)",
  marginTop: "24px",
}));

const DivGettingPlace = styled("div")(({ theme }) => ({
  width: "calc(100% - 68px)",
  marginTop: "24px",
}));

const ViewButton = styled(Button)({
  border: "1px solid #00bb77",
  color: "black",
  width: "100%",
  fontSize: "20px",
  // backgroundColor: "#3970C2",
  color: "#00bb77",
});

const DetailDriverBooking = (props) => {
  const [booking, setBooking] = useState(null);
  const [typeCar, setTypeCar] = useState(null);
  const [passenger, setPassenger] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (props.location.state === undefined) {
      props.history.push("/admin/driverBooking_page");
    } else {
      setBooking(props.location.state.booking);
      //   let booking_temp = props.location.state.booking;
      //   siteService
      //     .getLocationDriverById(booking_temp.idDriver)
      //     .then((res) => {
      //       if (res.data) {
      //         booking_temp.LatDriver = res.data[0].LatDriver;
      //         booking_temp.LngDriver = res.data[0].LngDriver;
      //         setBooking(booking_temp);
      //       }
      //     })
      //     .catch((error) => {
      //       booking_temp.LatDriver = 0;
      //       booking_temp.LngDriver = 0;
      //       setBooking(booking_temp);
      //       console.log(error);
      //     });

      //   VehicleTypeService.getVehicleTypeById(
      //     props.location.state.booking.idTypeCar
      //   )
      //     .then((res) => {
      //       if (res.data) {
      //         setTypeCar(res.data);
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
    }
  }, [props.location.state]);

  const onCloseModalHandle = () => {
    setOpenModal(false);
  };

  const onClickEdit = () => {
    props.history.push(`/driverbooking`, {
      booking: booking,
    });
  };

  const onClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleEmpty = (data) => {
    if (data === null || data === undefined || data === "") {
      return "-";
    } else {
      return data;
    }
  };

  return (
    <>
      {booking && (
        <DivCoverReturn>
          <DivButton>
            <BackButton onClick={() => props.history.goBack()}>
              <ArrowBackIosNewIcon /> Back
            </BackButton>
            <EditButton
              // disableRipple
              // component={Link}
              // to={`/maintenances/request`}
              onClick={onClickEdit}
            >
              <EditRoundedIcon
                className="Icon"
                // style={{ color: "#99A8B2" }}
              />
            </EditButton>
          </DivButton>
          <Title>จองคนขับ</Title>

          <DivContent>
            <DivTopic>
              <TextMainTopic>ผู้จอง</TextMainTopic>
            </DivTopic>
            <GridContainer container spacing={3}>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>ชื่อ :</TextTopic>
                  <TextData>{booking.nameUser}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>เบอร์โทรศัพท์ :</TextTopic>
                  <TextData>{booking.telephoneMobile}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>Email :</TextTopic>
                  <TextData>{booking.email}</TextData>
                </DivTopicAndData>
              </Grid>
            </GridContainer>
            <DivTopic>
              <TextMainTopic>รายละเอียดการจอง</TextMainTopic>
            </DivTopic>
            <DivTypeOfFlight>
              <DivTopicAndData>
                <TextTopic>ประเภทการเดินทาง :</TextTopic>
                <TextData>
                  {booking.twoWay ? "ไป-กลับ" : "เที่ยวเดียว"}
                </TextData>
              </DivTopicAndData>
            </DivTypeOfFlight>
            <GridContainer container spacing={3}>
              {booking.twoWay == true && (
                <Grid item xs={12}>
                  <DivTextFlight>
                    <TextTopic>ขาไป</TextTopic>
                  </DivTextFlight>
                </Grid>
              )}
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>ต้นทาง :</TextTopic>
                  <TextData>{booking.namePlaceFrom}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>ปลายทาง :</TextTopic>
                  <TextData>{booking.namePlaceTo}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>วันที่เดินทาง :</TextTopic>
                  <TextData>
                    {dayjs(booking.startDate).format("DD MMMM YYYY")}
                  </TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>เวลาเริ่มเดินทาง :</TextTopic>
                  <TextData>{`${booking.startTime} น.`}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>วันที่ถึงปลายทาง :</TextTopic>
                  <TextData>
                    {dayjs(booking.endDate).format("DD MMMM YYYY")}
                  </TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>เวลาถึงปลายทาง :</TextTopic>
                  <TextData>{`${booking.endTime} น.`}</TextData>
                </DivTopicAndData>
              </Grid>

              {booking.twoWay == true && (
                <>
                  <Grid item xs={12}>
                    <DivTextFlight>
                      <TextTopic>ขากลับ</TextTopic>
                    </DivTextFlight>
                  </Grid>

                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>ต้นทาง :</TextTopic>
                      <TextData>{booking.namePlaceFromReturn}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>ปลายทาง :</TextTopic>
                      <TextData>{booking.namePlaceToReturn}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>วันที่เดินทาง :</TextTopic>
                      <TextData>
                        {dayjs(booking.startDateReturn).format("DD MMMM YYYY")}
                      </TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>เวลาเริ่มเดินทาง :</TextTopic>
                      <TextData>{`${booking.startTimeReturn} น.`}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>วันที่ถึงปลายทาง :</TextTopic>
                      <TextData>
                        {dayjs(booking.endDateReturn).format("DD MMMM YYYY")}
                      </TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>เวลาถึงปลายทาง :</TextTopic>
                      <TextData>{`${booking.endTimeReturn} น.`}</TextData>
                    </DivTopicAndData>
                  </Grid>
                </>
              )}

              {booking.detailJourney && (
                <Grid item md={4}>
                  <DivTopicAndData>
                    <TextTopic>รายละเอียดการเดินทาง :</TextTopic>
                    <TextData>{booking.detailJourney}</TextData>
                  </DivTopicAndData>
                </Grid>
              )}
              {booking.note && (
                <Grid item md={12}>
                  <DivTopicAndData>
                    <TextTopic>รายละเอียดเพิ่มเติม :</TextTopic>
                    <TextData>{booking.note}</TextData>
                  </DivTopicAndData>
                </Grid>
              )}
            </GridContainer>
            <DivTopic>
              <TextMainTopic>รายละเอียดผู้จอง</TextMainTopic>
            </DivTopic>
            <GridContainer container spacing={3}>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>แผนก :</TextTopic>
                  <TextData>{handleEmpty(booking.sectionName)}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>ส่วน :</TextTopic>
                  <TextData>{handleEmpty(booking.departmentName)}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>บริษัท :</TextTopic>
                  <TextData>{handleEmpty(booking.companyName)}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>Cost Center :</TextTopic>
                  <TextData>{handleEmpty(booking.costCenterCharge)}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item xs={4}>
                <DivTopicAndData>
                  <TextTopic>Cost Element :</TextTopic>
                  <TextData>{handleEmpty(booking.costElementCharge)}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item xs={12}>
                <ViewButton onClick={onClickOpenModal}>แสดงแผนที่</ViewButton>
              </Grid>
            </GridContainer>
          </DivContent>
          <ModalShowmap
            open={openModal}
            onCloseHandle={onCloseModalHandle}
            inforInCar={booking}
          />
          <div style={{ height: "100px" }}></div>
        </DivCoverReturn>
      )}
    </>
  );
};

export default DetailDriverBooking;
