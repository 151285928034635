import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Typography, styled } from "@mui/material";
import {
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
  FormControlLabel,
  Switch,
  RadioGroup,
  Radio,
  FormLabel,
  FormHelperText,
  Divider,
  IconButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import GoogleMap from "../../shared/googleWithAutoComplete/GoogleMap";
import { SnackbarProvider, useSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Button from "@mui/material/Button";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LoadingButton } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDayjs";

import DriverBookingService from "../../../../services/driverBooking.service";
import { getUserProfile } from "../../../../actions/user";

const StyledRoot = styled("div")(({ theme }) => ({
  "& .divCoverReturn": {
    width: "100%",
    display: "grid",
    // flexDirection: "column",
    // alignItems: "center",
    // minWidth: "1600px",
    // ["@media only screen and (max-width: 600px)"]: {
    //   width: "90%",
    //   minWidth: "80px",
    //   margin: "0 auto",
    //   padding: 10,
    // },
  },
  "& .Title": {
    textAlign: "center",
    fontSize: "30px",
    marginTop: "100px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "80px",
    },
  },
  "& .SubTitle": {
    fontSize: "25px",
  },
  "& .SubTitle2": {
    fontSize: "15px",
  },
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
  "& .map": {
    width: "100%",
    height: "300px",
  },

  // "& .form": {
  //   width: "100%",
  //   zIndex: "1",
  // },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  marginTop: "100px",
  // position: "absolute",
  // left: "10px",
  // top: "13px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "80px",
  },
}));

const generateTimeOptions = (startTime = "-01:30") => {
  const times = [];
  const [startHour, startMinute] = startTime.split(":").map(Number);
  let startMinutes = startHour * 60 + startMinute + 30; // Adding 30 minutes

  for (let i = 0; i < 48; i++) {
    let hour = Math.floor(startMinutes / 60);
    let minute = startMinutes % 60;
    if (hour < 24) {
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      times.push(`${hour}:${minute}`);
      startMinutes += 30;
    }
  }
  return times;
};
const DriverBooking = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { user: AllUsers } = useSelector((state) => state.auth);
  const [booking, setBooking] = useState(
    props.location.state ? props.location.state.booking : null
  );
  const [startDate, setStartDate] = useState(
    props.location.state ? props.location.state.booking.startDate : new Date()
  );
  const [endDate, setEndDate] = useState(
    props.location.state ? props.location.state.booking.endDate : null
  );
  const [startDateReturn, setStartDateReturn] = useState(
    props.location.state ? props.location.state.booking.startDateReturn : null
  );
  const [endDateReturn, setEndDateReturn] = useState(
    props.location.state ? props.location.state.booking.endDateReturn : null
  );
  const [timeStart, settimeStart] = useState(
    props.location.state ? props.location.state.booking.startTime : null
  );
  const [timeEnd, settimeEnd] = useState(
    props.location.state ? props.location.state.booking.endTime : null
  );
  const [timeStartReturn, settimeStartReturn] = useState(
    props.location.state ? props.location.state.booking.startTimeReturn : null
  );
  const [timeEndReturn, settimeEndReturn] = useState(
    props.location.state ? props.location.state.booking.endTimeReturn : null
  );
  const [dataFrom, setDataFrom] = useState(
    props.location.state ? props.location.state.booking.namePlaceFrom : ""
  );
  const [dataTo, setDataTo] = useState(
    props.location.state ? props.location.state.booking.namePlaceTo : ""
  );
  const [dataFromReturn, setDataFromReturn] = useState(
    props.location.state ? props.location.state.booking.namePlaceFromReturn : ""
  );
  const [dataToReturn, setDataToReturn] = useState(
    props.location.state ? props.location.state.booking.namePlaceToReturn : ""
  );
  const [helperText, setHelperText] = useState("");
  const [error, setError] = useState(false);
  const [twoWay, setTwoWay] = useState(
    props.location.state ? props.location.state.booking.twoWay : false
  );
  const { enqueueSnackbar } = useSnackbar();

  const [endTimeOptions, setEndTimeOptions] = useState(generateTimeOptions());
  const [endTimeReturnOptions, setEndTimeReturnOptions] = useState(
    generateTimeOptions()
  );

  const [helperTexttimeStart, setHelperTexttimeStart] = useState("");
  const [hetHelperTexttimeEnd, setHelperTexttimeEnd] = useState("");
  const [helperTexttimeStartReturn, setHelperTexttimeStartReturn] =
    useState("");
  const [hetHelperTexttimeEndReturn, setHelperTexttimeEndReturn] = useState("");

  useEffect(() => {
    if (!AllUsers) {
      dispatch(getUserProfile());
    }
    setValue("twoWay", twoWay);
    if (props.location.state) {
      const booking = props.location.state.booking;
      setValue("idDriverBooking", booking.idDriverBooking);
      setValue("idUser", booking.idUser);
      setValue("fromPlace", booking.namePlaceFrom);
      setValue("toPlace", booking.namePlaceTo);
      setValue("fromPlaceReturn", booking.namePlaceFromReturn);
      setValue("toPlaceReturn", booking.namePlaceToReturn);
      setValue("startDate", booking.startDate);
      setValue("endDate", booking.endDate);
      setValue("startTime", booking.startTime);
      setValue("endTime", booking.endTime);
      if (booking.twoWay) {
        setValue("startDateReturn", booking.startDateReturn);
        setValue("endDateReturn", booking.endDateReturn);
        setValue("startTimeReturn", booking.startTimeReturn);
        setValue("endTimeReturn", booking.endTimeReturn);
      }

      setValue("detailJourney", booking.detailJourney);
      setValue("note", booking.note);
    }
  }, []);

  useEffect(() => {
    const newEndTimeOptions = generateTimeOptions(getValues("startTime"));
    setEndTimeOptions(newEndTimeOptions);
  }, [watch("startTime")]);

  useEffect(() => {
    const newEndTimeReturnOptions = generateTimeOptions(
      getValues("startTimeReturn")
    );
    setEndTimeReturnOptions(newEndTimeReturnOptions);
  }, [watch("startTimeReturn")]);

  const onSubmitHandler = async (data) => {
    if (booking) {
      console.log(booking);
      setHelperText("");
      setError(false);
      const senddata = {
        idDriverBooking: booking.idDriverBooking,
        namePlaceFrom: data.fromPlace,
        namePlaceTo: data.toPlace,
        namePlaceFromReturn: twoWay ? data.fromPlaceReturn : null,
        namePlaceToReturn: twoWay ? data.toPlaceReturn : null,
        twoWay: data.twoWay,
        note: data.note,
        detailJourney: data.detailJourney,
        startDate: data.startDate,
        startTime: timeStart,
        endDate: data.endDate,
        endTime: timeEnd,
        startDateReturn: twoWay ? data.startDateReturn : null,
        startTimeReturn: twoWay ? timeStartReturn : null,
        endDateReturn: twoWay ? data.endDateReturn : null,
        endTimeReturn: twoWay ? timeEndReturn : null,
        idUser: data.idUser,
        option: "คนขับรถอย่างเดียว",
      };
      console.log(data);
      console.log(senddata);
      DriverBookingService.postUpdateDriverBooking(senddata)
        .then((res) => {
          if (res.status === 200) {
            enqueueSnackbar("update driver booking success", {
              variant: "success",
            });
            props.history.push(`/admin/driverBooking_page`);
            // props.history.goBack();
          } else {
            enqueueSnackbar("update driver booking failed", {
              variant: "error",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar("update driver booking failed", { variant: "error" });
        });
    } else {
      console.log(data);
      // console.log(data, option);
      console.log(timeStart, timeEnd, timeStartReturn, timeEndReturn);
      console.log(twoWay);
      if (
        timeStart == null ||
        timeEnd == null ||
        (timeStartReturn == null && twoWay) ||
        (timeEndReturn == null && twoWay)
      ) {
        if (timeStart == null) {
          setHelperTexttimeStart("กรุณาระบุข้อมูลให้ถูกต้อง");
          setError(true);
        }
        if (timeEnd == null) {
          setHelperTexttimeEnd("กรุณาระบุข้อมูลให้ถูกต้อง");
          setError(true);
        }

        if (timeStartReturn == null && twoWay) {
          setHelperTexttimeStartReturn("กรุณาระบุข้อมูลให้ถูกต้อง");
          setError(true);
        }

        if (timeEndReturn == null && twoWay) {
          setHelperTexttimeEndReturn("กรุณาระบุข้อมูลให้ถูกต้อง");
          setError(true);
        }
      } else {
        setHelperText("");
        setError(false);
        const senddata = {
          namePlaceFrom: data.fromPlace,
          namePlaceTo: data.toPlace,
          namePlaceFromReturn: twoWay ? data.fromPlaceReturn : null,
          namePlaceToReturn: twoWay ? data.toPlaceReturn : null,
          twoWay: data.twoWay,
          note: data.note,
          detailJourney: data.detailJourney,
          startDate: data.startDate,
          startTime: timeStart,
          endDate: data.endDate,
          endTime: timeEnd,
          startDateReturn: twoWay ? data.startDateReturn : null,
          startTimeReturn: twoWay ? timeStartReturn : null,
          endDateReturn: twoWay ? data.endDateReturn : null,
          endTimeReturn: twoWay ? timeEndReturn : null,
          idUser: AllUsers.idUser,
          option: "คนขับรถอย่างเดียว",
        };
        console.log(data);
        console.log(senddata);
        DriverBookingService.postNewDriverBooking(senddata)
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar("book driver success", { variant: "success" });
              // props.history.push(`/myListOfRequest/driver_booking`);
              props.history.push(`/home`);
            } else {
              enqueueSnackbar("book driver failed", { variant: "error" });
            }
          })
          .catch((err) => {
            enqueueSnackbar("book driver failed", { variant: "error" });
          });
      }
    }

    // MeetingRoomService.postNewMeetingRoom(data, fields, imageUrlForDB);
    // console.log("check date" + JSON.stringify(data) + data.phoneManager);
  };
  const handleChangeStart = (newValue) => {
    settimeStart(newValue);
  };
  const handleChangeEnd = (newValue) => {
    settimeEnd(newValue);
  };

  const handleChangeStartReturn = (newValue) => {
    settimeStartReturn(newValue);
  };
  const handleChangeEndReturn = (newValue) => {
    settimeEndReturn(newValue);
  };

  const getDataFromGoogleMapFromPlace = (data) => {
    setDataFrom(data);
    setValue("fromPlace", data);
    setDataToReturn(data);
    setValue("toPlaceReturn", data);
  };
  const getDataFromGoogleMapToplace = (data) => {
    setDataTo(data);
    setValue("toPlace", data);
    setDataFromReturn(data);
    setValue("fromPlaceReturn", data);
  };

  const getDataFromGoogleMapFromReturnPlace = (data) => {
    setDataFromReturn(data);
    setValue("fromPlaceReturn", data);
  };
  const getDataFromGoogleMapToReturnplace = (data) => {
    setDataToReturn(data);
    setValue("toPlaceReturn", data);
  };

  return (
    <StyledRoot>
      <Container maxWidth="xl">
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <BackButton
                onClick={() => {
                  props.history.goBack();
                }}
              >
                <ArrowBackIcon className="Icon" style={{ color: "#99A8B2" }} />
              </BackButton>
              <Typography fontSize={24} fontWeight={500} className="Title">
                {booking ? "แก้ไขการจองคนขับรถ" : "จองคนขับรถ"}
              </Typography>
              <div></div>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography fontSize={18} fontWeight={300} className="SubTitle">
                  ขาไป
                </Typography>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography className="SubTitle2">เที่ยวเดียว</Typography>
                  <Switch
                    checked={twoWay}
                    onChange={() => {
                      setValue("twoWay", !twoWay);
                      setTwoWay(!twoWay);
                    }}
                  />
                  <Typography className="SubTitle2">ไป - กลับ</Typography>
                </div>
              </Grid>

              <Divider />
            </Grid>
            <Grid
              container
              item
              spacing={3}
              xs={12}
              sx={(theme) => ({
                display: "flex",
                boxShadow:
                  "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                marginTop: "10px",
                marginBottom: "20px",
                marginLeft: "0px",
                padding: "0 25px 25px 0",

                borderRadius: "15px",
                [theme.breakpoints.down("sm")]: {},
                [theme.breakpoints.down("md")]: {},
              })}
            >
              <Grid item xs={12} md={6} lg={6} style={{ marginBottom: "80px" }}>
                <InputLabel style={{ marginBottom: "10px" }}>
                  {"ต้นทาง"}
                </InputLabel>
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        placeholder="ระบุต้นทาง"
                        variant="outlined"
                        error={errors.fromPlace ? true : false}
                        helperText={
                          errors.fromPlace ? "กรุณาเลือกจุดต้นทาง" : false
                        }
                        fullWidth
                        disabled
                        multiline
                        value={propsInController.field.value}
                        onChange={propsInController.field.onChange}
                        onBlur={(e) => {
                          if (
                            getValues("toPlaceReturn") === "" ||
                            getValues("toPlaceReturn") === undefined
                          ) {
                            setValue("toPlaceReturn", e.target.value);
                          }
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    )}
                    name="fromPlace"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                  />
                </Grid>
                <div className="map">
                  <GoogleMap
                    name="fromPlace"
                    inputProps={{
                      ...register("fromPlace", { required: true }),
                    }}
                    value={dataFrom}
                    handleGetDataFromGoogleMap={getDataFromGoogleMapFromPlace}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ marginBottom: "80px" }}>
                <InputLabel style={{ marginBottom: "10px" }}>
                  {"ปลายทาง"}
                </InputLabel>
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        placeholder="ระบุปลายทาง"
                        variant="outlined"
                        error={errors.toPlace ? true : false}
                        helperText={
                          errors.toPlace ? "กรุณาเลือกจุดปลายทาง" : false
                        }
                        fullWidth
                        disabled
                        multiline
                        value={propsInController.field.value}
                        onChange={propsInController.field.onChange}
                        onBlur={(e) => {
                          if (
                            getValues("fromPlaceReturn") === "" ||
                            getValues("fromPlaceReturn") === undefined
                          ) {
                            setValue("fromPlaceReturn", e.target.value);
                          }
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    )}
                    name="toPlace"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                  />
                </Grid>
                <div className="map">
                  <GoogleMap
                    name="toPlace"
                    inputProps={{ ...register("toPlace", { required: true }) }}
                    value={dataTo}
                    handleGetDataFromGoogleMap={getDataFromGoogleMapToplace}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                  <Controller
                    name="startDate"
                    control={control}
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MobileDatePicker
                        {...field}
                        label="วันที่รับต้นทาง"
                        minDate={new Date()}
                        value={startDate}
                        onChange={(date) => {
                          if (date > endDate) {
                            setEndDate(date);
                          }
                          setStartDate(date);
                          field.onChange(date);
                        }}
                        inputFormat="DD MMMM YYYY"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={
                              errors.startDate
                                ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                                : false
                            }
                            error={errors.startDate ? true : false}
                            sx={{
                              ".MuiInputBase-root": {
                                borderRadius: "10px",
                              },
                            }}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  render={(propsInController) => (
                    <FormControl error={error} fullWidth>
                      <InputLabel shrink>{"เวลารับต้นทาง"}</InputLabel>
                      <Select
                        native
                        label="เวลารับต้นทาง"
                        value={propsInController.field.value}
                        onChange={(e) => {
                          propsInController.field.onChange(e.target.value);
                          handleChangeStart(e.target.value);
                        }}
                        style={{ borderRadius: "10px" }}
                      >
                        {generateTimeOptions().map((time, index) => {
                          if (time !== "23:30")
                            return (
                              <option key={index} value={time}>
                                {time}
                              </option>
                            );
                        })}
                      </Select>
                      <FormHelperText>{helperTexttimeStart}</FormHelperText>
                    </FormControl>
                  )}
                  name="startTime"
                  control={control}
                  helperText={
                    errors.startTime ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                  }
                  error={errors.startTime ? true : false}
                  defaultValue="00:00"
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                  <Controller
                    name="endDate"
                    control={control}
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MobileDatePicker
                        {...field}
                        label="วันที่ส่งปลายทาง"
                        minDate={startDate}
                        value={endDate}
                        onChange={(date) => {
                          if (date > startDateReturn) {
                            setStartDateReturn(date);
                          }
                          setEndDate(date);
                          field.onChange(date);
                        }}
                        inputFormat="DD MMMM YYYY"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={
                              errors.endDate
                                ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                                : false
                            }
                            error={errors.endDate ? true : false}
                            sx={{
                              ".MuiInputBase-root": {
                                borderRadius: "10px",
                              },
                            }}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  render={(propsInController) => (
                    <FormControl error={error} fullWidth>
                      <InputLabel shrink>{"เวลาส่งปลายทาง"}</InputLabel>
                      <Select
                        native
                        label="เวลาส่งปลายทาง"
                        value={propsInController.field.value}
                        onChange={(e) => {
                          propsInController.field.onChange(e.target.value);
                          handleChangeEnd(e.target.value);
                        }}
                        style={{ borderRadius: "10px" }}
                      >
                        {endTimeOptions.map((time, index) => (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        ))}
                      </Select>
                      <FormHelperText>{hetHelperTexttimeEnd}</FormHelperText>
                    </FormControl>
                  )}
                  name="endTime"
                  control={control}
                  defaultValue="00:00"
                />
              </Grid>
            </Grid>

            {twoWay ? (
              <>
                <Grid item xs={12} style={{ marginTop: "10px" }}>
                  <Typography
                    fontSize={18}
                    fontWeight={300}
                    className="SubTitle"
                  >
                    ขากลับ
                  </Typography>
                  <Divider />
                </Grid>
                <Grid
                  container
                  item
                  spacing={3}
                  xs={12}
                  sx={(theme) => ({
                    display: "flex",
                    boxShadow:
                      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                    border: "1px solid #EEEEEE",
                    backgroundColor: "#FBFCFC",
                    marginTop: "10px",
                    marginBottom: "20px",
                    marginLeft: "0px",
                    padding: "0 25px 25px 0",

                    borderRadius: "15px",
                    [theme.breakpoints.down("sm")]: {},
                    [theme.breakpoints.down("md")]: {},
                  })}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    style={{ marginBottom: "80px" }}
                  >
                    <InputLabel style={{ marginBottom: "10px" }}>
                      {"ต้นทาง"}
                    </InputLabel>
                    <Grid item xs={12} sm={12} lg={12}>
                      <Controller
                        render={(propsInController) => (
                          <TextField
                            placeholder="ระบุต้นทาง"
                            variant="outlined"
                            error={errors.fromPlaceReturn ? true : false}
                            helperText={
                              errors.fromPlaceReturn
                                ? "กรุณาเลือกจุดต้นทาง"
                                : false
                            }
                            fullWidth
                            disabled
                            multiline
                            value={propsInController.field.value}
                            onChange={propsInController.field.onChange}
                            InputProps={{
                              style: {
                                borderRadius: "10px",
                              },
                            }}
                          />
                        )}
                        name="fromPlaceReturn"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                      />
                    </Grid>
                    <div className="map">
                      <GoogleMap
                        name="fromPlaceReturn"
                        inputProps={{
                          ...register("fromPlaceReturn"),
                        }}
                        value={dataFromReturn}
                        handleGetDataFromGoogleMap={
                          getDataFromGoogleMapFromReturnPlace
                        }
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    style={{ marginBottom: "80px" }}
                  >
                    <InputLabel style={{ marginBottom: "10px" }}>
                      {"ปลายทาง"}
                    </InputLabel>
                    <Grid item xs={12} sm={12} lg={12}>
                      <Controller
                        render={(propsInController) => (
                          <TextField
                            placeholder="ระบุปลายทาง"
                            variant="outlined"
                            error={errors.toPlaceReturn ? true : false}
                            helperText={
                              errors.toPlaceReturn
                                ? "กรุณาเลือกจุดต้นทาง"
                                : false
                            }
                            fullWidth
                            disabled
                            multiline
                            value={propsInController.field.value}
                            onChange={propsInController.field.onChange}
                            InputProps={{
                              style: {
                                borderRadius: "10px",
                              },
                            }}
                          />
                        )}
                        name="toPlaceReturn"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                      />
                    </Grid>
                    <div className="map">
                      <GoogleMap
                        name="toPlaceReturn"
                        inputProps={{
                          ...register("toPlaceReturn"),
                        }}
                        value={dataToReturn}
                        handleGetDataFromGoogleMap={
                          getDataFromGoogleMapToReturnplace
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      locale="th"
                    >
                      <Controller
                        name="startDateReturn"
                        control={control}
                        defaultValue={null}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MobileDatePicker
                            {...field}
                            label="วันที่รับต้นทาง"
                            minDate={endDate}
                            value={startDateReturn}
                            onChange={(date) => {
                              if (date > endDateReturn) {
                                setEndDateReturn(date);
                              }
                              setStartDateReturn(date);
                              field.onChange(date);
                            }}
                            inputFormat="DD MMMM YYYY"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={
                                  errors.startDateReturn
                                    ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                                    : false
                                }
                                error={errors.startDateReturn ? true : false}
                                sx={{
                                  ".MuiInputBase-root": {
                                    borderRadius: "10px",
                                  },
                                }}
                                fullWidth
                              />
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Controller
                      render={(propsInController) => (
                        <FormControl error={error} fullWidth>
                          <InputLabel shrink>{"เวลารับต้นทาง"}</InputLabel>
                          <Select
                            native
                            label="เวลารับต้นทาง"
                            value={propsInController.field.value}
                            onChange={(e) => {
                              propsInController.field.onChange(e.target.value);
                              handleChangeStartReturn(e.target.value);
                            }}
                            style={{ borderRadius: "10px" }}
                          >
                            {generateTimeOptions().map((time, index) => {
                              if (time !== "23:30")
                                return (
                                  <option key={index} value={time}>
                                    {time}
                                  </option>
                                );
                            })}
                          </Select>
                          <FormHelperText>
                            {helperTexttimeStartReturn}
                          </FormHelperText>
                        </FormControl>
                      )}
                      name="startTimeReturn"
                      control={control}
                      helperText={
                        errors.startTimeReturn
                          ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                          : false
                      }
                      error={errors.startTimeReturn ? true : false}
                      defaultValue="00:00"
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      locale="th"
                    >
                      <Controller
                        name="endDateReturn"
                        control={control}
                        defaultValue={null}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MobileDatePicker
                            {...field}
                            label="วันที่ส่งปลายทาง"
                            minDate={startDateReturn}
                            value={endDateReturn}
                            onChange={(date) => {
                              setEndDateReturn(date);
                              field.onChange(date);
                            }}
                            inputFormat="DD MMMM YYYY"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={
                                  errors.endDateReturn
                                    ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                                    : false
                                }
                                error={errors.endDateReturn ? true : false}
                                sx={{
                                  ".MuiInputBase-root": {
                                    borderRadius: "10px",
                                  },
                                }}
                                fullWidth
                              />
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Controller
                      render={(propsInController) => (
                        <FormControl error={error} fullWidth>
                          <InputLabel shrink>{"เวลาส่งปลายทาง"}</InputLabel>
                          <Select
                            native
                            label="เวลาส่งปลายทาง"
                            value={propsInController.field.value}
                            onChange={(e) => {
                              propsInController.field.onChange(e.target.value);
                              handleChangeEndReturn(e.target.value);
                            }}
                            style={{ borderRadius: "10px" }}
                          >
                            {endTimeReturnOptions.map((time, index) => (
                              <option key={index} value={time}>
                                {time}
                              </option>
                            ))}
                          </Select>
                          <FormHelperText>
                            {hetHelperTexttimeEndReturn}
                          </FormHelperText>
                        </FormControl>
                      )}
                      name="endTimeReturn"
                      control={control}
                      defaultValue="00:00"
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}
            <Grid
              container
              item
              spacing={3}
              xs={12}
              sx={(theme) => ({
                display: "flex",
                boxShadow:
                  "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                marginTop: "10px",
                marginBottom: "20px",
                marginLeft: "0px",
                padding: "0 25px 25px 0",

                borderRadius: "15px",
                [theme.breakpoints.down("sm")]: {},
                [theme.breakpoints.down("md")]: {},
              })}
            >
              <Grid item xs={12} md={6} lg={12}>
                <TextField
                  label="รายละเอียดการเดินทาง"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  inputProps={{ ...register("detailJourney") }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                      // padding: "50px",
                      // padding: "30px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={12}>
                <TextField
                  label="หมายเหตุ"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  inputProps={{ ...register("note") }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                      // padding: "50px",
                      // padding: "30px",
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px",
              }}
            >
              <div className="NavButton">
                <Button
                  className="Button"
                  variant="contained"
                  type="submit"
                  style={{
                    maxWidth: "200px",
                    maxHeight: "70px",
                    marginTop: "5px",
                    borderRadius: "15px",
                    fontSize: "18px",
                    backgroundColor: "green",
                  }}
                >
                  ยืนยัน
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
    </StyledRoot>
  );
};
export default DriverBooking;
