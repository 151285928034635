import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  styled,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  Box,
  Chip,
  OutlinedInput,
  Divider,
  IconButton,
} from "@mui/material";
import Container from "@mui/material/Container";
import { SnackbarProvider, useSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DateAdapter from "@mui/lab/AdapterDayjs";
import "dayjs/locale/th";
import {
  getOptionHours,
  getOptionMaintenanceTypes,
  getOptionMinutes,
} from "../../../../../../utils/constant";
import UserService from "../../../../../../services/user.service";
import CateringService from "../../../../../../services/catering.service";

import Dropfile from "../../../../shared/Form/DropFile";
import employeeService from "../../../../../../services/employee.service";
import cateringService from "../../../../../../services/catering.service";
import { ca } from "date-fns/locale";
import { set } from "date-fns";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  maxWidth: 1600,
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const Form = ({ foodData, onSuccessHandler, catering, additionalOptions }) => {
  const location = useLocation();

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm();
  const [isEmployee, setEmployee] = useState(null);
  const [isCateringType, setCateringType] = useState(null);
  const [selected, setSelected] = useState(additionalOptions);
  const [additionalOption, setAdditionalOption] = useState(null);
  const [menurestaurant, setmenurestaurant] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    if (!catering) {
      console.log(data);
      CateringService.addRequestCatering(data, selected, null)
        .then((res) => {
          if (res.status === 200) {
            data.extraCatering = selected;
            onSuccessHandler(data);
            enqueueSnackbar("Add Request Catering Success", {
              variant: "success",
            });
            window.scroll(0, 0);
          } else {
            enqueueSnackbar("Add Request Catering Fail", {
              variant: "error",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar("Update Request Catering Fail", {
            variant: "error",
          });
        });
    } else {
      console.log(data);
      console.log(selected);
      CateringService.updateRequestCatering(data, selected, null)
        .then((res) => {
          if (res.status === 200) {
            data.extraCatering = selected;
            onSuccessHandler(data);
            enqueueSnackbar("Update Request Catering Success", {
              variant: "success",
            });
            window.scroll(0, 0);
          } else {
            enqueueSnackbar("Update Request Catering Fail", {
              variant: "error",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar("Update Request Catering Fail", {
            variant: "error",
          });
        });
    }
  };
  const selectionChangeHandler = (event) => {
    let temp = [];
    let find = false;
    if (selected.length > 0) {
      for (let i = 0; i < selected.length; i++) {
        if (
          selected[i].idCateringAdditionalOption !==
            event.target.value[event.target.value.length - 1]
              .idCateringAdditionalOption &&
          selected[i].nameOption !==
            event.target.value[event.target.value.length - 1].nameOption
        ) {
          temp.push(event.target.value[i]);
        } else {
          find = true;
        }
      }
    } else {
      temp.push(event.target.value[event.target.value.length - 1]);
      find = true;
    }
    if (!find) {
      temp.push(event.target.value[event.target.value.length - 1]);
    }

    setSelected(temp);
  };

  useEffect(() => {
    employeeService.getEmployeesByIdApproved(currentUser.idUser).then((res) => {
      if (res.data) {
        setEmployee(res.data);
      }
    });
    CateringService.getCateringAdditionalOption()
      .then((res) => {
        if (res.data) {
          setAdditionalOption(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    CateringService.getCateringType()
      .then((res) => {
        if (res.data) {
          setCateringType(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (catering) {
      const Approved = {
        idApproved: catering.idApproved,
        firstname_TH: catering.nameApproved.split(" ")[0],
        lastname_TH: catering.nameApproved.split(" ")[1],
      };
      const Hour = parseInt(catering.time.split(":")[0]);

      const Minute = parseInt(catering.time.split(":")[1]);
      setValue("idCateringRequest", catering.idCateringRequest);
      setValue("name", catering.name);
      setValue("phoneNumber", catering.phoneNumber);
      setValue("email", catering.email);
      setValue("company", catering.company);
      setValue("section", catering.section);
      setValue("department", catering.department);
      setValue("costCenter", catering.costCenter);
      setValue("costElement", catering.costElement);
      setValue("date", catering.date);
      setValue("startTimeHour", Hour);
      setValue("startTimeMinute", Minute);
      setValue("numberOfPeople", parseInt(catering.numberOfPeople));
      setValue("sendTo", catering.sendTo);
      setValue("cateringType", catering.cateringType);
      setValue("budget", parseInt(catering.budget));
      setValue("objective", catering.objective);
      setValue("description", catering.description);
      setValue("Approved", Approved);
      setValue("nameApproved", catering.nameApproved);
      setValue("departmentApproved", catering.departmentApproved);
      setValue("companyApproved", catering.companyApproved);
      setValue("idApproved", catering.idApproved);
    } else {
      if (currentUser) {
        UserService.getUserProfile(currentUser.idUser).then((res) => {
          if (res.data) {
            setValue("idUser", currentUser.idUser);
            setValue(
              "name",
              `${res.data.firstname_TH} ${res.data.lastname_TH}`
            );
            setValue("phoneNumber", res.data.telephoneMobile.replace(/-/g, ""));
            setValue("email", res.data.email);
            setValue("company", res.data.companyName);
            setValue("section", res.data.sectionName);
            setValue("department", res.data.departmentName);
            setValue("costCenter", res.data.costCenterCharge);
            setValue("costElement", res.data.costElementCharge);
          }
        });
      }
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <BackButton
              disableRipple
              component={Link}
              to={
                catering
                  ? `/catering/requested/${catering.idCateringRequest}`
                  : "/home"
              }
            >
              <ArrowBackIcon className="Icon" style={{ color: "#99A8B2" }} />
            </BackButton>
            <Typography fontSize={24} fontWeight={500}>
              ขออนุมัติจัดเลี้ยง
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "10px" }}>
            <Typography fontSize={18} fontWeight={300}>
              รายละเอียดผู้แจ้ง
            </Typography>
            <Divider />
          </Grid>
          <Grid
            container
            item
            spacing={3}
            xs={12}
            sx={(theme) => ({
              display: "flex",
              boxShadow:
                "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FBFCFC",
              marginTop: "10px",
              marginBottom: "20px",
              marginLeft: "0px",
              padding: "0 25px 25px 0",

              borderRadius: "15px",
              [theme.breakpoints.down("sm")]: {},
              [theme.breakpoints.down("md")]: {},
            })}
          >
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    label="ชื่อผู้แจ้ง"
                    InputLabelProps={{ shrink: field.value ? true : false }}
                    {...field}
                    helperText={
                      errors.name ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    error={errors.name ? true : false}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="เบอร์"
                    InputLabelProps={{ shrink: field.value ? true : false }}
                    {...field}
                    helperText={
                      errors.phoneNumber ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    error={errors.phoneNumber ? true : false}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Email"
                    InputLabelProps={{ shrink: field.value ? true : false }}
                    {...field}
                    helperText={
                      errors.email ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    error={errors.email ? true : false}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="section"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="แผนก"
                    InputLabelProps={{ shrink: field.value ? true : false }}
                    {...field}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="department"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="ส่วน"
                    InputLabelProps={{ shrink: field.value ? true : false }}
                    {...field}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="company"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="บริษัท"
                    InputLabelProps={{ shrink: field.value ? true : false }}
                    {...field}
                    helperText={
                      errors.company ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    error={errors.company ? true : false}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="costCenter"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Cost Center"
                    InputLabelProps={{ shrink: field.value ? true : false }}
                    {...field}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="costElement"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Cost Element"
                    InputLabelProps={{ shrink: field.value ? true : false }}
                    {...field}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "10px" }}>
            <Typography fontSize={18} fontWeight={300}>
              รายละเอียดการจัดเลี้ยง
            </Typography>
            <Divider />
          </Grid>
          <Grid
            container
            item
            spacing={3}
            xs={12}
            sx={(theme) => ({
              display: "flex",
              boxShadow:
                "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FBFCFC",
              marginTop: "10px",
              marginBottom: "20px",
              marginLeft: "0px",
              padding: "0 25px 25px 0",

              borderRadius: "15px",
              [theme.breakpoints.down("sm")]: {},
              [theme.breakpoints.down("md")]: {},
            })}
          >
            <Grid item xs={12} md={4} lg={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                <Controller
                  name="date"
                  control={control}
                  defaultValue={null}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MobileDatePicker
                      {...field}
                      label="วันที่"
                      minDate={new Date()}
                      inputFormat="DD MMMM YYYY"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            errors.date ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                          }
                          error={errors.date ? true : false}
                          sx={{
                            ".MuiInputBase-root": {
                              borderRadius: "10px",
                            },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="startTimeHour"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="เวลาจัด (ชั่วโมง)"
                    fullWidth
                    helperText={
                      errors.startTimeHour ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    error={errors.startTimeHour ? true : false}
                    select
                  >
                    {getOptionHours().map((mt) => (
                      <MenuItem key={`startHour_${mt.value}`} value={mt.value}>
                        {mt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="startTimeMinute"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="เวลาจัด (นาที)"
                    fullWidth
                    helperText={
                      errors.startTimeMinute
                        ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                        : false
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    error={errors.startTimeMinute ? true : false}
                    select
                  >
                    {getOptionMinutes().map((mt) => (
                      <MenuItem
                        key={`startMinute_${mt.value}`}
                        value={mt.value}
                      >
                        {mt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="numberOfPeople"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="จำนวนคน"
                    InputLabelProps={{ shrink: field.value ? true : false }}
                    type="number"
                    helperText={
                      errors.numberOfPeople
                        ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                        : false
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    error={errors.numberOfPeople ? true : false}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="sendTo"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ shrink: field.value ? true : false }}
                    label="จุดส่ง"
                    {...field}
                    helperText={
                      errors.sendTo ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    error={errors.sendTo ? true : false}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              {isCateringType && (
                <Controller
                  name="cateringType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      InputLabelProps={{ shrink: field.value ? true : false }}
                      fullWidth
                      label="ประเภทการจัด"
                      {...field}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      helperText={
                        errors.cateringType
                          ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                          : false
                      }
                      error={errors.cateringType ? true : false}
                      select
                      value={field.value}
                      onChange={field.onChange}
                      // style={{ minWidth: 520 }}
                    >
                      {isCateringType.map((mt) => (
                        <MenuItem value={mt.idCateringType}>{mt.Type}</MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              )}
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="budget"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    label="งบประมาณการจัดเลี้ยง"
                    InputLabelProps={{ shrink: field.value ? true : false }}
                    {...field}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    type="number"
                    fullWidth
                    helperText={
                      errors.budget ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    error={errors.budget ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <InputLabel>รายการจัดเลี้ยงเพิ่มเติม</InputLabel>
              <Select
                multiple
                fullWidth
                value={selected}
                onChange={selectionChangeHandler}
                // sx={{
                //   ".MuiInputBase-input": {
                //     minWidth: "1550px",
                //     minHeight: "45px",

                //   },

                // }}
                style={{ borderRadius: "10px" }}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip
                        key={value.idCateringAdditionalOption}
                        label={value.nameOption}
                        sx={{ marginLeft: "5px" }}
                      />
                    ))}
                  </div>
                )}
              >
                {additionalOption &&
                  additionalOption.length > 0 &&
                  additionalOption.map((item) => {
                    // เช็คว่าตัวเลือกนี้อยู่ใน selected หรือไม่
                    const isSelected = selected.some(
                      (selectedItem) =>
                        selectedItem.idCateringAdditionalOption ===
                        item.idCateringAdditionalOption
                    );

                    return (
                      <MenuItem
                        key={item.idCateringAdditionalOption}
                        value={{
                          idCateringAdditionalOption:
                            item.idCateringAdditionalOption,
                          nameOption: item.nameOption,
                          costOption: item.costOption,
                        }}
                        // เปลี่ยนสีพื้นหลังตามการเลือก
                        style={{
                          backgroundColor: isSelected ? "#d0f0c0" : "#fff",
                        }}
                      >
                        {item.nameOption}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Controller
                name="objective"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="วัตถุประสงค์"
                    InputLabelProps={{
                      shrink: catering !== null || field.value,
                    }}
                    inputProps={field}
                    helperText={
                      errors.objective ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    error={errors.objective ? true : false}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                    multiline
                    rows={4}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="รายละเอียดเพิ่มเติม"
                    InputLabelProps={{
                      shrink: catering !== null || field.value,
                    }}
                    inputProps={field}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                    multiline
                    rows={4}
                  />
                )}
              />
            </Grid>
          </Grid>

          {isEmployee && (
            <>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Typography fontSize={18} fontWeight={300}>
                  รายละเอียดผู้อนุมัติ
                </Typography>
                <Divider />
              </Grid>
              <Grid
                container
                item
                spacing={3}
                xs={12}
                sx={(theme) => ({
                  display: "flex",
                  boxShadow:
                    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                  border: "1px solid #EEEEEE",
                  backgroundColor: "#FBFCFC",
                  marginTop: "10px",
                  marginBottom: "20px",
                  marginLeft: "0px",
                  padding: "0 25px 25px 0",

                  borderRadius: "15px",
                  [theme.breakpoints.down("sm")]: {},
                  [theme.breakpoints.down("md")]: {},
                })}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    render={(propsInController) => (
                      <Autocomplete
                        options={isEmployee}
                        getOptionLabel={(option) =>
                          `${option.firstname_TH} ${option.lastname_TH}`
                        }
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {`${option.firstname_TH} ${option.lastname_TH}`}
                          </Box>
                        )}
                        value={propsInController.field.value}
                        onChange={(e, value) => {
                          if (value) {
                            setValue(
                              "nameApproved",
                              `${value.firstname_TH} ${value.lastname_TH}`
                            );
                            setValue(
                              "departmentApproved",
                              value.departmentName
                            );
                            setValue("companyApproved", value.companyName);
                            setValue("idApproved", value.idEmployees);
                            propsInController.field.onChange(value);
                            // console.log("propsInController.field.textContent : ",propsInController.field.value)
                          } else {
                            setValue("nameApproved", "");
                            setValue("departmentApproved", "");
                            setValue("companyApproved", "");
                            propsInController.field.onChange(null);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={propsInController.fieldState.error}
                            helperText={
                              propsInController.fieldState.error
                                ? "กรุณาระบุชื่อ"
                                : false
                            }
                            sx={{
                              ".MuiInputBase-root": { borderRadius: "10px" },
                            }}
                            placeholder="ระบุชื่อ-นามสกุลของคนที่จะ Approve"
                            label="ชื่อคนที่อนุมัติ"
                            fullWidth
                          />
                        )}
                      />
                    )}
                    name="Approved"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={null}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        disabled
                        label="แผนกของคนอนุมัติ"
                        fullWidth
                        value={propsInController.field.value}
                        InputLabelProps={{
                          shrink:
                            getValues("departmentApproved") === ""
                              ? false
                              : true,
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    )}
                    name="departmentApproved"
                    control={control}
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        disabled
                        label="บริษัทของผู้อนุมัติ"
                        fullWidth
                        value={propsInController.field.value}
                        InputLabelProps={{
                          shrink:
                            getValues("companyApproved") === "" ? false : true,
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    )}
                    name="companyApproved"
                    control={control}
                    defaultValue=""
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12} container justifyContent="flex-end">
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              style={{
                maxWidth: "150px",
                maxHeight: "60px",
                marginTop: "5px",
                borderRadius: "15px",
                fontSize: "18px",
                backgroundColor: "green",
              }}
              variant="contained"
            >
              ส่งคำขออนุมัติ
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Form;
