import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller, get } from "react-hook-form";
import {
  styled,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Divider,
  IconButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { LoadingButton } from "@mui/lab";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DateAdapter from "@mui/lab/AdapterDayjs";
import "dayjs/locale/th";

import {
  getOptionHours,
  getOptionMaintenanceTypes,
  getOptionMinutes,
} from "../../../../../../utils/constant";

import maintenanceService from "../../../../../../services/maintenance.service";
import UserService from "../../../../../../services/user.service";
import Dropfile from "../../../../shared/Form/DropFile";
import DropfileEdit from "./DropFile";
import { SnackbarProvider, useSnackbar } from "notistack";

import { tr } from "date-fns/locale";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  maxWidth: 1600,
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const maintenanceTypes = [
  { value: 1, label: "ซ่อมแซมทั่วไป" },
  { value: 2, label: "งานไฟฟ้า" },
  { value: 3, label: "งานประปา" },
];
const Form = ({ onSuccessHandler, maintenance, fileUrls }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm();
  const [myProfile, setMyProfile] = useState(null);
  const [myUploadFile, setMyUploadFile] = useState([]);
  const [isuserProfile, setUserProfile] = useState([]);
  const [fileUrl, setFileUrl] = useState(fileUrls);
  const { enqueueSnackbar } = useSnackbar();
  const { user: currentUser } = useSelector((state) => state.auth);
  const onSubmit = (data) => {
    if (!maintenance) {
      const formData = new FormData();
      formData.append("idUser", currentUser.idUser);
      formData.append("maintenanceType", data.maintenanceType);
      formData.append("requestorName", data.requestorName);
      formData.append("phoneNumber", data.phoneNumber);
      formData.append("email", data.email);
      formData.append("company", data.company);
      formData.append("agency", data.agency);
      formData.append("costCenter", data.costCenter);
      formData.append("costElement", data.costElement);
      formData.append("location", data.location);
      formData.append("locationDetail", data.locationDetail);
      formData.append(
        "startDate",
        new Date(
          new Date(data.date).toDateString() +
            " " +
            data.startTimeHour.toString().padStart(2, "0") +
            ":" +
            data.startTimeMinute.toString().padStart(2, "0")
        )
      );
      // formData.append("startTime", new Date(new Date().toDateString() + ' ' + data.startTimeHour.toString().padStart(2, '0') + ':' + data.startTimeMinute.toString().padStart(2, '0')));
      formData.append("timeNote", data.timeNote);

      data.attachment?.map((file, index) => {
        formData.append("attachment", file);
      });

      formData.append("description", data.description);
      formData.append("uploadfile", data.uploadfile);

      console.log(formData.get("startDate"));
      console.log("attachment", formData.get("attachment"));

      maintenanceService.addNewMaintenanceRequest(formData).then((res) => {
        console.log(res.data);
        if (res.data.success) {
          onSuccessHandler(res.data);
          enqueueSnackbar("Add New Maintenance Request success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Add New Maintenance Request failed", {
            variant: "error",
          });
        }
      });

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 2000);
      });
    } else {
      const formData = new FormData();
      formData.append("idMaintenances", data.idMaintenances);
      formData.append("idUser", data.idUser);
      formData.append("maintenanceType", data.maintenanceType);
      formData.append("requestorName", data.requestorName);
      formData.append("phoneNumber", data.phoneNumber);
      formData.append("email", data.email);
      formData.append("company", data.company);
      formData.append("agency", data.agency);
      formData.append("costCenter", data.costCenter);
      formData.append("costElement", data.costElement);
      formData.append("location", data.location);
      formData.append("locationDetail", data.locationDetail);
      formData.append(
        "startDate",
        new Date(
          new Date(data.date).toDateString() +
            " " +
            data.startTimeHour.toString().padStart(2, "0") +
            ":" +
            data.startTimeMinute.toString().padStart(2, "0")
        )
      );
      // formData.append("startTime", new Date(new Date().toDateString() + ' ' + data.startTimeHour.toString().padStart(2, '0') + ':' + data.startTimeMinute.toString().padStart(2, '0')));
      // formData.append("timeNote", data.timeNote);

      data.attachment?.map((file, index) => {
        formData.append("attachment", file);
      });

      formData.append("description", data.description);
      formData.append("fileUrl", JSON.stringify(data.fileUrl));
      formData.append("image", data.image);
      console.log(formData.get("image"));
      console.log("attachment", formData.get("attachment"));

      maintenanceService.updateMaintenanceRequest(formData).then((res) => {
        console.log(res.data);
        if (res.data.success) {
          onSuccessHandler(res.data);
          enqueueSnackbar("Update Maintenance Request success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Update Maintenance Request failed", {
            variant: "error",
          });
        }
      });

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 2000);
      });
    }
  };

  useEffect(() => {
    if (maintenance) {
      setValue("idMaintenances", maintenance[0].idMaintenances);
      setValue("idUser", maintenance[0].idUser);
      setValue("requestorName", maintenance[0].requestorName);
      setValue("phoneNumber", maintenance[0].phoneNumber);
      setValue("email", maintenance[0].email);
      setValue("company", maintenance[0].company);
      setValue("agency", maintenance[0].agency);
      setValue("costCenter", maintenance[0].costCenter);
      setValue("costElement", maintenance[0].costElement);
      setValue("maintenanceType", maintenance[0].maintenanceType);
      setValue("location", maintenance[0].location);
      setValue("locationDetail", maintenance[0].locationDetail);
      setValue("date", maintenance[0].startDate);
      setValue("startTimeHour", new Date(maintenance[0].startDate).getHours());
      setValue(
        "startTimeMinute",
        new Date(maintenance[0].startDate).getMinutes()
      );
      setValue("description", maintenance[0].description);
      setValue("fileUrl", fileUrl);
      setValue("image", maintenance[0].attachment);
    } else {
      if (currentUser) {
        UserService.getUserProfile(currentUser.idUser).then((res) => {
          if (res.data) {
            setValue(
              "requestorName",
              `${res.data.firstname_TH} ${res.data.lastname_TH}`
            );
            setValue("phoneNumber", res.data.telephoneMobile.replace(/-/g, ""));
            setValue("email", res.data.email);
            setValue("company", res.data.companyName);
            setValue("agency", res.data.departmentName);
            setValue("costCenter", res.data.costCenterCharge);
            setValue("costElement", res.data.costElementCharge);
          }
        });
      }
    }
  }, []);
  console.log(maintenance);
  return (
    <Container maxWidth="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <BackButton
              disableRipple
              component={Link}
              to={
                maintenance
                  ? `/maintenances/detail/${maintenance[0].idMaintenances}`
                  : "/home"
              }
            >
              <ArrowBackIcon className="Icon" style={{ color: "#99A8B2" }} />
            </BackButton>
            <Typography fontSize={24} fontWeight={500}>
              {maintenance ? "แก้ไขงานซ่อมแซม" : "แจ้งงานซ่อมแซม"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={20} fontWeight={300}>
              รายละเอียดผู้แจ้ง
            </Typography>
            <Divider />
          </Grid>
          <Grid
            container
            item
            spacing={3}
            xs={12}
            sx={(theme) => ({
              display: "flex",
              boxShadow:
                "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FBFCFC",
              marginTop: "10px",
              marginBottom: "20px",
              marginLeft: "0px",
              padding: "0 25px 25px 0",

              borderRadius: "15px",
              [theme.breakpoints.down("sm")]: {},
              [theme.breakpoints.down("md")]: {},
            })}
          >
            <Grid item xs={12} lg={4} sm={4}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ชื่อผู้แจ้ง"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                    helperText={
                      errors.requestorName ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    error={errors.requestorName ? true : false}
                  />
                )}
                control={control}
                name="requestorName"
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} lg={4} sm={4}>
              <TextField
                label="เบอร์ติดต่อ"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("phoneNumber", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.phoneNumber ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.phoneNumber ? true : false}
              />
            </Grid>
            <Grid item xs={12} lg={4} sm={4}>
              <TextField
                label="Email"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("email", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={errors.email ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false}
                error={errors.email ? true : false}
              />
            </Grid>
            <Grid item xs={6} lg={3} sm={3}>
              <TextField
                label="บริษัท"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("company", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.company ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.company ? true : false}
              />
            </Grid>
            <Grid item xs={6} lg={3} sm={3}>
              <TextField
                label="หน่วยงาน"
                InputLabelProps={{
                  shrink: true || maintenance !== null || getValues("agency"),
                }}
                inputProps={{ ...register("agency", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={errors.agency ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false}
                error={errors.agency ? true : false}
              />
            </Grid>
            <Grid item xs={6} lg={3} sm={3}>
              <TextField
                label="Cost Center"
                InputLabelProps={{
                  shrink: maintenance !== null || getValues("costCenter"),
                }}
                inputProps={{ ...register("costCenter", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.costCenter ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.costCenter ? true : false}
              />
            </Grid>
            <Grid item xs={6} lg={3} sm={3}>
              <TextField
                label="Cost Element"
                inputProps={{ ...register("costElement", { required: true }) }}
                InputLabelProps={{
                  shrink: maintenance !== null || getValues("costElement"),
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.costElement ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.costElement ? true : false}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={20} fontWeight={300}>
              รายละเอียดงานซ่อมแซม
            </Typography>
            <Divider />
          </Grid>
          <Grid
            container
            item
            spacing={3}
            xs={12}
            sx={(theme) => ({
              display: "flex",
              boxShadow:
                "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FBFCFC",
              marginTop: "10px",
              marginBottom: "20px",
              marginLeft: "0px",
              padding: "0 25px 25px 0",

              borderRadius: "15px",
              [theme.breakpoints.down("sm")]: {},
              [theme.breakpoints.down("md")]: {},
            })}
          >
            <Grid item xs={12} lg={4} sm={4}>
              <Controller
                name="maintenanceType"
                control={control}
                defaultValue={null}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    label="ประเภทการซ่อมแซม"
                    select
                    fullWidth
                    InputLabelProps={{
                      shrink:
                        maintenance !== null || getValues("maintenanceType"),
                    }}
                    value={field.value}
                    onChange={field.onChange}
                    {...field}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    helperText={
                      errors.maintenanceType
                        ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                        : false
                    }
                    error={errors.maintenanceType ? true : false}
                  >
                    {maintenanceTypes.map((mt) => (
                      <MenuItem value={mt.value}>{mt.label}</MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={4} sm={4}>
              <TextField
                label="สถานที่"
                InputLabelProps={{
                  shrink: maintenance !== null || getValues("location"),
                }}
                inputProps={{ ...register("location", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.location ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.location ? true : false}
              />
            </Grid>
            <Grid item xs={12} lg={4} sm={4}>
              <TextField
                label="รายละเอียด"
                InputLabelProps={{
                  shrink: maintenance !== null || getValues("locationDetail"),
                }}
                inputProps={{
                  ...register("locationDetail", { required: true }),
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.locationDetail ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.locationDetail ? true : false}
              />
            </Grid>
            <Grid item xs={12} lg={4} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                <Controller
                  name="date"
                  control={control}
                  defaultValue={null}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MobileDatePicker
                      {...field}
                      label="วันที่"
                      minDate={new Date()}
                      inputFormat="DD MMMM YYYY"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            errors.date ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                          }
                          error={errors.date ? true : false}
                          sx={{
                            ".MuiInputBase-root": {
                              borderRadius: "10px",
                            },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} lg={4} sm={4}>
              <Controller
                name="startTimeHour"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="เวลาเริ่ม (ชั่วโมง)"
                    fullWidth
                    helperText={
                      errors.startTimeHour ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    error={errors.startTimeHour ? true : false}
                    select
                  >
                    {getOptionHours().map((mt) => (
                      <MenuItem key={`startHour_${mt.value}`} value={mt.value}>
                        {mt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6} lg={4} sm={4}>
              <Controller
                name="startTimeMinute"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="เวลาเริ่ม (นาที)"
                    fullWidth
                    helperText={
                      errors.startTimeMinute
                        ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                        : false
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    error={errors.startTimeMinute ? true : false}
                    select
                  >
                    {getOptionMinutes().map((mt) => (
                      <MenuItem
                        key={`startMinute_${mt.value}`}
                        value={mt.value}
                      >
                        {mt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              {/* <TextField label="Note" inputProps={{...register("timeNote", { required: true })}} fullWidth helperText={errors.timeNote? "กรุณากรอกข้อมูลให้ถูกต้อง": false} error={errors.timeNote? true: false}/> */}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="รายละเอียดเพิ่มเติม"
                inputProps={{ ...register("description", { required: true }) }}
                InputLabelProps={{
                  shrink: maintenance !== null || getValues("description"),
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.description ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.description ? true : false}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              {maintenance ? (
                <>
                  {fileUrl && (
                    <DropfileEdit
                      title="แนบไฟล์ (ไม่เกิน 10 MB ต่อไฟล์ สูงสุด 5 ไฟล์)"
                      name="attachment"
                      fileUrl={fileUrl}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  )}
                </>
              ) : (
                <Dropfile
                  title="แนบไฟล์ (ไม่เกิน 10 MB ต่อไฟล์ สูงสุด 4 ไฟล์)"
                  name="attachment"
                  setValue={setValue}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              style={{
                maxWidth: "200px",
                maxHeight: "70px",
                marginTop: "5px",
                borderRadius: "15px",
                fontSize: "18px",
                backgroundColor: "green",
              }}
              variant="contained"
            >
              ยืนยัน
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Form;
