import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ExcelJS from "exceljs";
import { styled } from "@mui/styles";
import Container from "@mui/material/Container";

import makeStyles from "@mui/styles/makeStyles";
import {
  Tabs,
  Tab,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "@mui/lab";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Typography from "@mui/material/Typography";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

import CrossArea from "./crossArea";
import DetailCrossAreaCar from "./crossArea/detailCrossAreaCar";
import EditDetailCrossAreaCarBooking from "./crossArea/editDetail/index";

import InArea from "./inArea";
import DetailInAreaCar from "./inArea/detailInAreaCar";
import EditDetailInAreaCarBooking from "./inArea/editDetail";

import CrossAreaPool from "./crossAreaPool";
import DetailCrossAreaCarPool from "./crossAreaPool/detailCrossAreaCarPool";
import EditDetailCrossAreaCarPool from "./crossAreaPool/editDetail";

import DeliveryItems from "./deliveryItems";
import DetailDeliveryCar from "./deliveryItems/detailDeliveryCar";
import EditDetailDeliveryitemCar from "./deliveryItems/editDetail";

import DetailDriverPeer2Peer from "./DetailDriverPeer2peer";

import ShowMapAllDriver from "./showMapAllDriver";

import BetweenSiteCarManage from "./betweenSiteCar";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import { getUserProfile } from "../../../../actions/user";
import { getAllDriver } from "../../../../actions/user";
import { getAllVehicles } from "../../../../actions/vehicle";
import {
  getCrossAreaCarBookingByStartDate,
  getCrossAreaCarBookingByStartDateAndEndDate,
} from "../../../../actions/crossAreaCarBooking";
import {
  getInAreaCarBookingByStartDate,
  getInAreaCarBookingByStartDateAndEndDate,
} from "../../../../actions/inAreaCarBooking";
import {
  getDeliveryCarBookingByStartDate,
  getDeliveryCarBookingByStartDateAndEndDate,
} from "../../../../actions/deliveryCarBooking";
import {
  getBetweenSiteWithRouteDate,
  getBetweenSiteWithRouteDateAndEndDate,
} from "../../../../actions/betweenSiteCarBooking";
import { getAllVehicleBrandsAndModels } from "../../../../actions/vehicleBrandAndModel";
import dayjs from "dayjs";
import driverEmergencyService from "../../../../services/driverEmergency.service";
import utils from "../../../../utils";
import { name } from "dayjs/locale/th";
import { width } from "@mui/system";
import {
  getCrossAreaCarPoolBookingByStartDate,
  getCrossAreaCarPoolBookingByStartDateAndEndDate,
} from "../../../../actions/crossAreaCarPoolBooking";
require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  DatePicker: {
    // width: "200px",
    marginRight: "15px",
    [theme.breakpoints.down("md")]: {
      // width: "170px",
      marginRight: "0px",
    },
  },
  IconCar: {
    fontSize: "40px",
    marginRight: "10px",
    color: theme.palette.primary.main,
  },
}));

const DivCoverReturn = styled("div")({
  height: "calc(100% - 64px)",
});

const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const HeaderOfPage = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: "40px",
  // alignItems: "center",
});

const TabsStyled = styled(Tabs)({
  // "& .MuiButtonBase-root": {
  //   border: "1px solid black",
  // },
  // display: "flex",
  // justifyContent: "center",
  "& .MuiTabs-scroller .PrivateTabIndicator-colorSecondary-34": {
    height: "3px",
  },

  // marginInline: "auto",
  // "& .TabLeft": {
  //   borderLeft: "1px solid black",
  // },
});

const TabStyled = styled(Tab)({
  // width: "150px",
  // border: "1px solid black",
  // borderLeft: "0",
  fontSize: "20px",
  height: "60px",
});
const Image = styled("img")({
  width: "100%",
  height: "80%",
});

const PartOfContent = styled("div")({
  width: "100%",
  height: "calc(100% - 80px)",
  // display: "flex",

  // alignItems: "center",
});
const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const DivTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

const GridTitle = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const DivButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const Title = styled(Typography)({
  textAlign: "center",
  fontSize: "30px",
  // marginBottom: "20px",
});

const Text = styled(Typography)({
  fontSize: "15px",
});

const head_CrossArea = [
  "Booking ID",
  "Name",
  "Telephone",
  "Email",
  "Section",
  "Department",
  "Passenger",
  "Passenger (Return)",
  "Flight",
  "From",
  "To",
  "Departure Date",
  "Start Time",
  "End Time",
  "From (Return)",
  "To (Return)",
  "Date (Return)",
  "Start Time (Return)",
  "End Time (Return)",
  "Purpose",
  "Driver Name",
  "Driver Telephone",
  "Vehicle Type",
  "Model",
  "Plate No",
  "Approver Name",
  "Approver Department",
  "Approver Company",
  "Total Price",
  "Management Car Status",
  "Approval Status",
];

const head_CrossAreaPool = [
  "Booking ID",
  "Name",
  "Telephone",
  "Email",
  "Section",
  "Department",
  "Passenger",
  "Passenger (Return)",
  "Flight",
  "From",
  "To",
  "Departure Date",
  "From (Return)",
  "To (Return)",
  "Date (Return)",
  "Driver Name",
  "Driver Telephone",
  "Model",
  "Plate No",
  "Management Car Status",
  "Approval Status",
];

const head_InArea = [
  "Booking ID",
  "Name",
  "Telephone",
  "Email",
  "Section",
  "Department",
  "Company",
  "Cost Center",
  "Cost Element",
  "Passenger",
  "Flight",
  "From",
  "To",
  "GA Site",
  "Departure Date",
  "Start Time",
  "End Time",
  "Purpose",
  "Note",
  "Driver Name",
  "Driver Telephone",
  "Model",
  "Plate No",
  "Approver Name",
  "Approver Department",
  "Approver Company",
  "Management Car Status",
  "Approval Status",
];

const head_Delivery = [
  "Booking ID",
  "Name",
  "Telephone",
  "Email",
  "Section",
  "Department",
  "From",
  "To",
  "Date",
  "Start Time",
  "End Time",
  "Type Product",
  "Weight Product",
  "Purpose",
  "Detail",
  "Note",
  "Name Recipient",
  "Telephone Recipient",
  "Driver Name",
  "Driver Telephone",
  "Model",
  "Plate No",
  "Total Price",
  "Management Car Status",
  "Approval Status",
];

const head_BetweenSite = [
  "Booking ID",
  "Name",
  "Telephone",
  "Email",
  "Section",
  "Department",
  "Company",
  "From",
  "To",
  "Date",
  "Name Driver",
  "Telephone Driver",
];

const CarManagement = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: allDriver } = useSelector((state) => state.drivers);
  const { result: allVehicles } = useSelector((state) => state.vehicles);
  const { result: listVehicleBrandAndModel } = useSelector(
    (state) => state.vehicleBrandsAndModels
  );

  const { result: allBookingCrossArea, isFetching: isFetchingCrossArea } =
    useSelector((state) => state.crossAreaCar);
  const {
    result: allBookingCrossAreaPool,
    isFetching: isFetchingCrossAreaPool,
  } = useSelector((state) => state.crossAreaCarPool);
  const { result: allBookingInArea, isFetching: isFetchingInArea } =
    useSelector((state) => state.inAreaCar);
  const { result: allBookingDelivery, isFetching: isFetchingDelivery } =
    useSelector((state) => state.deliveryCar);
  const { result: allBookingBetweenSite, isFetching: isFetchingBetweenSite } =
    useSelector((state) => state.betweenSiteCar);

  const [typeOfManagement, setTypeOfManagement] = useState("cross_area");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [company, setCompany] = useState(userProfile?.companyName);
  const [loading, setLoading] = useState(false);

  const onChangeTab = (event, page) => {
    props.history.push("/admin/car_management/" + page);
    setTypeOfManagement(page);
  };

  const onClickGoBack = () => {
    props.history.push("/home");
  };

  const setFormatCrossArea = (data) => {
    let temp = [];
    data.forEach((booking) => {
      temp.push({
        idCrossAreaCarBooking: booking.idCrossAreaCarBooking,
        name: booking.name,
        telephoneMobile: booking.telephoneMobile,
        email: booking.email,
        section: booking.section,
        department: booking.department,
        numberOfPassenger: `${booking.numberOfPassenger} คน`,
        numberOfPassengerReturn:
          booking.flight === "oneWay"
            ? null
            : `${booking.numberOfPassengerReturn} คน`,
        flight: booking.flight === "oneWay" ? "เที่ยวเดียว" : "ไป-กลับ",
        fromPlace: booking.fromPlace,
        toPlace: booking.toPlace,
        departureDate: dayjs(booking.departureDate).format("D MMM YYYY"),
        startTime: dayjs(booking.startTime).format("HH:mm น."),
        endTime: dayjs(booking.endTime).format("HH:mm น."),
        fromPlaceReturn:
          booking.flight === "twoWay" ? booking.fromPlaceReturn : null,
        toPlaceReturn:
          booking.flight === "twoWay" ? booking.toPlaceReturn : null,
        returnDate:
          booking.flight === "twoWay" && booking.returnDate
            ? dayjs(booking.returnDate).format("D MMM YYYY")
            : null,
        returnStartTime:
          booking.flight === "twoWay" && booking.returnStartTime
            ? dayjs(booking.returnStartTime).format("HH:mm น.")
            : null,
        returnEndTime:
          booking.flight === "twoWay" && booking.returnEndTime
            ? dayjs(booking.returnEndTime).format("HH:mm น.")
            : null,
        purpose: booking.purpose,
        nameDriver: booking.nameDriver,
        phoneDriver: booking.phoneDriver,
        vehicleTypeNameTH: booking.vehicleTypeNameTH,
        model: booking.model,
        plate_No: booking.plate_No,
        nameApproved: booking.Approver
          ? booking.Approver.firstname_TH + " " + booking.Approver.lastname_TH
          : null,
        departmentApproved: booking.Approver
          ? booking.Approver.departmentName
          : null,
        companyApproved: booking.Approver ? booking.Approver.companyName : null,
        totalPrice: booking.totalPrice
          ? `${utils.numberWithCommas(booking.totalPrice)} บาท`
          : null,
        statusManageCar:
          booking.statusManageCar === "Success" ? "จัดรถแล้ว" : "รอดำเนินการ",
        statusApproved:
          booking.statusApproved === null
            ? "รอดำเนินการ"
            : booking.statusApproved === "Approved"
            ? "อนุมัติ"
            : "ไม่อนุมัติ",
      });
    });
    return temp;
  };

  const setFormatCrossAreaPool = (data) => {
    let temp = [];
    data.forEach((booking) => {
      temp.push({
        idCrossAreaCarPoolBooking: booking.idCrossAreaCarPoolBooking,
        name: booking.name,
        telephoneMobile: booking.telephoneMobile,
        email: booking.email,
        section: booking.section,
        department: booking.department,
        numberOfPassenger: `${booking.numberOfPassenger} คน`,
        numberOfPassengerReturn:
          booking.flight === "oneWay"
            ? null
            : `${booking.numberOfPassengerReturn} คน`,
        flight: booking.flight === "oneWay" ? "เที่ยวเดียว" : "ไป-กลับ",
        fromPlace: booking.fromPlace,
        toPlace: booking.toPlace,
        departureDate: dayjs(booking.departureDate).format("D MMM YYYY"),
        fromPlaceReturn:
          booking.flight === "twoWay" ? booking.fromPlaceReturn : null,
        toPlaceReturn:
          booking.flight === "twoWay" ? booking.toPlaceReturn : null,
        returnDate:
          booking.flight === "twoWay"
            ? dayjs(booking.returnDate).format("D MMM YYYY")
            : null,
        nameDriver: booking.nameDriver,
        phoneDriver: booking.phoneDriver,
        model: booking.vehicle ? booking.vehicle.model : null,
        plate_No: booking.plate_No,
        statusManageCar:
          booking.statusManageCar === "Success" ? "จัดรถแล้ว" : "รอดำเนินการ",
        statusApproved:
          booking.statusApproved === null
            ? "รอดำเนินการ"
            : booking.statusApproved === "Approved"
            ? "อนุมัติ"
            : "ไม่อนุมัติ",
      });
    });
    return temp;
  };

  const setFormatInArea = (data) => {
    let temp = [];
    data.forEach((booking) => {
      temp.push({
        idinAreaCarBooking: booking.idinAreaCarBooking,
        name: booking.name,
        telephoneMobile: booking.telephoneMobile,
        email: booking.email,
        section: booking.section,
        department: booking.department,
        company: booking.company,
        costCenter: booking.costCenter,
        costElement: booking.costElement,
        numberOfPassenger: `${booking.numberOfPassenger} คน`,
        flight: booking.flight === "oneWay" ? "เที่ยวเดียว" : "ไป-กลับ",
        fromPlace: booking.fromPlace,
        toPlace: booking.toPlace,
        gaSite: booking.gaSite,
        departureDate: dayjs(booking.departureDate).format("D MMM YYYY"),
        startTime: dayjs(booking.startTime).format("HH:mm น."),
        endTime: dayjs(booking.endTime).format("HH:mm น."),
        purpose: booking.purpose,
        note: booking.note,
        nameDriver: booking.nameDriver,
        phoneDriver: booking.phoneDriver,
        model: booking.model,
        plate_No: booking.plate_No,
        nameApproved: booking.nameApproved,
        departmentApproved: booking.departmentApproved,
        companyApproved: booking.companyApproved,
        statusManageCar:
          booking.statusManageCar === "Success" ? "จัดรถแล้ว" : "รอดำเนินการ",
        statusApproved:
          booking.statusApproved === "in progress" ||
          booking.statusApproved === null
            ? "รอดำเนินการ"
            : booking.statusApproved === "Approved"
            ? "อนุมัติ"
            : "ไม่อนุมัติ",
      });
    });
    return temp;
  };

  const setFormatDelivery = (data) => {
    let temp = [];
    data.forEach((booking) => {
      temp.push({
        idDeliveryCarBooking: booking.idDeliveryCarBooking,
        name: booking.name,
        telephoneMobile: booking.telephoneMobile,
        email: booking.email,
        section: booking.section,
        department: booking.department,
        fromPlace: booking.fromPlace,
        toPlace: booking.toPlace,
        date: dayjs(booking.date).format("D MMM YYYY"),
        startTime: dayjs(booking.startTime).format("HH:mm น."),
        endTime: dayjs(booking.endTime).format("HH:mm น."),
        typeProduct: booking.typeProduct,
        weightProduct: booking.weightProduct
          ? `${utils.numberWithCommas(booking.weightProduct)} KG`
          : null,
        purpose: booking.purpose,
        detail: booking.detail,
        note: booking.note,
        nameRecipient: booking.nameRecipient,
        telephoneMobileRecipient: booking.telephoneMobileRecipient,
        nameDriver: booking.nameDriver,
        phoneDriver: booking.phoneDriver,
        model: booking.model,
        plate_No: booking.plate_No,
        costBooking: booking.costBooking
          ? `${utils.numberWithCommas(booking.costBooking)} บาท`
          : null,
        statusManageCar:
          booking.statusManageCar === "Success" ? "จัดรถแล้ว" : "รอดำเนินการ",
        statusApproved:
          booking.statusApproved === null
            ? "รอดำเนินการ"
            : booking.statusApproved === "Approved"
            ? "อนุมัติ"
            : "ไม่อนุมัติ",
      });
    });
    return temp;
  };

  const setFormatBetweenSite = (data) => {
    let temp = [];
    data.forEach((booking) => {
      temp.push({
        idBetweenSiteCar: booking.idBetweenSiteCar,
        name: `${booking.user.firstname_TH} ${booking.user.lastname_TH}`,
        telephoneMobile: booking.user.telephoneMobile,
        email: booking.user.email,
        section: booking.user.sectionName,
        department: booking.user.departmentName,
        company: booking.user.companyName,
        fromPlace: `Site${booking.gettingPlace.noSite}: ${booking.gettingPlace.nameSite}`,
        toPlace: `Site${booking.toPlace.noSite}: ${booking.toPlace.nameSite}`,
        date: dayjs(booking.date).format("D MMM YYYY"),
        nameDriver: booking.driver.fNameThai,
        phoneDriver: booking.driver.mobileNumber,
      });
    });
    return temp;
  };

  const onClickDownload = () => {
    setLoading(true);
    if (typeOfManagement === "cross_area" && allBookingCrossArea) {
      const data = setFormatCrossArea(allBookingCrossArea);
      CompensateXlsx(head_CrossArea, data);
    } else if (
      typeOfManagement === "cross_area_pool" &&
      allBookingCrossAreaPool
    ) {
      const data = setFormatCrossAreaPool(allBookingCrossAreaPool);
      CompensateXlsx(head_CrossAreaPool, data);
    } else if (typeOfManagement === "in_area" && allBookingInArea) {
      const data = setFormatInArea(allBookingInArea);
      CompensateXlsx(head_InArea, data);
    } else if (typeOfManagement === "delivery" && allBookingDelivery) {
      const data = setFormatDelivery(allBookingDelivery);
      CompensateXlsx(head_Delivery, data);
    } else if (
      typeOfManagement === "beetween_site_car" &&
      allBookingBetweenSite
    ) {
      const data = setFormatBetweenSite(allBookingBetweenSite);
      CompensateXlsx(head_BetweenSite, data);
    }
  };

  useEffect(() => {
    if (userProfile === null) {
      dispatch(getUserProfile(currentUser.idUser));
    }
    if (!allDriver) {
      dispatch(getAllDriver());
    }
    if (!allVehicles) {
      dispatch(getAllVehicles());
    }
    if (!listVehicleBrandAndModel) {
      dispatch(getAllVehicleBrandsAndModels());
    }

    let path = props.history.location.pathname.split("/");
    if (path[path.length - 1] === "cross_area") {
      setTypeOfManagement("cross_area");
    }
    if (path[path.length - 1] === "cross_area_pool") {
      setTypeOfManagement("cross_area_pool");
    }
    if (path[path.length - 1] === "in_area") {
      setTypeOfManagement("in_area");
    }
    if (path[path.length - 1] === "delivery") {
      setTypeOfManagement("delivery");
    }
    if (path[path.length - 1] === "peer_to_peer") {
      setTypeOfManagement("peer_to_peer");
    }
    if (path[path.length - 1] === "beetween_site_car") {
      setTypeOfManagement("beetween_site_car");
    }
    if (path[path.length - 1] === "showMapAllDriver") {
      setTypeOfManagement("showMapAllDriver");
    }
    driverEmergencyService
      .getDriverEmergencyThatIsNotFinish()
      .then((res) => {});
  }, []);

  useEffect(() => {
    props.history.push("/admin/car_management/" + typeOfManagement);
    if (endDate) {
      let date = {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      };
      dispatch(getCrossAreaCarBookingByStartDateAndEndDate(date));
      dispatch(getInAreaCarBookingByStartDateAndEndDate(date));
      dispatch(getDeliveryCarBookingByStartDateAndEndDate(date));
      dispatch(getBetweenSiteWithRouteDateAndEndDate(date));
      dispatch(getCrossAreaCarPoolBookingByStartDateAndEndDate(date));
    } else {
      let date = {
        startDate: new Date(startDate),
      };
      dispatch(getCrossAreaCarBookingByStartDate(date));
      dispatch(getInAreaCarBookingByStartDate(date));
      dispatch(getDeliveryCarBookingByStartDate(date));
      dispatch(getBetweenSiteWithRouteDate(date));
      dispatch(getCrossAreaCarPoolBookingByStartDate(date));
    }
  }, [startDate, endDate]);

  const CompensateXlsx = (headerValues, data) => {
    const file_name = `${typeOfManagement}_Booking`;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(file_name);

    const headerRow = worksheet.addRow(headerValues);
    headerRow.height = 50; // ปรับความสุงของ header cell

    const headerStyle1 = {
      font: {
        bold: true,
        size: 18,
        name: "TH SarabunPSK",
        color: { argb: "FFFFFF" },
      },
      alignment: { horizontal: "center", vertical: "middle" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "002060" },
      },
    };

    // ตกแต่งตัวอักษร, สีของ cell แต่ละช่อง
    headerRow.eachCell((cell) => {
      cell.style = headerStyle1; // ตัวแปรตกแต่ง cell จากข้างบน
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    const colWidths = Object.keys(data[0]).map((key) => ({
      key: key,
      width: 30, // setting a fixed width for each key
    }));

    // Set column widths
    colWidths.forEach((col, index) => {
      worksheet.getColumn(index + 1).width = col.width;
    });

    // นำค่ามาแสดง เอาค่าที่แสดงใส่ให้ตรงตามตำแหน่งของ header
    data &&
      data.forEach((item) => {
        const row = Object.values(item).map((value) =>
          value === null || value === undefined || value === "" ? "-" : value
        );
        const excelRow = worksheet.addRow(row);

        const contentStyle = {
          font: { size: 18, name: "TH SarabunPSK" },
          alignment: { horizontal: "center", vertical: "middle" },
        };

        // ตกแต่ง cell เหมือนกับ headerStyle1
        excelRow.eachCell((cell) => {
          cell.style = contentStyle;
          cell.border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
        });
      });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${file_name}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
      setLoading(false);
    });
  };

  return (
    <Container maxWidth="xl">
      <Root>
        <DivCoverReturn className="page">
          <HeaderOfPage>
            <Grid
              container
              sx={(theme) => ({
                marginBottom: "25px",
                [theme.breakpoints.down("sm")]: {
                  display: "grid",
                },
                [theme.breakpoints.down("md")]: {
                  display: "grid",
                },
              })}
            >
              <GridTitle item xs={12}>
                <DivTitle>
                  <BackButton disableRipple onClick={onClickGoBack}>
                    <ArrowBackIcon
                      className="Icon"
                      style={{ color: "#99A8B2" }}
                    />
                  </BackButton>
                  <DriveEtaIcon className={`${classes.IconCar}`} />

                  <Title>Car Management</Title>
                </DivTitle>
                <DivButton>
                  <LoadingButton
                    loading={
                      loading ||
                      isFetchingBetweenSite ||
                      isFetchingCrossArea ||
                      isFetchingInArea ||
                      isFetchingDelivery
                    }
                    // loadingIndicator="Loading..."
                    disabled={typeOfManagement === "showMapAllDriver"}
                    loadingPosition="start"
                    startIcon={<DownloadRoundedIcon />}
                    variant="outlined"
                    onClick={onClickDownload}
                  >
                    ดาวน์โหลด
                  </LoadingButton>
                </DivButton>
              </GridTitle>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={(theme) => ({
                display: "flex",
                // justifyContent: "space-between",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                boxShadow:
                  "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

                marginBottom: "20px",
                padding: "25px 30px 25px 30px",
                // padding: "25px 30px 25px 30px",

                borderRadius: "15px",
                [theme.breakpoints.down("sm")]: {
                  // padding: "25px 30px 25px 15px",
                },
                [theme.breakpoints.down("md")]: {
                  // padding: "25px 30px 25px 15px",
                },
              })}
            >
              <Grid item xs={12} md={6}>
                <Stack>
                  <Text>ชื่อบริษัท</Text>

                  {userProfile && (
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        placeholder="เลือกบริษัท"
                        id="company"
                        defaultValue={company}
                        onChange={(e) => setCompany(e.target.value)}
                        value={company}
                      >
                        <MenuItem
                          key={userProfile.idCompany}
                          value={userProfile.companyName}
                        >
                          {userProfile.companyName}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={3}>
                <Stack>
                  <Text>วันที่เดินทาง</Text>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={startDate}
                      fullWidth
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => (
                        <TextField
                          className={`${classes.DatePicker}`}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={12} md={3}>
                <Stack>
                  <Text>วันที่สิ้นสุด</Text>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      minDate={startDate}
                      value={endDate}
                      fullWidth
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => (
                        <TextField
                          className={`${classes.DatePicker}`}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
            </Grid>
            <TabsStyled
              value={typeOfManagement}
              onChange={onChangeTab}
              // centered
              aria-label="Tab-typeOfManagement"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <TabStyled
                className="classes.TabLeft"
                id="Tab-crossArea"
                value="cross_area"
                label="รถข้ามพื้นที่เฉพาะ"
              />
              <TabStyled
                id="Tab-crossAreaPool"
                value="cross_area_pool"
                label="รถข้ามพื้นที่Pool"
              />
              <TabStyled id="Tab-inArea" value="in_area" label="รถในพื้นที่" />
              <TabStyled
                id="Tab-deliveryItem"
                value="delivery"
                label="รถรับส่งของ"
              />
              <TabStyled
                id="Tab-peer2peer"
                value="peer_to_peer"
                label="รถ Peer to Peer"
              />
              <TabStyled
                id="Tab-beetweenSiteCar"
                value="beetween_site_car"
                label="รถรับส่งระหว่าง Site"
              />
              <TabStyled
                id="Tab-showMapAllDriver"
                value="showMapAllDriver"
                label="แสดงแผนที่รถทั้งหมด"
              />
            </TabsStyled>
          </HeaderOfPage>
          <PartOfContent>
            <Switch>
              <Route
                exact
                path="/admin/car_management"
                component={() => (
                  <Redirect to="/admin/car_management/cross_area" />
                )}
              />

              <Route
                exact
                path="/admin/car_management/cross_area"
                component={(props) => (
                  <CrossArea
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />
              <Route
                exact
                path="/admin/car_management/cross_area/detail"
                component={(props) => (
                  <DetailCrossAreaCar
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />
              <Route
                exact
                path="/admin/car_management/cross_area/edit_detail"
                component={(props) => (
                  <EditDetailCrossAreaCarBooking
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />

              <Route
                exact
                path="/admin/car_management/cross_area_pool"
                component={(props) => (
                  <CrossAreaPool
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />
              <Route
                exact
                path="/admin/car_management/cross_area_pool/detail"
                component={(props) => (
                  <DetailCrossAreaCarPool
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />
              <Route
                exact
                path="/admin/car_management/cross_area_pool/edit_detail"
                component={(props) => (
                  <EditDetailCrossAreaCarPool
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />

              <Route
                exact
                path="/admin/car_management/in_area"
                component={(props) => (
                  <InArea {...props} startDate={startDate} endDate={endDate} />
                )}
              />
              <Route
                exact
                path="/admin/car_management/in_area/detail"
                component={(props) => (
                  <DetailInAreaCar
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />
              <Route
                exact
                path="/admin/car_management/in_area/edit_detail"
                component={(props) => (
                  <EditDetailInAreaCarBooking
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />

              <Route
                exact
                path="/admin/car_management/delivery"
                component={(props) => (
                  <DeliveryItems
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />
              <Route
                exact
                path="/admin/car_management/delivery/detail"
                component={(props) => (
                  <DetailDeliveryCar
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />
              <Route
                exact
                path="/admin/car_management/delivery/edit_detail"
                component={(props) => (
                  <EditDetailDeliveryitemCar
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />

              <Route
                exact
                path="/admin/car_management/peer_to_peer"
                component={(props) => (
                  <DetailDriverPeer2Peer
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />

              <Route
                exact
                path="/admin/car_management/showMapAllDriver"
                component={ShowMapAllDriver}
              />

              <Route
                exact
                path="/admin/car_management/beetween_site_car"
                component={(props) => (
                  <BetweenSiteCarManage
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />
            </Switch>
          </PartOfContent>
        </DivCoverReturn>
      </Root>
    </Container>
  );
};

export default CarManagement;
