import React, { useEffect } from "react";
import Card from "../Card";
import { Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/th";
import CardContent from "@mui/material/CardContent";

import { styled } from "@mui/styles";

const Item = ({ data }) => {
  return (
    <Card>
      <Grid container>
        <Grid item xs={12} md={5} lg={2} spacing={2} alignItems="center">
          {parseInt(data.maintenanceType) === 1 && (
            <Typography fontSize={20}>
              <i className="fas fa-wrench"></i> ซ่อมแซมทั่วไป
            </Typography>
          )}
          {parseInt(data.maintenanceType) === 2 && (
            <Typography fontSize={20} color="#f8c243 !important">
              <i className="fas fa-bolt"></i> งานไฟฟ้า
            </Typography>
          )}
          {parseInt(data.maintenanceType) === 3 && (
            <Typography fontSize={20} color="cornflowerblue !important">
              <i className="fas fa-tint"></i> งานประปา
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={5} lg={2} spacing={2} alignItems="center">
          <Typography className="title">วันที่แจ้ง</Typography>
          <Typography>
            {dayjs(data.createdAt).locale("th").format("DD MMMM YYYY")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5} lg={2} spacing={2} alignItems="center">
          <Typography className="title">วันที่เริ่มงาน</Typography>
          <Typography>
            {dayjs(data.startDate).locale("th").format("DD MMM YYYY (HH:mmน.)")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5} lg={2} spacing={2} alignItems="center">
          <Typography className="title">สถานที่</Typography>
          <Typography>{data.location}</Typography>
        </Grid>
        <Grid item xs={12} md={5} lg={2} spacing={2} alignItems="center">
          <Typography className="title">สถานะ</Typography>
          {parseInt(data.status) === 1 && (
            <Typography>
              <i className="far fa-clock"></i> กำลังรออนุมัติ...
            </Typography>
          )}
          {parseInt(data.status) === 2 && (
            <Typography>
              <i className="fad fa-spinner"></i> กำลังดำเนินการ
            </Typography>
          )}
          {parseInt(data.status) === 3 && (
            <Typography color="#43a047 !important">
              <i className="fas fa-check-circle"></i> ดำเนินการเสร็จสิ้น
            </Typography>
          )}
          {parseInt(data.status) === 4 && (
            <Typography color="#e53935 !important">
              <i className="fas fa-times-circle"></i> ไม่อนุมัติ
            </Typography>
          )}
          {parseInt(data.status) === 5 && (
            <Typography color="#e53935 !important">
              <i className="fas fa-trash"></i> ยกเลิก
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          lg={2}
          spacing={2}
          alignItems="center"
          justifyContent={"flex-end"}
          marginTop={2}
          container
        >
          <Button
            variant="contained"
            style={{
              borderRadius: "15px",
              backgroundColor: "#00e893",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#00965f",
                color: "#ffffff",
              },
            }}
            component={Link}
            to={`/maintenances/detail/${data.idMaintenances}`}
          >
            ดูรายละเอียด
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Item;
