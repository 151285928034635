import React, { useState, useEffect } from "react";
import { styled } from "@mui/material";
import "dayjs/locale/th";
import Form from "./Form";
import Result from "./Result";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  maxWidth: 1600,
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const Request = (props) => {
  const [isSuccess, setIsSuccess] = useState(null);
  const [maintenance, setMaintenance] = useState(
    props.location.state ? props.location.state.maintenance : null
  );
  const [fileUrl, setFileUrl] = useState(null);
  const [resultData, setResultDate] = useState(null);
  const onSuccessHandler = (data) => {
    setIsSuccess(data.success);
    setResultDate(data.data);
  };

  useEffect(() => {
    let file = [];
    if (maintenance && maintenance[0].fileUrl) {
      for (let i = 0; i < maintenance[0].fileUrl.length; i++) {
        file.push({
          id: i,
          url: maintenance[0].fileUrl[i].file,
          isActive: true,
        });
      }
    }
    setFileUrl(file);
  }, []);

  return (
    <div className="page">
      <Root>
        {isSuccess === null && fileUrl && (
          <Form
            onSuccessHandler={onSuccessHandler}
            maintenance={maintenance}
            fileUrls={fileUrl}
          />
        )}
        {isSuccess === true && (
          <Result data={resultData} maintenance={maintenance} />
        )}
      </Root>
    </div>
  );
};

export default Request;
