import React, { useEffect, useState } from "react";
import {
  Avatar,
  Stack,
  styled,
  Typography,
  Grid,
  IconButton,
  Divider,
  TextField,
  InputAdornment,
  Button,
  Box,
} from "@mui/material";
import Card from "../../../shared/Card";
import dayjs from "dayjs";
import "dayjs/locale/th";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StatusRequest from "../../../shared/StatusRequest";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import utils from "../../../../../utils";
import LoadingButton from "@mui/lab/LoadingButton";
import { getCateringStatus } from "../../../../../utils/constant";
import { SnackbarProvider, useSnackbar } from "notistack";
import ConfirmApproveModal from "../../../admin/Catering/RequestedList/ConfirmApproveModal";
import ConfirmDisApproveModal from "../../../admin/Catering/RequestedList/ConfirmDisApproveModal";
import cateringService from "../../../../../services/catering.service";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  maxWidth: 1280,
  "& .date": {
    color: "#3f51b5",
  },
  "& .location": {
    color: "#d32f2f",
  },
  "& .text": {
    fontSize: 18,
  },
  "& .icon": {
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
  },
  "& .status__approved": {
    color: "#2e7d32",
  },
  "& .status__disapproved": {
    color: "#d32f2f",
  },
  "& .status__pending": {
    color: "#fbc02d",
  },
  "& .EditIcon": {
    marginRight: "5px",
    color: theme.palette.primary.main,
  },
  "& .CheckIcon": {
    marginRight: "5px",
    color: "green",
  },
  "& .LocationIcon": {
    marginRight: "5px",
    color: theme.palette.error.main,
  },
  "& .CalendarIcon": {
    marginRight: "5px",
    color: theme.palette.primary.main,
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const EditButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "inherit",
  },
  "&:hover .Icon": {
    color: theme.palette.primary.main,
  },
  "& .Icon": {
    marginRight: "5px",
    color: theme.palette.primary.main,
    fontSize: "25px",
  },
}));

const GridContent = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
}));

const Text = styled(Typography)({
  fontSize: "18px",
  fontWeight: 420,
  marginRight: "10px",
});

const HighlightText = styled(Typography)({
  fontSize: "16px",
});
const HighlightTextMain = styled(Typography)({
  fontSize: "20px",
  fontWeight: 550,
});

const TextWithIcon = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  justifyContent: "center",
  "& .Icon": {
    marginRight: "5px",
    color: "#293A80",
  },
  "& .LocationIcon": {
    marginRight: "5px",
    color: theme.palette.error.main,
  },
  "& .CalendarIcon": {
    marginRight: "5px",
    color: theme.palette.primary.main,
  },
}));

const EditDetailButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    color: "black",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const DivButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const CateringDescriptionPageForManager = (props) => {
  const [catering, setCatering] = useState(null);
  const [additionalPrice, setAdditionalPrice] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [clickEdit, setClickEdit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reLoading, setReLoading] = useState(false);
  const [openConfirmApproveModal, setOpenConfirmApproveModal] = useState(false);
  const [openConfirmDisApproveModal, setOpenConfirmDisApproveModal] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    cateringService
      .getCateringById(props.match.params.requestedId)
      .then((res) => {
        setCatering(res.data.data);
        setAdditionalPrice(res.data.data.additionalOption);
      });
  }, [reLoading]);

  useEffect(() => {
    if (additionalPrice) {
      setTotalPrice(additionalPrice.reduce((a, b) => a + b.costOption, 0));
    }
  }, [additionalPrice]);

  const onClickGoBack = () => {
    props.history.push("/manager/catering/requested_list");
  };

  const changeStatus = (index, status) => {
    cateringService
      .changeStatusById(index, status)
      .then((res) => {
        if (res.data.data.status) {
          setCatering(res.data.data);
          enqueueSnackbar("Update Status Success", {
            variant: "success",
          });
          setReLoading(!reLoading);
        } else {
          enqueueSnackbar("Update Status Failed", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Update Status Failed", {
          variant: "error",
        });
        console.log(err);
      });
  };

  const onClickEdit = (index) => {
    let clickEdit_copy = [...clickEdit];
    clickEdit_copy[index] = !clickEdit[index];
    setClickEdit(clickEdit_copy);
  };

  const onClickApprove = () => {
    setLoading(true);

    cateringService
      .updateCostRequestCatering({
        id: props.match.params.requestedId,
        totalPrice: totalPrice,
        costList: additionalPrice,
      })
      .then((res) => {
        if (res.data.data) {
          enqueueSnackbar("The expenses summary has been successfully saved.", {
            variant: "success",
          });
          setLoading(false);
        } else {
          enqueueSnackbar("The expenses summary hasn't been saved.", {
            variant: "error",
          });
          setLoading(false);
        }
      });
  };
  const onClickEditDetail = () => {
    props.history.push(`/catering/request_catering`, {
      catering: catering,
    });
  };
  return (
    <Container maxWidth="lg">
      <div className="page">
        <Root>
          {catering && (
            <>
              <Card style={{ paddingLeft: "32px", marginBottom: "50px" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <DivButton>
                      <BackButton disableRipple onClick={onClickGoBack}>
                        <ArrowBackIcon
                          className="Icon"
                          style={{ color: "#99A8B2" }}
                        />
                      </BackButton>
                      <Typography fontSize={25} fontWeight={600}>
                        ร้องขอจัดเลี้ยง
                      </Typography>
                    </DivButton>
                    <DivButton>
                      {/* <EditDetailButton onClick={onClickEditDetail}>
                        <EditRoundedIcon className="Icon" color="inherit" />
                      </EditDetailButton> */}
                    </DivButton>
                  </Grid>
                  <Grid item xs={12}>
                    <HighlightTextMain>ผู้ร้องขอ</HighlightTextMain>
                    <Divider />
                  </Grid>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>ชื่อ - สกุล:</Text>
                    <Avatar
                      src={`${process.env.REACT_APP_URL}image/profile/${
                        catering.requester === null ||
                        catering.requester === undefined
                          ? "1.jpg"
                          : catering.requester.image
                      }`}
                      style={{ marginRight: "10px" }}
                    />
                    <HighlightText>
                      {catering.requester === null ||
                      catering.requester === undefined
                        ? "ไม่ระบุ"
                        : catering.requester.firstname}{" "}
                      {catering.requester === null ||
                      catering.requester === undefined
                        ? "ไม่ระบุ"
                        : catering.requester.lastname}
                    </HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>เบอร์: </Text>
                    <HighlightText>
                      {catering.phoneNumber === null
                        ? "ไม่ระบุ"
                        : catering.phoneNumber}
                    </HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>Email: </Text>
                    <HighlightText>
                      {catering.email === null ? "ไม่ระบุ" : catering.email}
                    </HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>ส่วน: </Text>
                    <HighlightText>
                      {catering.department === null
                        ? "ไม่ระบุ"
                        : catering.department}
                    </HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>บริษัท: </Text>
                    <HighlightText>
                      {catering.company === null ? "ไม่ระบุ" : catering.company}
                    </HighlightText>
                  </GridContent>
                  <Grid item xs={12}>
                    <HighlightTextMain>
                      รายละเอียดร้องขอจัดเลี้ยง
                    </HighlightTextMain>
                    <Divider />
                  </Grid>
                  <GridContent item xs={12} sm={12} md={12}>
                    <Text>สถานะ: </Text>
                    {catering.status === "approved" && (
                      <StatusRequest
                        boxSize={30}
                        fontSize={18}
                        borderRadius={8}
                        status={1}
                        active={1}
                      />
                    )}
                    {catering.status === "pending" && (
                      <StatusRequest
                        boxSize={30}
                        fontSize={18}
                        borderRadius={8}
                        status={null}
                        step={null}
                        active={1}
                      />
                    )}
                    {catering.status === "disapproved" && (
                      <StatusRequest
                        boxSize={30}
                        fontSize={18}
                        borderRadius={8}
                        status={0}
                        active={1}
                      />
                    )}
                    {catering.status === "pending" ? (
                      <HighlightText style={{ color: "#fbc02d" }}>
                        รอดำเนินการ
                      </HighlightText>
                    ) : catering.status === "approved" ? (
                      <HighlightText style={{ color: "#2e7d32" }}>
                        อนุมัติแล้ว
                      </HighlightText>
                    ) : catering.status === "disapproved" ? (
                      <HighlightText style={{ color: "#d32f2f" }}>
                        ไม่อนุมัติ
                      </HighlightText>
                    ) : null}
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <LocationOnRoundedIcon className="LocationIcon" />
                    <Text>สถานที่: </Text>
                    <HighlightText>{catering.sendTo}</HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <CalendarMonthRoundedIcon className="CalendarIcon" />
                    <Text>เวลา: </Text>
                    <HighlightText>
                      {dayjs(catering.date).locale("th").format("DD MMM YYYY")}{" "}
                      {catering.time} น.
                    </HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <PeopleAltRoundedIcon className="Icon" />
                    <Text>จำนวนผู้เข้าร่วม: </Text>
                    <HighlightText>{catering.numberOfPeople} คน</HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>ประเภท: </Text>
                    <HighlightText>{catering.cateringTypeName}</HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>วัตถุประสงค์: </Text>
                    <HighlightText>{catering.objective}</HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>งบประมาณ: </Text>
                    <HighlightText>
                      {catering.budget === null ||
                      catering.budget === "" ||
                      catering.budget === undefined
                        ? "ไม่ระบุ"
                        : utils.numberWithCommas(catering.budget)}{" "}
                      บาท
                    </HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={12} md={12}>
                    <Text>รายละเอียดเพิ่มเติม: </Text>
                    <HighlightText>
                      {catering.description === null ||
                      catering.description === ""
                        ? "ไม่ระบุ"
                        : catering.description}
                    </HighlightText>
                  </GridContent>
                  <Grid item xs={12}>
                    <HighlightTextMain>ผู้ให้อนุมัติ</HighlightTextMain>
                    <Divider />
                  </Grid>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>ชื่อ - สกุล:</Text>
                    <Avatar
                      src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                      style={{ marginRight: "10px" }}
                    />
                    <HighlightText>{catering.nameApproved}</HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>ส่วน: </Text>
                    <HighlightText>
                      {catering.departmentApproved === null
                        ? "ไม่ระบุ"
                        : catering.departmentApproved}
                    </HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>บริษัท: </Text>
                    <HighlightText>
                      {catering.companyApproved === null
                        ? "ไม่ระบุ"
                        : catering.companyApproved}
                    </HighlightText>
                  </GridContent>
                  <Grid item xs={12}>
                    <HighlightTextMain>รายการค่าใช้จ่าย</HighlightTextMain>
                    <Divider />
                  </Grid>
                  <Grid container item spacing={2} xs={12} sm={12} md={12}>
                    {additionalPrice &&
                      additionalPrice.map((value, index) => (
                        <Grid item xs={12} key={index}>
                          <GridContent
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ paddingInline: "15px" }}
                          >
                            <GridContent item xs={12} sm={6} md={6}>
                              <HighlightText>{value.nameOption} </HighlightText>
                              {/* {clickEdit[index] ? (
                                <EditButton onClick={() => onClickEdit(index)}>
                                  <CheckRoundedIcon className="CheckIcon" />
                                </EditButton>
                              ) : (
                                <EditButton onClick={() => onClickEdit(index)}>
                                  <EditRoundedIcon className="EditIcon" />
                                </EditButton>
                              )} */}
                            </GridContent>
                            <GridContent
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              style={{ justifyContent: "flex-end" }}
                            >
                              {clickEdit[index] ? (
                                <TextField
                                  style={{ width: "150px" }}
                                  value={additionalPrice[index].costOption}
                                  onChange={(e) => {
                                    let num = parseInt(e.target.value);
                                    if (!isNaN(num)) {
                                      let additional = [...additionalPrice];
                                      additional[index].costOption = num;
                                      setAdditionalPrice(additional);
                                    } else setAdditionalPrice(index, 0);
                                  }}
                                  variant="outlined"
                                  type="number"
                                  size="small"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        ฿
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              ) : (
                                <HighlightText style={{ color: "green" }}>
                                  ฿{" "}
                                  {additionalPrice[index].costOption === null ||
                                  additionalPrice[index].costOption === "" ||
                                  additionalPrice[index].costOption ===
                                    undefined
                                    ? "ไม่ระบุ"
                                    : utils.numberWithCommas(
                                        additionalPrice[index].costOption
                                      )}
                                </HighlightText>
                              )}
                            </GridContent>
                          </GridContent>

                          <Divider style={{ marginTop: "10px" }} />
                        </Grid>
                      ))}
                    <Grid item xs={12}>
                      <GridContent
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ paddingInline: "15px" }}
                      >
                        <GridContent item xs={12} sm={6} md={6}>
                          <Text>รวมค่าบริการทั้งหมด </Text>
                        </GridContent>
                        <GridContent
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          style={{ justifyContent: "flex-end" }}
                        >
                          <HighlightText style={{ color: "green" }}>
                            ฿{" "}
                            {totalPrice === null ||
                            totalPrice === "" ||
                            totalPrice === undefined
                              ? "ไม่ระบุ"
                              : utils.numberWithCommas(totalPrice)}
                          </HighlightText>
                        </GridContent>
                      </GridContent>
                    </Grid>
                    {/* <GridContent
                      item
                      xs={12}
                      style={{ justifyContent: "flex-end" }}
                    >
                      <LoadingButton
                        onClick={onClickApprove}
                        loading={loading}
                        variant="outlined"
                      >
                        บันทึกสรุปค่าใช้จ่าย
                      </LoadingButton>
                    </GridContent> */}
                  </Grid>
                </Grid>
              </Card>
              {catering.status === "pending" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px", // ช่องว่างระหว่างปุ่ม
                    marginBottom: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenConfirmDisApproveModal(true)}
                  >
                    ไม่อนุมัติ
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#4BB543", // สีเขียว
                      "&:hover": {
                        backgroundColor: "#43A047", // สีเขียวเข้มขึ้นเมื่อ hover
                      },
                    }}
                    onClick={() => setOpenConfirmApproveModal(true)}
                  >
                    อนุมัติ
                  </Button>
                </Box>
              )}
              {openConfirmApproveModal && (
                <ConfirmApproveModal
                  open={openConfirmApproveModal}
                  onClose={() => {
                    setOpenConfirmApproveModal(false);
                  }}
                  onChangeStatusHandler={changeStatus}
                  index={catering.idCateringRequest}
                />
              )}
              {openConfirmDisApproveModal && (
                <ConfirmDisApproveModal
                  open={openConfirmDisApproveModal}
                  onClose={() => {
                    setOpenConfirmDisApproveModal(false);
                  }}
                  onChangeStatusHandler={changeStatus}
                  index={catering.idCateringRequest}
                />
              )}
            </>
          )}
        </Root>
      </div>
    </Container>
  );
};

export default CateringDescriptionPageForManager;
