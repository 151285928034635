import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import { styled } from "@mui/styles";

import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { SnackbarProvider, useSnackbar } from "notistack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { useMediaQuery, Button } from "@mui/material";

import FormInformant from "./formInformat";
import FormFightCarPool from "./formFightCarPool";
import FormDetailFightCarPool from "./formDetailFightCarPool";
import DialogShowDetailBooking from "./dialogShowDetailBooking";
import NavButton from "../../../../shared/NavButton/NavButtonForm";
import userService from "../../../../../../services/user.service";
import { getEmployeesByCompany } from "../../../../../../actions/employee";

import CrossAreaCarPoolBookingServices from "../../../../../../services/crossAreaCarPoolBooking.service";
import {
  getCrossAreaCarPoolBookingByStartDate,
  getCrossAreaCarPoolBookingByStartDateAndEndDate,
} from "../../../../../../actions/crossAreaCarPoolBooking";

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // paddingInline: "5vw",
    // [theme.breakpoints.down("sm")]: {
    //   paddingInline: "0",
    //   marginLeft: "0",
    //   width: "100%",
    // },
  },
  Grid: {
    width: "80%",
    margin: "0 auto",
    marginTop: "20px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
    },
  },
  Form: {
    width: "80%",
  },
  Title: {
    textAlign: "center",
    fontSize: "25px",
  },
  MobilePaddingLeft: {
    paddingLeft: 0,
  },
  DivSpace: {
    height: "100px",
  },
}));

const DivHead = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "20px",
  marginTop: "20px",
});

const BackButton = styled(Button)(({ theme }) => ({
  // position: "absolute",
  color: "black",
  fontSize: "20px",
  // left: "30px",
}));

const EditDetailCrossAreaCarPool = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [booking, setBooking] = useState(null);
  const [fromPlacePassenger, setFromPlacePassenger] = useState(null);
  const [toPlacePassenger, setToPlacePassenger] = useState(null);
  const [fromPlaceReturnPassenger, setFromPlaceReturnPassenger] =
    useState(null);
  const [toPlaceReturnPassenger, setToPlaceReturnPassenger] = useState(null);
  const { user: CurrentUser } = useSelector((state) => state.auth);
  const [countPage, setCountPage] = useState(1);
  const [openDetailBooking, setOpenDetailBooking] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [stateInputRef, setStateInputRef] = useState({
    listAllPassengers: [{ order: "" }],
    statusUsingAllPassenger: false,
  });

  useEffect(() => {
    if (props.location.state === undefined) {
      props.history.push("/admin/car_management");
    } else {
      if (CurrentUser) {
        userService.getUserProfile(CurrentUser.idUser).then((res) => {
          if (res.data) {
            dispatch(getEmployeesByCompany(res.data.idCompany));
          }
        });
        // setValue("idUser", CurrentUser.idUser);
      }
      setBooking(props.location.state.booking);
      const booking = props.location.state.booking;
      const passenger = props.location.state.booking.routes;
      setValue("idCrossAreaCarPoolBooking", booking.idCrossAreaCarPoolBooking);
      setValue("idUser", booking.idUser);
      setValue("name", booking.name);
      setValue("telephoneMobile", booking.telephoneMobile);
      setValue("email", booking.email);
      setValue("section", booking.section);
      setValue("department", booking.department);
      setValue("flight", booking.flight);
      setValue("fromPlace", booking.fromPlace);
      setValue("toPlace", booking.toPlace);
      setValue("numberOfPassenger", parseInt(booking.numberOfPassenger));
      setValue("departureDate", booking.departureDate);
      let fromPlacePassenger = [];
      let toPlacePassenger = [];
      let fromPlaceReturnPassenger = [];
      let toPlaceReturnPassenger = [];
      for (let i = 0; i < passenger.length; i++) {
        const option = {
          idEmployees: passenger[i].detail.idUser,
          firstname_TH: passenger[i].detail.name.split(" ")[0],
          lastname_TH: passenger[i].detail.name.split(" ")[1],
          departmentName: passenger[i].detail.department,
        };
        setValue(
          `listPassenger.person${i + 1}.idCrossAreaCarPassenger`,
          passenger[i].detail.idCrossAreaCarPoolBookingPassenger
        );
        setValue(`listPassenger.person${i + 1}.option`, option);
        setValue(`listPassenger.person${i + 1}.name`, passenger[i].detail.name);
        setValue(
          `listPassenger.person${i + 1}.telephoneMobile`,
          passenger[i].detail.telephoneMobile
        );
        setValue(
          `listPassenger.person${i + 1}.email`,
          passenger[i].detail.email
        );
        setValue(
          `listPassenger.person${i + 1}.company`,
          passenger[i].detail.company
        );
        setValue(
          `listPassenger.person${i + 1}.costCenter`,
          passenger[i].detail.costCenter
        );
        setValue(
          `listPassenger.person${i + 1}.costElement`,
          passenger[i].detail.costElement
        );
        setValue(
          `listPassenger.person${i + 1}.fromPlace`,
          passenger[i].detail.fromPlace
        );
        setValue(
          `listPassenger.person${i + 1}.toPlace`,
          passenger[i].detail.toPlace
        );
        setValue(
          `listPassenger.person${i + 1}.roundTime`,
          passenger[i].detail.roundTime
        );
        setValue(
          `listPassenger.person${i + 1}.endTime`,
          passenger[i].detail.endTime
        );
        setValue(
          `listPassenger.person${i + 1}.purpose`,
          passenger[i].detail.purpose
        );
        setValue(
          `listPassenger.person${i + 1}.noteDeparture`,
          passenger[i].detail.noteDeparture
        );
        fromPlacePassenger.push(passenger[i].detail.fromPlace);
        toPlacePassenger.push(passenger[i].detail.toPlace);
        if (booking.flight === "twoWay") {
          setValue(
            `listPassenger.person${i + 1}.fromPlaceReturn`,
            passenger[i].detail.fromPlaceReturn
          );
          setValue(
            `listPassenger.person${i + 1}.toPlaceReturn`,
            passenger[i].detail.toPlace
          );
          setValue(
            `listPassenger.person${i + 1}.roundTimeReturn`,
            passenger[i].detail.roundTimeReturn
          );
          setValue(
            `listPassenger.person${i + 1}.endTimeReturn`,
            passenger[i].detail.endTimeReturn
          );
          setValue(
            `listPassenger.person${i + 1}.noteReturn`,
            passenger[i].detail.noteReturn
          );

          fromPlaceReturnPassenger.push(passenger[i].detail.fromPlaceReturn);
          toPlaceReturnPassenger.push(passenger[i].detail.toPlace);
        }
      }
      setFromPlacePassenger(fromPlacePassenger);
      setToPlacePassenger(toPlacePassenger);
      setFromPlaceReturnPassenger(fromPlaceReturnPassenger);
      setToPlaceReturnPassenger(toPlaceReturnPassenger);
      if (booking.flight === "twoWay") {
        setValue("toPlaceReturn", booking.toPlaceReturn);
        setValue("fromPlaceReturn", booking.fromPlaceReturn);
        setValue(
          "numberOfPassengerReturn",
          parseInt(booking.numberOfPassengerReturn)
        );
        setValue("returnDate", booking.returnDate);
      }
    }
  }, []);

  const MobileResponsive = useMediaQuery("(max-width:600px)");

  const showRegister = (date) => {
    CheckPage(date);
    console.log(date);
  };

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const CheckPage = (date) => {
    if (countPage === 1) {
      setCountPage(countPage + 1);
    } else {
      console.log("1234");
      console.log(
        getValues(
          `listPassenger.person${getValues("numberOfPassenger")}.option`
        )
      );
      let isNotSpecified = [];
      for (let index = 1; index <= getValues("numberOfPassenger"); index++) {
        if (getValues(`listPassenger.person${index}.option`) === undefined) {
          isNotSpecified.push(index);
        }
      }
      if (isNotSpecified.length > 0) {
        handleClick();
      } else {
        setOpenDetailBooking(true);
      }
    }
    window.scroll(0, 0);
  };

  const onClickBackPageHandler = () => {
    setCountPage(countPage - 1);
  };

  const onCloseDialog = () => {
    setOpenDetailBooking(false);
  };

  const onClickSubmit = (date) => {
    console.log(date);
    CrossAreaCarPoolBookingServices.postUpdateCrossAreaCarPoolBooking(
      date
    ).then((res) => {
      if (res.data) {
        enqueueSnackbar("Update Booking Success", { variant: "success" });
        fetchData();
        props.history.push("/admin/car_management/cross_area_pool");
      } else {
        enqueueSnackbar("Update Booking Failed", { variant: "error" });
      }
    });
  };

  const fetchData = () => {
    if (props.endDate) {
      let date = {
        startDate: new Date(props.startDate),
        endDate: new Date(props.endDate),
      };
      dispatch(getCrossAreaCarPoolBookingByStartDateAndEndDate(date));
    } else {
      let date = {
        startDate: new Date(props.startDate),
      };
      dispatch(getCrossAreaCarPoolBookingByStartDate(date));
    }
  };

  console.log(props.location.state.booking);

  return (
    <div
      className={clsx(classes.DivCoverReturn, {
        [classes.MobilePaddingLeft]: MobileResponsive,
      })}
    >
      <DivHead>
        <BackButton onClick={() => props.history.goBack()}>
          <ArrowBackIosNewIcon /> Back
        </BackButton>

        <Typography className={classes.Title}>จองรถข้ามพื้นที่ Pool</Typography>
        <div></div>
      </DivHead>
      {countPage === 1 && booking && (
        <form className={classes.Form} onSubmit={handleSubmit(showRegister)}>
          <FormInformant
            register={register}
            setValue={setValue}
            errors={errors}
          />
          <FormFightCarPool
            watch={watch}
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            departureDate={booking.departureDate}
            returnDate={booking.returnDate}
            fromPlace={booking.fromPlace}
            toPlace={booking.toPlace}
            fromPlaceReturn={booking.fromPlaceReturn}
            toPlaceReturn={booking.toPlaceReturn}
          />
          <NavButton
            type="button"
            numberOfPage={countPage}
            onClickBackPageHandler={onClickBackPageHandler}
          />
        </form>
      )}
      {countPage === 2 && (
        <form className={classes.Form} onSubmit={handleSubmit(showRegister)}>
          <Grid container justifyContent="center">
            {watch("numberOfPassenger") && (
              <FormDetailFightCarPool
                register={register}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
                control={control}
                errors={errors}
                stateInputRef={stateInputRef}
                setStateInputRef={setStateInputRef}
                fromPlacePassenger={fromPlacePassenger}
                toPlacePassenger={toPlacePassenger}
                fromPlaceReturnPassenger={fromPlaceReturnPassenger}
                toPlaceReturnPassenger={toPlaceReturnPassenger}
              />
            )}
            <NavButton
              type="submit"
              numberOfPage={countPage}
              onClickBackPageHandler={onClickBackPageHandler}
            />
          </Grid>
          <div className={classes.DivSpace}></div>
        </form>
      )}
      <DialogShowDetailBooking
        open={openDetailBooking}
        handleClose={onCloseDialog}
        getValues={getValues}
        onClickSubmit={handleSubmit(onClickSubmit)}
      />

      <Snackbar
        open={openAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} variant="filled" severity="error">
          กรุณากรอกข้อมูลให้ครบ
        </Alert>
      </Snackbar>

      {/* </Grid> */}
    </div>
  );
};

export default EditDetailCrossAreaCarPool;
