import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import { Typography, styled, Grid, TextField, Paper } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import UnicornLoading from "../../../assets/loading.gif";

import utils from "../../../../../utils";
import RouteCrossAreaCarPoolService from "../../../../../services/routeCrossAreaCarPool.service";
import RouteLineComponent from "./routeLineComponent";
import { getAllDriver } from "../../../../../actions/user";
import { getAllVehicles } from "../../../../../actions/vehicle";
import {
  getCrossAreaCarPoolBookingByStartDate,
  getCrossAreaCarPoolBookingByStartDateAndEndDate,
} from "../../../../../actions/crossAreaCarPoolBooking";

const StyledRoot = styled("div")(({ theme }) => ({
  "& .divCoverReturn": {
    height: "calc(100% - 20px)",
    paddingTop: "20px",
  },
  "& .partOfTitle": {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "40px",
    marginBottom: "20px",
  },
  "& .partOfFilter": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  "& .DatePicker": {
    width: "200px",
    marginRight: "15px",
    [theme.breakpoints.down("md")]: {
      width: "170px",
      marginRight: "0px",
    },
  },
  "& .Title": {
    fontSize: "30px",
  },
  "& .Content": {
    width: "100%",
    overflowX: "auto",
  },
  "& .partOfRoute": {
    width: "100%",
    minWidth: "1220px",
  },
  "& .Route": {
    display: "flex",
    alignItems: "center",
    margin: "0px 2% 30px",
  },
  "& .partOfOrderRoute": {
    minWidth: "60px",
    flexBasis: "5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& orderRoute": {
    width: "50px",
    height: "50px",
    border: "3px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
  },
  "& TextOrderRoute": {
    color: theme.palette.primary.main,
    fontSize: "30px",
  },
  "& Stepper": {
    flexBasis: "80%",
  },
  "& partOfManageCar": {
    flexBasis: "15%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  "& ManageCarButton": {
    width: "100px",
    height: "50px",
    fontSize: "20px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    ["&:hover"]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  "& Icon": {
    fontSize: "35px",
    color: "white",
  },
  "& EditButton": {
    backgroundColor: theme.palette.grey[500],
    ["&:hover"]: {
      backgroundColor: theme.palette.grey[700],
    },
  },
  "& DeleteButton": {
    backgroundColor: "red",
    ["&:hover"]: {
      backgroundColor: "darkred",
    },
  },
  "& Modal": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& Paper": {
    width: "90%",
    maxWidth: "350px",
    padding: "20px",
  },
  "& TextModalDetail": {
    fontSize: "20px",
  },
  "& TextNoBooking": {
    display: "grid",
    textAlign: "center",
    width: "100px",
  },
}));

const CrossAreaPool = (props) => {
  const dispatch = useDispatch();
  const {
    result: AllBookingCrossAreaPool,
    isFetching: loading,
    isError: isError,
  } = useSelector((state) => state.crossAreaCarPool);
  const [actionInPage, setActionInPage] = useState(false);

  console.log(AllBookingCrossAreaPool);
  const handleActionInPage = () => {
    console.log("actionInPage", actionInPage);
    setActionInPage(!actionInPage);
    if (props.endDate) {
      let date = {
        startDate: new Date(props.startDate),
        endDate: new Date(props.endDate),
      };
      dispatch(getCrossAreaCarPoolBookingByStartDateAndEndDate(date));
    } else {
      let date = {
        startDate: new Date(props.startDate),
      };
      dispatch(getCrossAreaCarPoolBookingByStartDate(date));
    }
  };

  const onClickDetail = (id) => {
    props.history.push("/admin/car_management/cross_area_pool/detail", {
      id: id,
    });
  };

  const RouteLine = () => {
    let allRoutes = [];
    if (AllBookingCrossAreaPool) {
      for (let i = 0; i < AllBookingCrossAreaPool.length; i++) {
        for (let j = 0; j < AllBookingCrossAreaPool[i].routes.length; j++) {
          allRoutes.push(AllBookingCrossAreaPool[i].routes[j]);
        }
      }
    }
    const routeLine = utils.groupBy(allRoutes, (route) => route.routeLine);
    // console.log(routeLine, Array.from(routeLine.values()).length);
    if (
      AllBookingCrossAreaPool &&
      Array.from(routeLine.values()).length > 0 &&
      !loading
    ) {
      const routes = [...routeLine.values()];

      return (
        <>
          <StyledRoot>
            <div className="partOfTitle">
              <Typography className="Title">
                ใช้รถจำนวน{" "}
                <span
                  style={{ color: "red", fontSize: "35px", margin: "0 10px" }}
                >
                  {routes.length}
                </span>{" "}
                คัน
              </Typography>
            </div>
            <div className="Content">
              <div className="partOfRoute">
                {routes.map((route, index) => {
                  return (
                    <RouteLineComponent
                      key={index}
                      index={index + 1}
                      route={route}
                      handleActionInPage={handleActionInPage}
                      onClickDetail={onClickDetail}
                    />
                  );
                })}
              </div>
            </div>
          </StyledRoot>
        </>
      );
    } else if (
      (!AllBookingCrossAreaPool ||
        Array.from(routeLine.values()).length <= 0) &&
      !loading
    ) {
      return (
        <StyledRoot>
          <Grid container spacing={2} marginTop={3}>
            <Grid item xs={12} container justifyContent="center">
              <Typography
                fontSize={30}
                marginBottom={2}
                // marginTop={1}
                textAlign="center"
              >
                ไม่มีเส้นทาง
              </Typography>
            </Grid>
          </Grid>
        </StyledRoot>
      );
    } else {
      return (
        <Grid item xs={12} container justifyContent="center">
          <Paper
            sx={{
              overflow: "hidden",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FFFFFF",
              marginBottom: "20px",
              padding: "25px 30px 25px 30px",
              borderRadius: "15px",
              textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
            }}
          >
            <img
              src={UnicornLoading}
              alt="Loading..."
              style={{ width: "300px" }}
            />
            {/* ปรับขนาดของภาพตามที่ต้องการ */}
          </Paper>
        </Grid>
      );
    }
  };

  return (
    <StyledRoot>
      <div className="divCoverReturn">{RouteLine()}</div>
    </StyledRoot>
    // <div className={`${classes.DivCoverReturn}`}>
    //     {AllBookingCrossAreaPool.length > 0 && RouteLine()}
    // </div>
  );
};

export default CrossAreaPool;
