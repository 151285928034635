import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import { useSelector, useDispatch } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import { Typography, Grid, TextField, Paper } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import UnicornLoading from "../../../assets/loading.gif";
import {
  getCrossAreaCarBookingByStartDate,
  getCrossAreaCarBookingByStartDateAndEndDate,
} from "../../../../../actions/crossAreaCarBooking";
import CrossAreaCarBookingService from "../../../../../services/crossAreaCarBooking.service";

import CardBooking from "./cardBooking";

require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    height: "calc(100% - 30px)",
    paddingTop: "20px",
  },
  partOfFilter: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  DatePicker: {
    width: "200px",
    marginRight: "15px",
    [theme.breakpoints.down("md")]: {
      width: "170px",
      marginRight: "0px",
    },
  },
  partOfAllCard: {
    width: "100%",
    height: "calc(100% - 100px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "15px",
    overflow: "hidden",
    overflowY: "auto",
    paddingTop: "20px",
    ["&::-webkit-scrollbar"]: {
      width: "10px",
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    ["&::-webkit-scrollbar-thumb"]: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "3px",
    },
  },
  TextNoBooking: {
    fontSize: "30px",
    textAlign: "center",
  },
  Grid: {
    width: "95%",
  },
  GridItem: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  Card: {
    width: "100%",
    height: "120px",
    boxShadow: theme.shadows[3],
    display: "flex",
    padding: "0 10px",
    [theme.breakpoints.down("lg")]: {
      height: "fit-content",
      flexDirection: "column",
      padding: "15px 0",
    },
  },
  partOfAvatar: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexBasis: "10%",
    [theme.breakpoints.down("lg")]: {
      height: "fit-content",
    },
  },
  Avatar: {
    width: "90px",
    height: "90px",
    [theme.breakpoints.down("lg")]: {
      width: "120px",
      height: "120px",
    },
  },
  partOfName: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexBasis: "25%",
    marginLeft: "20px",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "0",
      display: "block",
    },
  },
  Text: {
    fontSize: "20px",
  },
  HighlightText: {
    fontWeight: "bold",
  },
  TextContentPartName: {
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
  },
  partOfDate: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexBasis: "15%",
  },
  TextDateAndTime: {
    fontSize: "25px",
    [theme.breakpoints.down("lg")]: {
      margin: "-5px 0",
    },
  },
  partOfNumPassenger: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexBasis: "15%",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "row",
      margin: "-5px 0 -10px",
    },
  },
  TextNumPassenger: {
    fontSize: "40px",
    [theme.breakpoints.down("lg")]: {
      marginRight: "10px",
    },
  },
  partOfStatus: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexBasis: "15%",
    [theme.breakpoints.down("lg")]: {
      margin: "5px 0",
    },
  },
  TextSuccess: {
    color: "green",
    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
    },
  },
  TextWaiting: {
    color: "darkorange",
    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
    },
  },
  partOfNavButton: {
    width: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    // flexBasis:"20%",
    position: "absolute",
    right: "20px",
    top: "calc(50% + 15px)",
    transform: "translateY(-50%)",
    zIndex: "2",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      height: "60px",
      flexBasis: "auto",
    },
  },
  ManageButton: {
    width: "150px",
    fontSize: "20px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ["&:hover"]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  Icon: {
    fontSize: "35px",
    color: "white",
  },
  EditButton: {
    backgroundColor: theme.palette.grey[500],
    ["&:hover"]: {
      backgroundColor: theme.palette.grey[700],
    },
  },
  DeleteButton: {
    backgroundColor: "red",
    ["&:hover"]: {
      backgroundColor: "darkred",
    },
  },
}));

const CrossArea = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { result: allBookingCrossArea, isFetching: loading } = useSelector(
    (state) => state.crossAreaCar
  );
  // const [allBookingCrossArea, setAllBookingCrossArea] = useState([]);
  const [submitModal, setSubmitModal] = useState(false);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   console.log("why?");
  //   if (props.endDate) {
  //     console.log("1");
  //     let date = {
  //       startDate: new Date(props.startDate),
  //       endDate: new Date(props.endDate),
  //     };
  //     dispatch(getCrossAreaCarBookingByStartDateAndEndDate(date));
  //   } else {
  //     console.log("2");
  //     let date = {
  //       startDate: new Date(props.startDate),
  //     };
  //     dispatch(getCrossAreaCarBookingByStartDate(date));
  //   }
  // }, []);

  const onClickSubmitModal = () => {
    setSubmitModal(!submitModal);
    if (props.endDate) {
      let date = {
        startDate: new Date(props.startDate),
        endDate: new Date(props.endDate),
      };
      dispatch(getCrossAreaCarBookingByStartDateAndEndDate(date));
    } else {
      let date = {
        startDate: new Date(props.startDate),
      };
      dispatch(getCrossAreaCarBookingByStartDate(date));
    }
  };
  const onClickCard = (booking) => {
    props.history.push("/admin/car_management/cross_area/detail", {
      booking: booking,
    });
  };

  return (
    <div className={`${classes.DivCoverReturn}`}>
      <div className={`${classes.partOfAllCard}`}>
        <Grid container spacing={3} className={`${classes.Grid}`}>
          {!loading && allBookingCrossArea && allBookingCrossArea.length > 0 ? (
            allBookingCrossArea.map((booking, index) => {
              return (
                <CardBooking
                  key={booking.id}
                  booking={booking}
                  onClickCard={onClickCard}
                  onClickSubmitModal={onClickSubmitModal}
                />
              );
            })
          ) : (
            <>
              {!loading && (
                <Grid item xs={12}>
                  <Typography className={`${classes.TextNoBooking}`}>
                    ไม่มีรายการ
                  </Typography>
                </Grid>
              )}
            </>
          )}
          {loading && (
            <Grid item xs={12} container justifyContent="center">
              <Paper
                sx={{
                  overflow: "hidden",
                  border: "1px solid #EEEEEE",
                  backgroundColor: "#FFFFFF",
                  marginBottom: "20px",
                  padding: "25px 30px 25px 30px",
                  borderRadius: "15px",
                  textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                }}
              >
                <img
                  src={UnicornLoading}
                  alt="Loading..."
                  style={{ width: "300px" }}
                />
                {/* ปรับขนาดของภาพตามที่ต้องการ */}
              </Paper>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default CrossArea;
