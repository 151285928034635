import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/styles";
import {
  Paper,
  Button,
  Typography,
  Grid,
  Avatar,
  useMediaQuery,
  Divider,
  IconButton,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import DetailCrossAreaCar from "./detailCrossAreaCar";
import DetailInAreaCar from "./detailInAreaCar";
import DetailDeliveryCar from "./detailDeliveryCar";
import DetailCrossAreaCarPool from "./detailCrossAreaCarPool";
import DetailDriverBooking from "./detailDriverBooking/index.js";
import ModalShowmap from "./showMap/index.js";
import DetailMeetingRoomBooking from "./detailMeetingRoomBooking";

import siteService from "../../../../services/site.service.js";

import MeetingRoomService from "../../../../services/meetingRoom.service";
import { locationSite1 } from "../../../../utils/googleMapAssets.js";

const DivCoverReturn = styled("div")({
  height: "fit-content",
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
});

const PaperStyled = styled(Paper)(({ theme }) => ({
  width: "80%",
  maxWidth: "950px",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBottom: "20px",
  marginBottom: "20px",
  boxShadow: theme.shadows[3],
  position: "relative",
  padding: "0 20px",
  "& .BackButton": {
    width: "fit-content",
    color: "black",
    position: "absolute",
    top: "13px",
    left: "0",
    padding: "0 25px",
    ["&:hover"]: {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      top: "9px",
      padding: "0 10px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "0 15px",
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  position: "absolute",
  top: "13px",
  left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  width: "150px",
  height: "50px",
  color: "white",
  fontSize: "20px",
  [theme.breakpoints.down("sm")]: {
    width: "fit-Content",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: "30px",
  marginTop: "10px",
}));

const GridContainer = styled(Grid)({
  width: "100%",
});

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  height: "100%",
  width: "300px",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  backgroundColor: "#DDDDDD",
}));

const GridNavButton = styled(Grid)(({ theme }) => ({
  "& .DisapproveButton": {
    backgroundColor: "red",
    ["&:hover"]: {
      backgroundColor: "darkred",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .ApproveButton": {
    backgroundColor: "green",
    ["&:hover"]: {
      backgroundColor: "darkgreen",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "10px",
  },
}));

const GridButton = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});

const Price = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: "30px",
  margin: "10px 0",
}));
const ViewButton = styled(Button)({
  border: "1px solid rgba(25, 118, 210, 0.5)",
  color: "black",
  width: "100%",
  fontSize: "20px",
  // backgroundColor: "#3970C2",
  color: "#3970C2",
});

const DetailBookingPage = (props) => {
  const { result: crossAreaBookings } = useSelector(
    (state) => state.crossAreaCar
  );
  const { result: crossAreaPoolBookings } = useSelector(
    (state) => state.crossAreaCarPool
  );
  const { result: inAreaBookings } = useSelector((state) => state.inAreaCar);
  const { result: deliveryBookings } = useSelector(
    (state) => state.deliveryCar
  );
  const { result: meetingRoomBookings } = useSelector(
    (state) => state.meetingRoom
  );
  const { result: driverBookings } = useSelector(
    (state) => state.driverBooking
  );
  const [typeBooking, setTypeBooking] = useState(null);
  const [booking, setBooking] = useState(null);
  const [isManager, setIsManager] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const Width900 = useMediaQuery("(max-width:900px)");

  const [meetingRoom, setMeetingRoom] = useState(null);
  const [imageMeetingRoom, setImageMeetingRoom] = useState(null);
  const [isLocationDriver, setLocationDriver] = useState([
    { Lat: locationSite1.lat, Long: locationSite1.lng },
  ]);

  useEffect(() => {
    if (props.location.state) {
      if (props.location.state.typeBooking === "CrossArea") {
        setBooking(
          crossAreaBookings.find(
            (book) =>
              book.idCrossAreaCarBooking === props.location.state.idBooking
          )
        );
      } else if (props.location.state.typeBooking === "CrossAreaPool") {
        setBooking(
          crossAreaPoolBookings.find(
            (book) =>
              book.idCrossAreaCarPoolBooking === props.location.state.idBooking
          )
        );
      } else if (props.location.state.typeBooking === "InArea") {
        setBooking(
          inAreaBookings.find(
            (book) => book.idinAreaCarBooking === props.location.state.idBooking
          )
        );
      } else if (props.location.state.typeBooking === "Delivery") {
        setBooking(
          deliveryBookings.find(
            (book) =>
              book.idDeliveryCarBooking === props.location.state.idBooking
          )
        );
      } else if (props.location.state.typeBooking === "MeetingRoom") {
        setBooking(
          meetingRoomBookings.find(
            (book) =>
              book.idNewMeetingRoomBooking === props.location.state.idBooking
          )
        );
        MeetingRoomService.getAllMeetingRoomById(
          props.location.state.idImageBooking
        ).then((res) => {
          if (res.data) {
            setMeetingRoom(res.data[0]);
          }
        });
        MeetingRoomService.getIAllImageNewMeetingRoomById(
          props.location.state.idImageBooking
        ).then((res) => {
          if (res.data) {
            setImageMeetingRoom(res.data[0]);
          } else {
            setImageMeetingRoom([]);
          }
        });
      } else if (props.location.state.typeBooking === "DriverBooking") {
        setBooking(
          driverBookings.find(
            (book) => book.idDriverBooking === props.location.state.idBooking
          )
        );
        // MeetingRoomService.getAllMeetingRoomById(
        //   props.location.state.idBooking
        // ).then((res) => {
        //   if (res.data) {
        //     setMeetingRoom(res.data[0]);
        //   }
        // });
        // MeetingRoomService.getIAllImageNewMeetingRoomById(
        //   props.location.state.idBooking
        // ).then((res) => {
        //   if (res.data) {
        //     setImageMeetingRoom(res.data[0]);
        //   }
        // });
      }

      // setBooking(Bookings.find(book => book.id === props.location.state.idBooking));
      setTypeBooking(props.location.state.typeBooking);
      if (props.location.pathname.split("/")[1] === "manager") {
        setIsManager(true);
      }
    }
  }, []);

  useEffect(() => {
    if (booking) {
      siteService
        .getLocationDriverById(booking.idDriver)
        .then((res) => {
          if (res.data) {
            setLocationDriver([
              { Lat: res.data[0].LatDriver, Long: res.data[0].LngDriver },
            ]);
          }
        })
        .catch((error) => {
          setLocationDriver([
            { Lat: locationSite1.lat, Long: locationSite1.lng },
          ]);
        });
    }
  }, [booking]);

  const onCloseModalHandle = () => {
    setOpenModal(false);
  };

  const onClickOpenModal = () => {
    setOpenModal(true);
  };

  const TextUnderPicture = () => {
    if (typeBooking && booking) {
      if (typeBooking === "CrossArea") {
        return (
          <Typography style={{ textAlign: "center", fontSize: "20px" }}>
            {`${booking.vehicleTypes.vehicleTypeNameTH} ${booking.vehicleBrandsAndModels.model}`}
          </Typography>
        );
      } else if (typeBooking === "InArea") {
        return (
          <Typography style={{ textAlign: "center", fontSize: "20px" }}>
            {`${booking.vehicleTypes.vehicleTypeNameTH} GA ${
              booking.gaSite ? booking.gaSite : "ไม่ระบุ"
            }`}
          </Typography>
        );
      }
    }
  };

  const TextBooking = () => {
    if (typeBooking) {
      if (typeBooking === "CrossArea") {
        return <Text>การจองรถข้ามพื้นที่เฉพาะ</Text>;
      } else if (typeBooking === "CrossAreaPool") {
        return <Text>การจองรถข้ามพื้นที่ Pool</Text>;
      } else if (typeBooking === "InArea") {
        return <Text>การจองรถในพื้นที่</Text>;
      } else if (typeBooking === "MeetingRoom") {
        return <Text>การจองห้องประชุม</Text>;
      } else if (typeBooking === "DriverBooking") {
        return <Text>การขอจองคนขับรถ</Text>;
      } else {
        return <Text>การขอรถรับส่งของ</Text>;
      }
    }
  };

  const TextFlight = () => {
    if (booking) {
      if (booking.flight === "oneWay") {
        return <Text>ประเภท : เที่ยวเดียว</Text>;
      } else if (booking.flight === "twoWay") {
        return <Text>ประเภท : ไป-กลับ</Text>;
      } else {
        return <Text>ประเภท : รอรับกลับ</Text>;
      }
    }
  };

  const onClickWatchTimeOfCrossAreaCarPool = () => {
    props.history.push("/myCrossAreaCarPool", {
      routeDate: booking.departureDate,
    });
  };

  const onClickGoBack = (istypeBooking) => {
    if (isManager) {
      props.history.push(`/manager/approved`);
    } else {
      if (istypeBooking) {
        if (istypeBooking === "CrossArea") {
          props.history.push(`/myListOfRequest/cross_area_car_booking`);
        } else if (istypeBooking === "CrossAreaPool") {
          props.history.push(`/myListOfRequest/cross_area_car_pool_booking`);
        } else if (istypeBooking === "InArea") {
          props.history.push(`/myListOfRequest/in_area_car_booking`);
        } else if (istypeBooking === "Delivery") {
          props.history.push(`/myListOfRequest/delivery_car_booking`);
        } else if (istypeBooking === "MeetingRoom") {
          props.history.push(`/myListOfRequest/meeting_room_booking`);
        } else {
          props.history.push(`/myListOfRequest/driver_booking`);
        }
      }
    }
  };
  return (
    <DivCoverReturn className="page">
      {booking &&
        typeBooking != "MeetingRoom" &&
        typeBooking != "DriverBooking" && (
          <PaperStyled>
            {/* <BackButton disableRipple onClick={props.history.goBack}>
            <ArrowBackIcon className="Icon" />
          </BackButton> */}
            <BackButton
              disableRipple
              onClick={() => {
                onClickGoBack(typeBooking);
              }}
            >
              <ArrowBackIcon className="Icon" />
            </BackButton>
            {/* <ButtonStyled
                        className="BackButton"
                        startIcon={<ArrowBackIcon />}
                        onClick={props.history.goBack}
                    >
                        Back
                    </ButtonStyled> */}
            <Title>การจองรถ</Title>
            <GridContainer container>
              <Grid container item xs={12} md={6}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AvatarStyled
                    style={{ height: "calc(100% - 30px)", width: "300px" }}
                    src={`${process.env.REACT_APP_URL}image/car/${
                      booking.vehicleTypes
                        ? booking.vehicleTypes.imagepath
                        : "car6.jpeg"
                    }`}
                    alt={
                      booking.vehicleTypes
                        ? booking.vehicleTypes.imagepath
                        : "car6.jpeg"
                    }
                    variant="square"
                  />
                  {typeBooking !== "Delivery" && TextUnderPicture()}
                </div>
              </Grid>
              {Width900 && (
                <Grid item xs={12}>
                  <DividerStyled />
                </Grid>
              )}
              <Grid
                container
                item
                xs={12}
                md={6}
                alignContent="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  {TextBooking()}
                </Grid>
                {typeBooking !== "Delivery" ? (
                  <>
                    <Grid item xs={12}>
                      <Text>{`จำนวนผู้โดยสาร : ${booking.numberOfPassenger} คน`}</Text>
                      {/* {booking.passengers.map((passengers) => 
                                        (<>
                                            <Text item xs={12}>{`ชื่อ : ${passengers.name}`}</Text>
                                            <Text item xs={12}>{`เบอร์ : ${passengers.telephoneMobile}`}</Text>
                                         </>
                                        ))} */}
                    </Grid>
                    {typeBooking === "CrossAreaPool" &&
                      booking.flight === "twoWay" && (
                        <Grid item xs={12}>
                          <Text>{`จำนวนผู้โดยสารขากลับ : ${booking.numberOfPassengerReturn} คน`}</Text>
                        </Grid>
                      )}
                    <Grid item xs={12}>
                      {TextFlight()}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Text>{`ประเภทของที่ส่ง : ${booking.typeProduct}`}</Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Text>{`น้ำหนัก : ${booking.weightProduct} kg`}</Text>
                    </Grid>
                  </>
                )}
                {typeBooking !== "CrossAreaPool" && (
                  <Grid item xs={12}>
                    <Text>{`วัตถุประสงค์ : ${
                      booking.purpose && booking.purpose !== ""
                        ? booking.purpose
                        : "ไม่ระบุ"
                    }`}</Text>
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={12} md={12} alignContent="center">
                <ViewButton onClick={onClickOpenModal}>แสดงแผนที่</ViewButton>
              </Grid>
            </GridContainer>
            <DividerStyled />
            <GridContainer container spacing={1}>
              <Grid item xs={12} md={6}>
                <Text>{`ผู้จอง : ${booking.name}`}</Text>
              </Grid>
              <Grid item xs={12} md={6}>
                <Text>{`เบอร์โทรศัพท์ : ${booking.telephoneMobile}`}</Text>
              </Grid>
              <Grid item xs={12} md={6}>
                <Text>{`email : ${booking.email}`}</Text>
              </Grid>
            </GridContainer>
            <DividerStyled />
            {typeBooking === "CrossArea" && (
              <DetailCrossAreaCar booking={booking} isManager={isManager} />
            )}
            {typeBooking === "CrossAreaPool" && (
              <DetailCrossAreaCarPool
                booking={booking}
                onClickWatchTime={onClickWatchTimeOfCrossAreaCarPool}
              />
            )}
            {typeBooking === "InArea" && (
              <DetailInAreaCar booking={booking} isManager={isManager} />
            )}
            {typeBooking === "Delivery" && (
              <DetailDeliveryCar booking={booking} />
            )}
            <DividerStyled />
            <Price>{`ค่าใช้จ่าย  :   ${
              booking.totalPrice ? booking.totalPrice : "ไม่ระบุ"
            }  THB   \(ไม่รวมค่าล่วงเวลา\)`}</Price>
            {isManager && (
              <>
                <DividerStyled />
                <GridNavButton container justifyContent="center" spacing={2}>
                  <GridButton item xs={12} sm={4}>
                    <ButtonStyled
                      className="ApproveButton"
                      startIcon={<CheckIcon />}
                    >
                      อนุมัติ
                    </ButtonStyled>
                  </GridButton>
                  <GridButton item xs={12} sm={4}>
                    <ButtonStyled
                      className="DisapproveButton"
                      startIcon={<CloseIcon />}
                    >
                      ไม่อนุมัติ
                    </ButtonStyled>
                  </GridButton>
                </GridNavButton>
              </>
            )}
          </PaperStyled>
        )}

      {booking && meetingRoom && typeBooking === "MeetingRoom" && (
        <PaperStyled>
          <BackButton
            disableRipple
            onClick={() => {
              onClickGoBack(typeBooking);
            }}
          >
            <ArrowBackIcon className="Icon" />
          </BackButton>
          <Title>การห้องประชุม</Title>
          <GridContainer container>
            <Grid container item xs={12} md={6}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {imageMeetingRoom && (
                  <AvatarStyled
                    style={{ height: "calc(100% - 30px)", width: "300px" }}
                    src={`${imageMeetingRoom.imageBase64}`}
                    alt={imageMeetingRoom.imageBase64}
                    variant="square"
                  />
                )}
                {!imageMeetingRoom && meetingRoom && meetingRoom.fileUrl[0] && (
                  <AvatarStyled
                    style={{ height: "calc(100% - 30px)", width: "300px" }}
                    src={`${meetingRoom.fileUrl[0]}`}
                    alt={meetingRoom.fileUrl[0]}
                    variant="square"
                  />
                )}
                {typeBooking !== "Delivery" && TextUnderPicture()}
              </div>
            </Grid>
            {Width900 && (
              <Grid item xs={12}>
                <DividerStyled />
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              md={6}
              alignContent="center"
              spacing={2}
            >
              <Grid item xs={12}>
                {TextBooking()}
              </Grid>
              {typeBooking !== "Delivery" ? (
                <>
                  <Grid item xs={12}>
                    <Text>{`สถานที่: ${meetingRoom.nameMeetingRoom}`}</Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Text>{`จำนวนคน: ${booking.numOfPeople} คน`}</Text>
                  </Grid>
                </>
              ) : null}
            </Grid>
            {/* <Grid container item xs={12} md={12} alignContent="center">
              <ViewButton onClick={onClickOpenModal}>แสดงแผนที่</ViewButton>
            </Grid> */}
          </GridContainer>
          <DividerStyled />
          <GridContainer container spacing={1}>
            <Grid item xs={12} md={6}>
              <Text>{`ผู้จอง : ${booking.nameUser}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`เบอร์โทรศัพท์ : ${booking.phoneUser}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`email : ${booking.emailUser}`}</Text>
            </Grid>
          </GridContainer>
          <DividerStyled />

          {typeBooking === "MeetingRoom" && (
            <DetailMeetingRoomBooking booking={booking} />
          )}
          <DividerStyled />
          <Price>{`ค่าใช้จ่าย  :   ${parseFloat(
            booking.totalPrice
          ).toLocaleString("en-US")}  THB   `}</Price>
          {isManager && (
            <>
              <DividerStyled />
              <GridNavButton container justifyContent="center" spacing={2}>
                <GridButton item xs={12} sm={4}>
                  <ButtonStyled
                    className="ApproveButton"
                    startIcon={<CheckIcon />}
                  >
                    อนุมัติ
                  </ButtonStyled>
                </GridButton>
                <GridButton item xs={12} sm={4}>
                  <ButtonStyled
                    className="DisapproveButton"
                    startIcon={<CloseIcon />}
                  >
                    ไม่อนุมัติ
                  </ButtonStyled>
                </GridButton>
              </GridNavButton>
            </>
          )}
        </PaperStyled>
      )}
      {booking && typeBooking === "DriverBooking" && (
        <PaperStyled>
          <BackButton
            disableRipple
            onClick={() => {
              onClickGoBack(typeBooking);
            }}
          >
            <ArrowBackIcon className="Icon" />
          </BackButton>
          <Title>การขอจองคนขับรถ</Title>
          <GridContainer container>
            <Grid container item xs={12} md={6}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <AvatarStyled
                  style={{ height: "200px", width: "200px", flexShrink: 0 }}
                  src={`${process.env.REACT_APP_URL}image/profile/${
                    booking.driver ? booking.driver.image : "1.jpg"
                  }`}
                  alt={booking.driver ? booking.driver.image : "1.jpg"}
                  variant="circle"
                />
              </div>
            </Grid>
            {Width900 && (
              <Grid item xs={12}>
                <DividerStyled />
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              md={6}
              alignContent="center"
              spacing={2}
            >
              <Grid item xs={12}>
                {TextBooking()}
              </Grid>

              <Grid item xs={12}>
                <Text>{`ชื่อคนขับรถ : ${
                  booking.nameDriver && booking.nameDriver !== ""
                    ? booking.nameDriver
                    : " - "
                }`}</Text>
              </Grid>

              <Grid item xs={12}>
                <Text>{`เบอร์โทร : ${
                  booking.phoneDriver && booking.phoneDriver !== ""
                    ? booking.phoneDriver
                    : " - "
                }`}</Text>
              </Grid>

              <Grid item xs={12}>
                <Text>{`ประเภท : ${
                  booking.twoWay == 0 ? "เที่ยวเดียว" : "ไป-กลับ"
                }`}</Text>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={12} alignContent="center">
              <ViewButton onClick={onClickOpenModal}>แสดงแผนที่</ViewButton>
            </Grid>
          </GridContainer>
          <DividerStyled />
          <GridContainer container spacing={1}>
            <Grid item xs={12} md={6}>
              <Text>{`ผู้จอง : ${booking.nameUser}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`เบอร์โทรศัพท์ : ${booking.user.telephoneMobile}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`email : ${booking.user.email}`}</Text>
            </Grid>
          </GridContainer>
          <DividerStyled />
          {typeBooking === "CrossArea" && (
            <DetailCrossAreaCar booking={booking} isManager={isManager} />
          )}
          {typeBooking === "CrossAreaPool" && (
            <DetailCrossAreaCarPool
              booking={booking}
              onClickWatchTime={onClickWatchTimeOfCrossAreaCarPool}
            />
          )}
          {typeBooking === "InArea" && (
            <DetailInAreaCar booking={booking} isManager={isManager} />
          )}
          {typeBooking === "Delivery" && (
            <DetailDeliveryCar booking={booking} />
          )}
          {typeBooking === "DriverBooking" && (
            <DetailDriverBooking booking={booking} />
          )}
          <DividerStyled />
          <Price>{`ค่าใช้จ่าย  :   6,400  THB   \(ไม่รวมค่าล่วงเวลา\)`}</Price>
          {isManager && (
            <>
              <DividerStyled />
              <GridNavButton container justifyContent="center" spacing={2}>
                <GridButton item xs={12} sm={4}>
                  <ButtonStyled
                    className="ApproveButton"
                    startIcon={<CheckIcon />}
                  >
                    อนุมัติ
                  </ButtonStyled>
                </GridButton>
                <GridButton item xs={12} sm={4}>
                  <ButtonStyled
                    className="DisapproveButton"
                    startIcon={<CloseIcon />}
                  >
                    ไม่อนุมัติ
                  </ButtonStyled>
                </GridButton>
              </GridNavButton>
            </>
          )}
        </PaperStyled>
      )}

      <ModalShowmap
        open={openModal}
        onCloseHandle={onCloseModalHandle}
        inforInCar={booking}
        isLocationDriver={isLocationDriver}
      />
    </DivCoverReturn>
  );
};

export default DetailBookingPage;
