import { httpClient } from "./httpClient";

const postNewMeetingRoom = (formData) => {
  return httpClient.post("add_new_meeting_room", formData, {
    "content-type": "multipart/form-data",
  });
};

const postUpdateNewMeetingRoom = (formData) => {
  return httpClient.post("update_new_meeting_room", formData, {
    "content-type": "multipart/form-data",
  });
};

const getDeleteNewMeetingRoomById = (index) => {
  return httpClient.get("delete_new_meeting_room/" + index);
};

const getAlltNewMeetingRoom = () => {
  return httpClient.get("all_new_meeting_room");
};
const getIAllImageNewMeetingRoomById = (id) => {
  return httpClient.get("all_image_new_meeting_room_byId/" + id);
};
const getAllMeetingRoomById = (id) => {
  return httpClient.get("all_meeting_room_byId/" + id);
};
const postNewMeetingRoomBooking = (newMeetingRoomBooking) => {
  return httpClient.post("add_new_meeting_room_booking", [
    newMeetingRoomBooking,
  ]);
};

const postUpdateMeetingRoomBooking = (newMeetingRoomBooking) => {
  return httpClient.post("update_meeting_room_booking", [
    newMeetingRoomBooking,
  ]);
};

const getMeetingRoomBookingByIdUser = (idUser) => {
  return httpClient.get("get_meeting_room_booking_byIdUser/" + idUser);
};
const getAllMeetingRoomBooking = () => {
  return httpClient.get("all_new_meeting_room_booking");
};

const getAllMeetingRoomBookingIsNotFinished = () => {
  return httpClient.get("all_new_meeting_room_booking_is_not_finish");
};
const getAllMeetingRoomBookingAndAllMeetingRoom = (filter) => {
  return httpClient.post(
    "all_new_meeting_room_booking_and_all_meeting_room",
    filter
  );
};
const postApprovedlMeetingRoomBooking = (approved) => {
  return httpClient.post("approved_meeting_room_booking", approved);
};
const getMeetingRoomBookingByIdUserForRating = (idUser) => {
  return httpClient.get("meeting_room_booking_byIdUser_ForRating/" + idUser);
};
const getMeetingRoomBookingByFilter = (filter) => {
  return httpClient.post("meeting_room_booking_ByFilter", filter);
};
const getAllMeetingRoomBookingByIdMeetingRoom = (id) => {
  return httpClient.get("get_meeting_room_booking_byIdMeetingRoom/" + id);
};
const getMeetingRoomBookingById = (id) => {
  return httpClient.get("get_meeting_room_booking_byId/" + id);
};
export default {
  postNewMeetingRoom,
  postUpdateNewMeetingRoom,
  getDeleteNewMeetingRoomById,
  getAlltNewMeetingRoom,
  getIAllImageNewMeetingRoomById,
  getAllMeetingRoomById,
  postNewMeetingRoomBooking,
  postUpdateMeetingRoomBooking,
  getMeetingRoomBookingByIdUser,
  getAllMeetingRoomBooking,
  getAllMeetingRoomBookingIsNotFinished,
  getAllMeetingRoomBookingAndAllMeetingRoom,
  postApprovedlMeetingRoomBooking,
  getMeetingRoomBookingByIdUserForRating,
  getMeetingRoomBookingByFilter,
  getAllMeetingRoomBookingByIdMeetingRoom,
  getMeetingRoomBookingById,
};
