import { httpClient } from "./httpClient";

const API_URL = process.env.REACT_APP_API_URL;

const getAllInAreaCarBookings = () => {
  return httpClient.get("in_area_car_bookings");
};

const getInAreaCarBookingById = (id) => {
  return httpClient.get("in_area_car_booking/" + id);
};

const getInAreaCarBookingByIdUser = (idUser) => {
  return httpClient.get("in_area_car_booking_byIdUser/" + idUser);
};

const getInAreaCarBookingByIdDriver = (idDriver) => {
  return httpClient.get("in_area_car_booking_byIdDriver/" + idDriver);
};

const getInAreaCarBookingByIdApprovedUserForManager = (idApprovedUser) => {
  return httpClient.get(
    "in_area_car_booking_byIdApprovedUser/" + idApprovedUser
  );
};

const getInAreaCarBookingByStartDate = (date) => {
  return httpClient.post("in_area_car_bookingBystartDate", date);
};

const getInAreaCarBookingByStartDateAndEndDate = (date) => {
  return httpClient.post("in_area_car_bookingByStartDateAndendDate", date);
};
const postNewInAreaCarBooking = (newInArea) => {
  return httpClient
    .post(API_URL + "post_New_In_Area_Car_Booking", newInArea)
    .then((Response) => {
      if (Response.data.type === "success") {
        return Response.data;
      }
    });
};

const postUpdateInAreaCarBooking = (newInArea) => {
  return httpClient
    .post(API_URL + "post_Update_In_Area_Car_Booking", newInArea)
    .then((Response) => {
      if (Response.data.type === "success") {
        return Response.data;
      }
    });
};
const postManageCarInAreaCarBooking = (manageCar, idDriver) => {
  return httpClient.post("manage_car_in_area_car_booking", [
    manageCar,
    idDriver,
  ]);
};
const deleteCarInAreaCarBooking = (delCar) => {
  return httpClient.post("delete_car_in_area_car_booking", delCar);
};

const postApprovedInAreaCarBooking = (approved) => {
  return httpClient.post("approved_in_area_car_booking", approved);
};
const getInAreaCarBookingByIdUserForRating = (idUser) => {
  return httpClient.get("in_area_car_booking_byIdUser_ForRating/" + idUser);
};
const getInAreaCarBookingByFilter = (filter) => {
  return httpClient.post("in_area_car_booking_ByFilter", filter);
};
const getInAreaCarBookingByFilterByIdDriver = (filter) => {
  return httpClient.post("in_area_car_booking_ByFilter_ByIdDriver", filter);
};
export default {
  getAllInAreaCarBookings,
  getInAreaCarBookingById,
  getInAreaCarBookingByIdUser,
  getInAreaCarBookingByIdDriver,
  getInAreaCarBookingByIdApprovedUserForManager,
  getInAreaCarBookingByStartDate,
  getInAreaCarBookingByStartDateAndEndDate,
  postNewInAreaCarBooking,
  postUpdateInAreaCarBooking,
  postManageCarInAreaCarBooking,
  deleteCarInAreaCarBooking,
  postApprovedInAreaCarBooking,
  getInAreaCarBookingByIdUserForRating,
  getInAreaCarBookingByFilter,
  getInAreaCarBookingByFilterByIdDriver,
};
