import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  styled,
  Stack,
  TextField,
  MenuItem,
  Typography,
  Grid,
  FormControl,
  Button,
  Select,
  Paper,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CateringRequestedCard from "./CateringRequestedCard";
import Container from "@mui/material/Container";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import cateringService from "../../../../../services/catering.service";
import ButtonBlue from "../../../shared/buttonBlue";
import UnicornLoading from "../../../assets/loading.gif";
import { SnackbarProvider, useSnackbar } from "notistack";
import CreateExcel from "../../../shared/createExcel";
import dayjs from "dayjs";
import { Description } from "@material-ui/icons";
import { de, id } from "date-fns/locale";
import { getUserProfile } from "../../../../../actions/user";
import utils from "../../../../../utils";
const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
  "& .TextNoBooking": {
    textAlign: "center",
    fontSize: "25px",
  },
}));

const Text = styled(Typography)({
  fontSize: "15px",
});

const GridHeaderTitle = styled(Grid)(({ theme }) => ({
  marginTop: "20px",
  marginBottom: "20px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
  },
}));
const GridHeaderButton = styled(Grid)(({ theme }) => ({
  marginTop: "20px",
  marginBottom: "20px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    marginTop: "0px",
  },
}));

const head_Catering = [
  "Catering ID",
  "Name",
  "Telephone",
  "Email",
  "Section",
  "Department",
  "Company",
  "Cost Center",
  "Cost Element",
  "Location",
  "Date",
  "Time",
  "Number of Guest",
  "Catering Type",
  "Purpose",
  "Description",
  "Budget",
  "additionalOption",
  "Total Cost",
  "Status",
  "Approver Name",
  "Approver Department",
  "Approver Company",
];
const RequestedList = (props) => {
  const dispatch = useDispatch();
  const [caterings, setCaterings] = useState([]);
  const [cateringFormat, setCateringFormat] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { register, getValues, control } = useForm();
  const [company, setCompany] = useState(userProfile?.companyName);
  const [isStartDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const changeStatus = (index, status) => {
    setLoading(true);
    cateringService
      .changeStatusById(index, status)
      .then((res) => {
        if (res.data.data.status) {
          cateringService
            .getAllCaterings({ status: getValues("status") || null })
            .then((res) => {
              setCaterings(res.data.data);
              setCateringFormatData(res.data.data);
              setLoading(false);
            });
          enqueueSnackbar("Update Status Success", { variant: "success" });
        } else {
          enqueueSnackbar("Update Status Failed", { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Update Status Failed", { variant: "error" });
        console.log(err);
      });
  };

  const onClickSearch = () => {
    setLoading(true);
    cateringService
      .getAllCaterings({
        status: getValues("status") || null,
        date: isStartDate,
      })
      .then((res) => {
        setCaterings(res.data.data);
        setCateringFormatData(res.data.data);
        setLoading(false);
      });
  };

  const onClickCard = (index) => {
    props.history.push(`/catering/requested/${index}`);
  };

  useEffect(() => {
    setLoading(true);
    if (!userProfile) {
      dispatch(getUserProfile(currentUser?.idUser));
    }
    cateringService.getAllCaterings().then((res) => {
      setCaterings(res.data.data);
      setCateringFormatData(res.data.data);
      setLoading(false);
    });
  }, []);

  const setCateringFormatData = (data) => {
    const format = data.map((item) => {
      // const additionalOption = item.additionalOption.map((item) => {
      //   return `${item.nameOption}(${item.costOption} บาท)`;
      // });
      let additionalOption = "";
      for (let i = 0; i < item.additionalOption.length; i++) {
        if (i === 0) {
          additionalOption = `${item.additionalOption[i].nameOption}(${item.additionalOption[i].costOption} บาท)`;
        } else {
          additionalOption = `${additionalOption}, ${item.additionalOption[i].nameOption}(${item.additionalOption[i].costOption} บาท)`;
        }
      }

      return {
        idCateringRequest: item.idCateringRequest,
        name: item.name,
        phoneNumber: item.phoneNumber,
        email: item.email,
        section: item.section,
        department: item.department,
        company: item.company,
        costCenter: item.costCenter,
        costElement: item.costElement,
        sendTo: item.sendTo,
        date: dayjs(item.date).format("DD MMM YYYY"),
        time: item.time,
        numberOfPeople: `${utils.numberWithCommas(item.numberOfPeople)} คน`,
        cateringTypeName: item.cateringTypeName,
        objective: item.objective,
        description: item.description,
        budget: `${utils.numberWithCommas(item.budget)} บาท`,
        additionalOption: additionalOption,
        totalCost: item.totalCost
          ? `${utils.numberWithCommas(item.totalCost)} บาท`
          : null,
        status:
          item.status === "approved"
            ? "อนุมัติ"
            : item.status === "disapproved"
            ? "ไม่อนุมัติ"
            : "รอดำเนินการ",
        nameApproved: item.nameApproved,
        departmentApproved: item.departmentApproved,
        companyApproved: item.companyApproved,
      };
    });
    setCateringFormat(format);
  };

  return (
    <Container maxWidth="xl">
      <Root>
        <div className="page">
          <Grid container spacing={0}>
            <GridHeaderTitle item xs={12} sm={12} md={8} lg={8}>
              <Typography fontSize={32}>รายการร้องขอจัดเลี้ยง</Typography>
            </GridHeaderTitle>
            <GridHeaderButton item xs={12} sm={12} md={4} lg={4}>
              <CreateExcel
                name="Catering_Requested"
                headerValues={head_Catering}
                data={cateringFormat}
              />
            </GridHeaderButton>

            <Grid
              item
              container
              spacing={3}
              xs={12}
              sx={(theme) => ({
                display: "flex",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                marginTop: "0",
                marginLeft: "0",
                marginBottom: "20px",
                padding: "0 25px 25px 0",
                borderRadius: "15px",
              })}
            >
              <Grid item xs={12} md={6} lg={6}>
                <Stack>
                  <Text>ชื่อบริษัท</Text>

                  {userProfile && (
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        placeholder="เลือกบริษัท"
                        id="company"
                        defaultValue={company}
                        onChange={(e) => setCompany(e.target.value)}
                        value={company}
                      >
                        <MenuItem
                          key={userProfile.idCompany}
                          value={userProfile.companyName}
                        >
                          {userProfile.companyName}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={3} lg={3}>
                <Stack>
                  <Text>วันที่จัดเลี้ยง</Text>
                  <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                    <Controller
                      name="date"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <MobileDatePicker
                          {...field}
                          // clearable
                          inputFormat="DD MMMM YYYY"
                          value={isStartDate}
                          componentsProps={{
                            actionBar: {
                              actions: ["clear", "cancel", "accept"],
                            },
                          }}
                          onChange={(date) => setStartDate(date)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              placeholder="วัน เดือน ปี"
                              InputProps={{
                                style: {
                                  borderRadius: "5px",
                                },
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Stack>
                  <Text>สถานะ</Text>
                  <TextField
                    inputProps={{ ...register("status") }}
                    select
                    defaultValue={false}
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "5px",
                      },
                    }}
                  >
                    <MenuItem value={false}>ทั้งหมด</MenuItem>
                    <MenuItem value="pending">รออนุมัติ</MenuItem>
                    <MenuItem value="approved">อนุมัติแล้ว</MenuItem>
                    <MenuItem value="disapproved">ไม่อนุมัติ</MenuItem>
                  </TextField>
                </Stack>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  style={{ marginTop: "10px" }}
                >
                  <ButtonBlue
                    variant="contained"
                    onClick={onClickSearch}
                    style={{
                      maxWidth: "200px",
                      maxHeight: "35px",
                      borderRadius: "15px",
                    }}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </ButtonBlue>
                </Stack>
              </Grid>
            </Grid>
            <Grid item container spacing={3} xs={12}>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Paper
                    sx={{
                      overflow: "hidden",
                      border: "1px solid #EEEEEE",
                      backgroundColor: "#FFFFFF",
                      marginBottom: "20px",
                      padding: "25px 30px 25px 30px",
                      borderRadius: "15px",
                      textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                    }}
                  >
                    <img
                      src={UnicornLoading}
                      alt="Loading..."
                      style={{ width: "300px" }}
                    />
                    {/* ปรับขนาดของภาพตามที่ต้องการ */}
                  </Paper>
                </div>
              ) : (
                <>
                  {caterings.length > 0 ? (
                    caterings
                      .toReversed()
                      .map((catering, index) => (
                        <CateringRequestedCard
                          key={`catering_${catering.idCateringRequest}`}
                          data={catering}
                          onChangeStatusHandler={changeStatus}
                          index={index}
                          onClickCard={onClickCard}
                        />
                      ))
                  ) : (
                    <Grid item xs={12} lg={12} style={{ marginTop: "30px" }}>
                      <Typography className="TextNoBooking">
                        ไม่มีรายการ
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </Root>
    </Container>
  );
};

export default RequestedList;
