import React, { useState, useEffect, Fragment } from "react";
import { Controller } from "react-hook-form";

import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { useMediaQuery } from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import GoogleMap from "../../../../../shared/googleWithAutoComplete/GoogleMap";

const useStyles = makeStyles((theme) => ({
  FormControl: {
    width: "100%",
    paddingLeft: "10px",
    margin: "15px 0px",
  },
  MarginTop: {
    marginTop: "0",
  },
  PaddingRight: {
    paddingRight: "0px !important",
  },
  map: {
    width: "100%",
    height: "300px",
  },
}));

const FormFightCarPool = (props) => {
  const classes = useStyles();
  const [typeOfFlight, setTypeOfFlight] = useState(1);
  const listFlight = [
    {
      nameOfFlight: "ขาไป",
      nameOfParamInRegister: "Departure",
    },
    {
      nameOfFlight: "ขากลับ",
      nameOfParamInRegister: "Return",
    },
  ];
  const [dataFrom, setDataFrom] = useState(props.fromPlace);
  const [dataTo, setDataTo] = useState(props.toPlace);
  const [dataFromReturn, setDataFromReturn] = useState(
    props.fromPlaceReturn ? props.fromPlaceReturn : ""
  );
  const [dataToReturn, setDataToReturn] = useState(
    props.toPlaceReturn ? props.toPlaceReturn : ""
  );
  const [isStartDate, setIsStartDate] = useState(props.departureDate);
  const [isEndDate, setIsEndDate] = useState(
    props.returnDate ? props.returnDate : props.departureDate
  );

  const MobileResponsive = useMediaQuery("(max-width:600px)");

  const onCheckReturn = (index) => {
    if (
      listFlight[index].nameOfParamInRegister === "Return" &&
      props.getValues("numberOfPassenger")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onChangePassenger = (index, e) => {
    if (!onCheckReturn(index)) {
      if (
        props.getValues("numberOfPassengerReturn") === "" ||
        props.getValues("numberOfPassengerReturn") >
          props.getValues("numberOfPassenger") ||
        props.getValues("numberOfPassengerReturn") === undefined
      ) {
        props.setValue("numberOfPassengerReturn", e.target.value);
      }
    }
  };

  const getDataFromGoogleMapFromPlace = (data) => {
    setDataFrom(data);
    props.setValue("fromPlace", data);
    setDataToReturn(data);
    props.setValue("toPlaceReturn", data);
  };
  const getDataFromGoogleMapToplace = (data) => {
    setDataTo(data);
    props.setValue("toPlace", data);
    setDataFromReturn(data);
    props.setValue("fromPlaceReturn", data);
  };

  const getDataFromGoogleMapFromReturnPlace = (data) => {
    setDataFromReturn(data);
    props.setValue("fromPlaceReturn", data);
  };
  const getDataFromGoogleMapToReturnplace = (data) => {
    setDataToReturn(data);
    props.setValue("toPlaceReturn", data);
  };

  useEffect(() => {
    props.watch("flight") === "twoWay"
      ? setTypeOfFlight(2)
      : setTypeOfFlight(1);
  }, [props.watch("flight")]);

  return (
    <>
      <FormControl className={classes.FormControl}>
        <Controller
          rules={{ required: true }}
          control={props.control}
          defaultValue="oneWay"
          name="flight"
          render={(propsInController) => (
            <RadioGroup
              value={propsInController.field.value}
              onChange={(flight) => {
                propsInController.field.onChange(flight.target.value);
              }}
            >
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={2}
                  className={classes.PaddingRight}
                >
                  <FormControlLabel
                    value="oneWay"
                    control={
                      <Radio
                        checked={propsInController.field.value === "oneWay"}
                      />
                    }
                    label="เที่ยวเดียว"
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <FormControlLabel
                    value="twoWay"
                    control={
                      <Radio
                        checked={propsInController.field.value === "twoWay"}
                      />
                    }
                    label="ไป-กลับ"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          )}
        />
      </FormControl>
      <Grid
        container
        spacing={3}
        sx={(theme) => ({
          display: "flex",
          // justifyContent: "space-between",
          border: "1px solid #EEEEEE",
          backgroundColor: "#FBFCFC",
          marginTop: "20px",
          marginBottom: "20px",
          padding: "0 25px 25px 0",
          boxShadow:
            "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
          borderRadius: "15px",
        })}
      >
        {[...new Array(typeOfFlight)].map((value, index) => {
          return (
            <Fragment key={listFlight[index].nameOfParamInRegister}>
              {props.watch("flight") === "twoWay" && (
                <Grid item xs={12}>
                  <Typography>{listFlight[index].nameOfFlight}</Typography>
                  <Divider />
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <InputLabel style={{ marginBottom: "10px" }}>
                  {"จุดรับ"}
                </InputLabel>
                {index === 0 ? (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Controller
                        render={(propsInController) => (
                          <TextField
                            placeholder="ระบุต้นทาง"
                            label="ต้นทาง"
                            variant="outlined"
                            error={props.errors.fromPlace ? true : false}
                            helperText={
                              props.errors.fromPlace
                                ? "กรุณาเลือกจุดต้นทาง"
                                : false
                            }
                            fullWidth
                            multiline
                            disabled
                            value={propsInController.field.value}
                            onChange={propsInController.field.onChange}
                            onBlur={(e) => {
                              if (
                                props.getValues("toPlaceReturn") === "" ||
                                props.getValues("toPlaceReturn") === undefined
                              ) {
                                props.setValue("toPlaceReturn", e.target.value);
                              }
                            }}
                            InputProps={{
                              style: {
                                borderRadius: "15px",
                              },
                            }}
                          />
                        )}
                        name="fromPlace"
                        control={props.control}
                        rules={{ required: true }}
                        defaultValue=""
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ height: "380px" }}>
                      <GoogleMap
                        name="fromPlace"
                        inputProps={{
                          ...props.setValue("fromPlace", dataFrom),
                        }}
                        value={dataFrom}
                        handleGetDataFromGoogleMap={
                          getDataFromGoogleMapFromPlace
                        }
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Controller
                        render={(propsInController) => (
                          <TextField
                            placeholder="ระบุต้นทาง"
                            label="ต้นทาง"
                            error={props.errors.fromPlaceReturn}
                            helperText={
                              props.errors.fromPlaceReturn && "กรุณากรอกข้อมูล"
                            }
                            fullWidth
                            multiline
                            disabled
                            value={propsInController.field.value}
                            onChange={propsInController.field.onChange}
                            InputProps={{
                              style: {
                                borderRadius: "15px",
                              },
                            }}
                          />
                        )}
                        name="fromPlaceReturn"
                        control={props.control}
                        defaultValue=""
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ height: "380px" }}>
                      <GoogleMap
                        name="fromPlaceReturn"
                        inputProps={{
                          ...props.setValue("fromPlaceReturn", dataFromReturn),
                        }}
                        value={dataFromReturn}
                        handleGetDataFromGoogleMap={
                          getDataFromGoogleMapFromReturnPlace
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel style={{ marginBottom: "10px" }}>
                  {"จุดส่ง"}
                </InputLabel>

                {index === 0 ? (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Controller
                        render={(propsInController) => (
                          <TextField
                            placeholder="ระบุปลายทาง"
                            label="ปลายทาง"
                            variant="outlined"
                            multiline
                            disabled
                            error={props.errors.toPlace ? true : false}
                            helperText={
                              props.errors.toPlace
                                ? "กรุณาเลือกจุดปลายทาง"
                                : false
                            }
                            fullWidth
                            value={propsInController.field.value}
                            onChange={propsInController.field.onChange}
                            onBlur={(e) => {
                              if (
                                props.getValues("fromPlaceReturn") === "" ||
                                props.getValues("fromPlaceReturn") === undefined
                              ) {
                                props.setValue(
                                  "fromPlaceReturn",
                                  e.target.value
                                );
                              }
                            }}
                            InputProps={{
                              style: {
                                borderRadius: "15px",
                              },
                            }}
                          />
                        )}
                        name="toPlace"
                        rules={{ required: true }}
                        control={props.control}
                        defaultValue=""
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ height: "380px" }}>
                      <GoogleMap
                        name="toPlace"
                        inputProps={{
                          ...props.setValue("toPlace", dataTo),
                        }}
                        value={dataTo}
                        handleGetDataFromGoogleMap={getDataFromGoogleMapToplace}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Controller
                        render={(propsInController) => (
                          <TextField
                            placeholder="ระบุปลายทาง"
                            label="ปลายทาง"
                            fullWidth
                            multiline
                            error={props.errors.toPlaceReturn}
                            helperText={
                              props.errors.toPlaceReturn && "กรุณากรอกข้อมูล"
                            }
                            disabled
                            value={propsInController.field.value}
                            onChange={propsInController.field.onChange}
                            InputProps={{
                              style: {
                                borderRadius: "15px",
                              },
                            }}
                          />
                        )}
                        name="toPlaceReturn"
                        control={props.control}
                        rules={{ required: true }}
                        defaultValue=""
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ height: "380px" }}>
                      <GoogleMap
                        name="toPlaceReturn"
                        inputProps={{
                          ...props.setValue("toPlaceReturn", dataToReturn),
                        }}
                        value={dataToReturn}
                        handleGetDataFromGoogleMap={
                          getDataFromGoogleMapToReturnplace
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>{"จำนวนผู้โดยสาร"}</InputLabel>
                  {index === 0 ? (
                    <Controller
                      render={(propsInController) => (
                        <Select
                          label="จำนวนผู้โดยสาร"
                          value={propsInController.field.value}
                          onChange={(e) => {
                            propsInController.field.onChange(e);
                            onChangePassenger(index, e);
                          }}
                          style={{ borderRadius: "15px" }}
                        >
                          {[...new Array(10)].map((value, index) => {
                            return (
                              <MenuItem
                                key={value + "" + index}
                                value={index + 1}
                              >
                                {index + 1}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                      name={`numberOfPassenger`}
                      control={props.control}
                      defaultValue="1"
                    />
                  ) : (
                    props.watch("numberOfPassenger") && (
                      <Controller
                        render={(propsInController) => (
                          <Select
                            label="จำนวนผู้โดยสาร"
                            error={props.errors.numberOfPassengerReturn}
                            helperText={
                              props.errors.numberOfPassengerReturn &&
                              "กรุณากรอกข้อมูล"
                            }
                            value={propsInController.field.value}
                            onChange={(e) => {
                              propsInController.field.onChange(e);
                            }}
                            style={{ borderRadius: "15px" }}
                          >
                            {[
                              ...new Array(
                                props.getValues("numberOfPassenger")
                              ),
                            ].map((value, index) => {
                              return (
                                <MenuItem
                                  key={value + "" + index}
                                  value={index + 1}
                                >
                                  {index + 1}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                        name={`numberOfPassengerReturn`}
                        control={props.control}
                        rules={{ required: true }}
                        defaultValue=""
                      />
                    )
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    render={(propsInController) => (
                      <DatePicker
                        label="วันที่เดินทาง"
                        minDate={index === 0 ? new Date() : isStartDate}
                        value={index === 0 ? isStartDate : isEndDate}
                        onChange={(date) => {
                          if (index === 0) {
                            if (date > isEndDate) {
                              setIsEndDate(date);
                            }
                            setIsStartDate(date);
                          } else {
                            setIsEndDate(date);
                          }
                          propsInController.field.onChange(date);
                        }}
                        inputFormat="dd MMMM yyyy"
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              ".MuiInputBase-root": { borderRadius: "15px" },
                            }}
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    )}
                    name={`${listFlight[
                      index
                    ].nameOfParamInRegister.toLowerCase()}Date`}
                    defaultValue={new Date()}
                    control={props.control}
                  />
                </LocalizationProvider>
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </>
  );
};

export default FormFightCarPool;
