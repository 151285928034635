import {
  BETWEEN_SITE_CAR_BOOKINGS_FETCHING,
  BETWEEN_SITE_CAR_BOOKINGS_FAILED,
  BETWEEN_SITE_CAR_BOOKINGS_SUCCESS,
} from "./types";

import betweenSiteCarService from "../services/betweenSiteCar.service";

export const getBetweenSiteCarBookingByIdDriver =
  (idDriver) => async (dispatch) => {
    try {
      dispatch({
        type: BETWEEN_SITE_CAR_BOOKINGS_FETCHING,
      });
      const res = await betweenSiteCarService.getBetweenSiteCarByIdDriver(
        idDriver
      );
      if (res) {
        dispatch({
          type: BETWEEN_SITE_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: BETWEEN_SITE_CAR_BOOKINGS_FAILED,
      });
    }
  };

export const getBetweenSiteCarByFilterByIdDriver =
  (filter) => async (dispatch) => {
    try {
      dispatch({
        type: BETWEEN_SITE_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await betweenSiteCarService.getBetweenSiteCarByFilterByIdDriver(filter);
      if (res) {
        dispatch({
          type: BETWEEN_SITE_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: BETWEEN_SITE_CAR_BOOKINGS_FAILED,
      });
    }
  };

export const getBetweenSiteWithRouteDate = (date) => async (dispatch) => {
  try {
    dispatch({
      type: BETWEEN_SITE_CAR_BOOKINGS_FETCHING,
    });
    const res = await betweenSiteCarService.getBetweenSiteWithRouteDate(date);
    if (res) {
      dispatch({
        type: BETWEEN_SITE_CAR_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: BETWEEN_SITE_CAR_BOOKINGS_FAILED,
    });
  }
};

export const getBetweenSiteWithRouteDateAndEndDate =
  (date) => async (dispatch) => {
    try {
      dispatch({
        type: BETWEEN_SITE_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await betweenSiteCarService.getBetweenSiteWithRouteDateAndEndDate(date);
      if (res) {
        dispatch({
          type: BETWEEN_SITE_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: BETWEEN_SITE_CAR_BOOKINGS_FAILED,
      });
    }
  };
