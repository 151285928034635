import React, { Fragment } from "react";
import { Controller } from "react-hook-form";

import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import FormEachEmployee from "../../../../../shared/formBooking/formEachEmployee";
import FormLocationTimePurpose from "./formLocationTimePurpose";

const useStyles = makeStyles((theme) => ({
  GridTitleOfForm: {
    marginTop: "20px",
  },
  FontTitle: {
    fontSize: "20px",
  },
  GridTextStart: {
    display: "flex",
    alignItems: "center",
  },
}));

const OrderPassengerAndCheckBox = styled("div")({
  display: "flex",
  alignItems: "center",
});

const FormDetailFightCarPool = (props) => {
  const classes = useStyles();

  const onClickCheckBox = () => {
    if (props.getValues("statusUsingAllPassenger")) {
      [...new Array(props.getValues("numberOfPassenger") - 1)].map(
        (value, index) => {
          props.setValue(
            `listPassenger.person${index + 2}.toPlace`,
            props.getValues(`listPassenger.person1.toPlace`)
          );
          props.setValue(
            `listPassenger.person${index + 2}.roundTime`,
            props.getValues(`listPassenger.person1.roundTime`)
          );
          props.setValue(
            `listPassenger.person${index + 2}.endTime`,
            props.getValues(`listPassenger.person1.endTime`)
          );
          props.setValue(
            `listPassenger.person${index + 2}.purpose`,
            props.getValues(`listPassenger.person1.purpose`)
          );
          props.setValue(
            `listPassenger.person${index + 2}.fromPlaceReturn`,
            props.getValues(`listPassenger.person1.fromPlaceReturn`)
          );
        }
      );
    }
  };

  return (
    <>
      {[...new Array(props.getValues("numberOfPassenger"))].map(
        (value, index) => {
          return (
            <Fragment key={value + "" + index}>
              <Grid
                container
                spacing={3}
                sx={(theme) => ({
                  display: "flex",
                  // justifyContent: "space-between",
                  border: "1px solid #EEEEEE",
                  backgroundColor: "#FBFCFC",
                  marginTop: "20px",
                  marginBottom: "20px",
                  padding: "0 25px 25px 0",
                  boxShadow:
                    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                  borderRadius: "15px",
                })}
              >
                <Grid
                  item
                  xs={12}
                  className={`${index !== 0 && classes.GridTitleOfForm}`}
                >
                  <OrderPassengerAndCheckBox>
                    <Typography className={classes.FontTitle}>
                      คนที่ {index + 1}
                    </Typography>
                    {index === 0 && (
                      <Controller
                        render={(propsInController) => (
                          <FormControlLabel
                            style={{ marginLeft: "10px" }}
                            control={
                              <Checkbox
                                checked={propsInController.field.value}
                                onChange={(e) => {
                                  propsInController.field.onChange(e);
                                  onClickCheckBox();
                                }}
                              />
                            }
                            label="ไปสถานที่เดียวกันทุกคน"
                          />
                        )}
                        name="statusUsingAllPassenger"
                        control={props.control}
                        defaultValue={false}
                      />
                    )}
                  </OrderPassengerAndCheckBox>
                  <Divider />
                </Grid>
                <FormEachEmployee
                  key={value + "" + index}
                  register={props.register}
                  index={index + 1}
                  control={props.control}
                  watch={props.watch}
                  setValue={props.setValue}
                  errors={props.errors}
                />
                {props.getValues("flight") === "twoWay" && (
                  <Grid container item xs={12}>
                    <Grid item xs={12} className={classes.GridTextStart}>
                      {props.getValues("flight") === "twoWay" && (
                        <Typography>ขาไป</Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                )}
                <FormLocationTimePurpose
                  index={index + 1}
                  control={props.control}
                  register={props.register}
                  getValues={props.getValues}
                  setValue={props.setValue}
                  watch={props.watch}
                  nameParam="Departure"
                  errors={props.errors}
                  stateInputRef={props.stateInputRef}
                  setStateInputRef={props.setStateInputRef}
                  fromPlacePassenger={props.fromPlacePassenger[index]}
                  toPlacePassenger={props.toPlacePassenger[index]}
                  fromPlaceReturnPassenger={
                    props.fromPlaceReturnPassenger[index]
                  }
                  toPlaceReturnPassenger={props.toPlaceReturnPassenger[index]}
                />
                {props.getValues("flight") === "twoWay" && (
                  <>
                    <Grid item xs={12}>
                      <Typography>ขากลับ</Typography>
                      <Divider />
                    </Grid>
                    <FormLocationTimePurpose
                      index={index + 1}
                      control={props.control}
                      register={props.register}
                      setValue={props.setValue}
                      getValues={props.getValues}
                      watch={props.watch}
                      nameParam="Return"
                      stateInputRef={props.stateInputRef}
                      setStateInputRef={props.setStateInputRef}
                      fromPlacePassenger={props.fromPlacePassenger[index]}
                      toPlacePassenger={props.toPlacePassenger[index]}
                      fromPlaceReturnPassenger={
                        props.fromPlaceReturnPassenger[index]
                      }
                      toPlaceReturnPassenger={
                        props.toPlaceReturnPassenger[index]
                      }
                    />
                  </>
                )}
              </Grid>
            </Fragment>
          );
        }
      )}
    </>
  );
};

export default FormDetailFightCarPool;
