import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import UnicornLoading from "../../assets/loading.gif";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import {
  getAllCrossAreaCarBookings,
  getCrossAreaCarBookingByIdApprovedUserForManager,
} from "../../../../actions/crossAreaCarBooking";
import {
  getAllInAreaCarBookings,
  getInAreaCarBookingByIdApprovedUserForManager,
} from "../../../../actions/inAreaCarBooking";
import CrossAreaCarBookingService from "../../../../services/crossAreaCarBooking.service";
import InAreaCarBookingService from "../../../../services/inAreaCarBooking.service";

import CrossAreaCarPoolBookingService from "../../../../services/crossAreaCarPoolBooking.service";
import DeliveryCarBookingService from "../../../../services/deliveryCarBooking.service";

import DialogOfConfirmationOfApprovalOfAllBooking from "./DialogOfConfirmationOfApprovalOfAllBooking";

import Paper from "@mui/material/Paper";
import CrossArea from "./CrossArea";
import CrossAreaPool from "./CrossAreaPool";
import InArea from "./InArea";
import DeliveryItem from "./DeliveryItem";
import { getAllCrossAreaCarPoolBookings } from "../../../../actions/crossAreaCarPoolBooking";
import { getAllDeliveryCarBookings } from "../../../../actions/deliveryCarBooking";

// import Card from "../../shared/Card";

require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    height: "90%",
    width: "80%",
    // display: "grid",
    // flexDirection: "row",
    marginTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",

    ["@media only screen and (max-width: 375px)"]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  Title: {
    textAlign: "center",
    fontSize: "30px",
    marginBottom: "5px",
  },
  Card: {
    marginBottom: "20px",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    // boxShadow: theme.shadows[3],
    transition: "transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    boxShadow: "0 2px 4px 0 rgb(35 31 32 / 20%), 0 0 1px 0 rgb(35 31 32 / 10%)",

    borderRadius: "15px",
    cursor: "pointer",
    border: "1px solid #EEEEEE",
    "&:hover": {
      transform: "translateY(-8px)",
      boxShadow:
        "rgb(145 158 171 / 60%) 0px 0px 2px 0px, rgb(145 158 171 / 60%) 0px 20px 40px -4px",
    },
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "space-between",
    },
    paddingBottom: "10px",
  },
  Avatar: {
    width: "100px",
    height: "100px",
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
    },
  },
  Name: {
    color: "#185ADB",
    fontSize: "20px",
    marginBottom: "10px",
  },
  TextAndIcon: {
    display: "flex",
    marginBottom: "5px",
  },
  Icon: {
    marginRight: "10px",
    color: "#293A80",
  },
  IconButton: {
    marginRight: "5px",
  },
  ButtonRight: {
    marginRight: "10px",
  },
  Button: {
    height: "50px",
    width: "130px",
    fontSize: "20px",
    borderRadius: "25px",
  },
  ApproveButton: {
    color: "white",
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  },
  DisapproveButton: {
    color: "white",
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "darkred",
    },
  },
  DetailButton: {
    color: "white",
    backgroundColor: "#979797",
    "&:hover": {
      backgroundColor: "#757575",
    },
  },
  AvaterAndName: {
    display: "flex",
  },
  NameAndType: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  GirdAvatarAndName: {
    paddingRight: "0 !important",
  },
  NavButton: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ["@media only screen and (max-width: 1100px)"]: {
      marginTop: "5px",
      height: "50px",
    },
  },
  NavButtonApprove: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight: "100px",
    color: "green",
    ["@media only screen and (max-width: 1100px)"]: {
      marginTop: "5px",
      height: "50px",
    },
  },
  NavButtonApproveReject: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginRight: "100px",
    justifyContent: "flex-end",
    color: "red",
    ["@media only screen and (max-width: 1100px)"]: {
      marginTop: "5px",
      height: "50px",
    },
  },
  GridContainer: {
    width: "70%",
    ["@media only screen and (max-width: 1100px)"]: {
      width: "100%",
    },
  },
  GirdPurposeAndDateAndTime: {
    paddingLeft: "15px",
  },
  Text: {
    color: "#293A80",
  },
  CrossArea: {
    color: "#C400C6",
  },
  InArea: {
    color: "#0A81AB",
  },
  DateAndTime: {
    ["@media (min-width: 700px) and (max-width: 780px)"]: {
      display: "flex",
    },
  },
  Date: {
    marginRight: "40px",
  },
  NavButtonTop: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "20px",
  },
  ButtonAllBooking: {
    height: "56px",
    fontSize: "20px",
    color: "white",
    marginBottom: "10px",
    paddingRight: "15px",
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  },
  IconAllBooking: {
    marginRight: "10px",
    marginLeft: "10px",
  },
  AllContent: {
    width: "100%",
    height: "100%",
  },
  CardContent: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    border: "3px solid rgba(90, 114, 123, 0.11)",
    boxShadow: "rgba(90, 114, 123, 0.11) 0px 7px 30px 0px",
  },
  Tabbar: {
    width: "auto",
    display: "flex",
    justifyContent: "space-between",
    padding: "25px",
  },
  TableContent: {
    padding: "25px",
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    ["@media (min-width: 700px) and (max-width: 780px)"]: {
      display: "block",
    },
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const ViewButton = styled(Button)(({ theme }) => ({
  width: "180px",
  minWidth: "180px",
  height: "50px",
  fontSize: "15px",
  borderRadius: "10px 10px 10px 10px",
  zIndex: "10",
  backgroundColor: theme.palette.primary.main,
  border: "1px solid #00bb77",
  borderBottom: "0",
  marginBottom: "-2px",
  color: "white",
  fontWeight: "bold",
  "&:hover": {
    borderBottom: "0px",
    backgroundColor: theme.palette.primary.dark,
  },
}));

const ApprovePage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showHistory, setHistory] = useState(0);
  const { user: authUser } = useSelector((state) => state.auth);
  // const { result: Bookings } = useSelector((state) => state.bookings);
  const { isFetching: isFetchingCrossAreaBookings, result: crossAreaBookings } =
    useSelector((state) => state.crossAreaCar);
  const {
    isFetching: isFetchingCrossAreaPoolBookings,
    result: crossAreaPoolBookings,
  } = useSelector((state) => state.crossAreaCarPool);
  const { isFetching: isFetchingInAreaBookings, result: inAreaBookings } =
    useSelector((state) => state.inAreaCar);
  const { isFetching: isFetchingDeliveryBookings, result: deliveryBookings } =
    useSelector((state) => state.deliveryCar);

  const [openDialog, setOpenDialog] = useState(false);
  const [typeOfBooking, setTypeOfBooking] = useState("CrossArea");

  const handleChange = (event, newValue) => {
    setTypeOfBooking(newValue);
  };

  useEffect(() => {
    dispatch(getAllCrossAreaCarBookings());
    dispatch(getAllCrossAreaCarPoolBookings());
    dispatch(getAllInAreaCarBookings());
    dispatch(getAllDeliveryCarBookings());
  }, []);

  const convertTime = (lawStartDate, lawEndDate) => {
    return `${dayjs(lawStartDate).format("HH:mm")} - ${dayjs(lawEndDate).format(
      "HH:mm"
    )}`;
  };

  const convertDate = (lawstartDate, lawendDate) => {
    if (lawendDate) {
      return `${dayjs(lawstartDate).format("DD")} - ${dayjs(lawendDate).format(
        "DD MMMM YYYY"
      )}`;
    } else {
      return dayjs(lawstartDate).format("DD MMMM YYYY");
    }
  };

  const watchDetail = (idBooking) => {
    props.history.push("/manager/detail_booking", {
      typeBooking: typeOfBooking,
      idBooking: idBooking,
    });
  };

  const onClickApprovalOfAllBooking = (typeOfBooking) => {
    console.log(typeOfBooking);
    setOpenDialog(true);
  };

  const onClickCancellationOfApprovalOfAllBooking = () => {
    setOpenDialog(false);
  };

  const onClickApprove = (idBooking, status) => {
    if (typeOfBooking === "CrossArea") {
      CrossAreaCarBookingService.postApprovedCrossAreaCarBooking({
        typeOfBooking: typeOfBooking,
        idBooking: idBooking,
        status: status,
      })
        .then((res) => {
          if (res.data) {
            dispatch(getAllCrossAreaCarBookings());
            enqueueSnackbar("Add Approval Status Success", {
              variant: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar("Add Approval Status Failed", { variant: "error" });
        });
      // return "การจองรถข้ามพื้นที่พิเศษ";
    } else if (typeOfBooking === "InArea") {
      InAreaCarBookingService.postApprovedInAreaCarBooking({
        typeOfBooking: typeOfBooking,
        idBooking: idBooking,
        status: status,
      })
        .then((res) => {
          if (res.data) {
            dispatch(getAllInAreaCarBookings());
            enqueueSnackbar("Add Approval Status Success", {
              variant: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar("Add Approval Status Failed", { variant: "error" });
        });
      // return "การจองรถในพื้นที่";
    } else if (typeOfBooking === "CrossAreaPool") {
      CrossAreaCarPoolBookingService.postApprovedlCrossAreaCarPoolBooking({
        typeOfBooking: typeOfBooking,
        idBooking: idBooking,
        status: status,
      })
        .then((res) => {
          if (res.data) {
            dispatch(getAllCrossAreaCarPoolBookings());
            enqueueSnackbar("Add Approval Status Success", {
              variant: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar("Add Approval Status Failed", { variant: "error" });
        });
      // return "จองสถานที่";
    } else if (typeOfBooking === "DeliveryCar") {
      DeliveryCarBookingService.postApprovedlDeliveryCarBooking({
        typeOfBooking: typeOfBooking,
        idBooking: idBooking,
        status: status,
      })
        .then((res) => {
          if (res.data) {
            dispatch(getAllDeliveryCarBookings());
            enqueueSnackbar("Add Approval Status Success", {
              variant: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar("Add Approval Status Failed", { variant: "error" });
        });
      // return "จองสถานที่";
    }
  };

  const onClickHistory = () => {
    setHistory(1);
  };
  const onClickHistoryClose = () => {
    setHistory(0);
  };

  return (
    <div className={`page ${classes.DivCoverReturn}`}>
      <div className={classes.Header}>
        <Typography className={classes.Title}>รายการรออนุมัติ</Typography>
        {showHistory === 0 && (
          <ViewButton onClick={onClickHistory}>
            <img
              style={{
                weight: "26px",
                height: "26px",
                marginRight: "3px",
                borderRadius: "5px",
              }}
              src={"/assets/Image/Icon/clock_white.png"}
              alt="Logo"
            />
            ประวัติการทำรายการ
          </ViewButton>
        )}
        {showHistory === 1 && (
          <ViewButton onClick={onClickHistoryClose}>
            <img
              style={{
                weight: "26px",
                height: "26px",
                marginRight: "3px",
              }}
              src={"/assets/Image/Icon/list.png"}
              alt="Logo"
            />
            สถานะรายการ
          </ViewButton>
        )}
      </div>
      <div className={`${classes.NavButtonTop}`}>
        <FormControl style={{ width: "200px", marginBottom: "10px" }}>
          <InputLabel>ประเภทการจอง</InputLabel>
          <Select
            label="ประเภทการจอง"
            value={typeOfBooking}
            onChange={(e) => setTypeOfBooking(e.target.value)}
          >
            <MenuItem value={"CrossArea"}>จองรถข้ามพื้นที่เฉพาะ</MenuItem>
            <MenuItem value={"InArea"}>จองรถในพื้นที่</MenuItem>
            <MenuItem value={"CrossAreaPool"}>จองรถข้ามพื้นที่Pool</MenuItem>
            <MenuItem value={"DeliveryCar"}>จองรถส่งของ</MenuItem>
          </Select>
        </FormControl>
        <Button
          className={`${classes.ButtonAllBooking}`}
          onClick={() => onClickApprovalOfAllBooking(typeOfBooking)}
        >
          <DoneAllIcon className={`${classes.IconAllBooking}`} />
          อนุมัติทั้งหมด
        </Button>
      </div>
      {isFetchingCrossAreaBookings ||
      isFetchingInAreaBookings ||
      isFetchingCrossAreaPoolBookings ||
      isFetchingDeliveryBookings ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Paper
            sx={{
              overflow: "hidden",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FFFFFF",
              marginBottom: "20px",
              padding: "25px 30px 25px 30px",
              borderRadius: "15px",
              textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
            }}
          >
            <img
              src={UnicornLoading}
              alt="Loading..."
              style={{ width: "300px" }}
            />
            {/* ปรับขนาดของภาพตามที่ต้องการ */}
          </Paper>
        </div>
      ) : (
        <>
          {inAreaBookings &&
            typeOfBooking === "InArea" &&
            showHistory === 1 &&
            inAreaBookings
              .filter((booking) => booking.statusApproved != null)
              .map((booking) => (
                <InArea
                  booking={booking}
                  typeBooking={typeOfBooking}
                  convertTime={convertTime}
                  convertDate={convertDate}
                  watchDetail={watchDetail}
                  onClickApprove={onClickApprove}
                />
              ))}
          {inAreaBookings &&
            typeOfBooking === "InArea" &&
            showHistory === 0 &&
            inAreaBookings
              .filter((booking) => booking.statusApproved == null)
              .map((booking) => (
                <InArea
                  booking={booking}
                  typeBooking={typeOfBooking}
                  convertTime={convertTime}
                  convertDate={convertDate}
                  watchDetail={watchDetail}
                  onClickApprove={onClickApprove}
                />
              ))}
          {crossAreaBookings &&
            typeOfBooking === "CrossArea" &&
            showHistory === 1 &&
            crossAreaBookings
              .filter((booking) => booking.statusApproved != null)
              .map((booking) => (
                <CrossArea
                  booking={booking}
                  typeBooking={typeOfBooking}
                  convertTime={convertTime}
                  convertDate={convertDate}
                  watchDetail={watchDetail}
                  onClickApprove={onClickApprove}
                />
              ))}
          {crossAreaBookings &&
            typeOfBooking === "CrossArea" &&
            showHistory === 0 &&
            crossAreaBookings
              .filter((booking) => booking.statusApproved == null)
              .map((booking) => (
                <CrossArea
                  booking={booking}
                  typeBooking={typeOfBooking}
                  convertTime={convertTime}
                  convertDate={convertDate}
                  watchDetail={watchDetail}
                  onClickApprove={onClickApprove}
                />
              ))}
          {crossAreaPoolBookings &&
            typeOfBooking === "CrossAreaPool" &&
            showHistory === 1 &&
            crossAreaPoolBookings
              .filter((booking) => booking.statusApproved != null)
              .map((booking) => (
                <CrossAreaPool
                  booking={booking}
                  typeBooking={typeOfBooking}
                  convertTime={convertTime}
                  convertDate={convertDate}
                  watchDetail={watchDetail}
                  onClickApprove={onClickApprove}
                />
              ))}
          {crossAreaPoolBookings &&
            typeOfBooking === "CrossAreaPool" &&
            showHistory === 0 &&
            crossAreaPoolBookings
              .filter((booking) => booking.statusApproved == null)
              .map((booking) => (
                <CrossAreaPool
                  booking={booking}
                  typeBooking={typeOfBooking}
                  convertTime={convertTime}
                  convertDate={convertDate}
                  watchDetail={watchDetail}
                  onClickApprove={onClickApprove}
                />
              ))}
          {deliveryBookings &&
            typeOfBooking === "DeliveryCar" &&
            showHistory === 1 &&
            deliveryBookings
              .filter((booking) => booking.statusApproved != null)
              .map((booking) => (
                <DeliveryItem
                  booking={booking}
                  typeBooking={typeOfBooking}
                  convertTime={convertTime}
                  convertDate={convertDate}
                  watchDetail={watchDetail}
                  onClickApprove={onClickApprove}
                />
              ))}
          {deliveryBookings &&
            typeOfBooking === "DeliveryCar" &&
            showHistory === 0 &&
            deliveryBookings
              .filter((booking) => booking.statusApproved == null)
              .map((booking) => (
                <DeliveryItem
                  booking={booking}
                  typeBooking={typeOfBooking}
                  convertTime={convertTime}
                  convertDate={convertDate}
                  watchDetail={watchDetail}
                  onClickApprove={onClickApprove}
                />
              ))}
        </>
      )}
      <DialogOfConfirmationOfApprovalOfAllBooking
        openDialog={openDialog}
        onCancellation={onClickCancellationOfApprovalOfAllBooking}
      />
    </div>
  );
};

export default ApprovePage;
