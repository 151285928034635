import React from "react";
import ListMenu from "../../../pages/shared/listMenu";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
const ManagerMenu = ({ onClickList }) => {
  const dataListMenu = [
    {
      listItemIcon: <PlaylistAddCheckIcon />,
      listItemText: "Approved",
      listLink: "/manager/approved",
      listKey: "Approved",
    },
    {
      listItemIcon: <FormatListBulletedIcon />,
      listItemText: "รายการขอจัดเลี้ยง",
      listLink: "/manager/catering/requested_list",
      listKey: "managerCateringRequestedList",
    },
    {
      listItemIcon: <FormatListBulletedIcon />,
      listItemText: "รายการซ่อมแซม",
      listLink: "/manager/maintenances",
      listKey: "managerMaintenancesRequestedList",
    },
    {
      listItemIcon: <FormatListBulletedIcon />,
      listItemText: "รายการจองสถานที่",
      listLink: "/manager/meeting_room_booking",
      listKey: "managerMeetingRoomBookingRequestedList",
    },
    {
      listItemIcon: <FormatListBulletedIcon />,
      listItemText: "รายการจองคนขับรถ",
      listLink: "/manager/driver_booking",
      listKey: "managerDriverBookingRequestedList",
    },
    // {
    //   listItemIcon: <DoneAllIcon />,
    //   listItemText: "Approved",
    //   listLink: "/manager/approvedTemporary",
    //   listKey:"ApprovedTemporary",
    // },
    // {
    //   listItemIcon: <DashboardIcon />,
    //   listItemText: "Dashboard",
    //   listLink: "/manager/dashboard",
    //   listKey:"dashboard",
    // },
  ];

  return (
    <ListMenu
      dataListMenu={dataListMenu}
      menuRole={"Manager"}
      onClickList={onClickList}
    />
  );
};

export default ManagerMenu;
