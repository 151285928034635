import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import VehicleBrandAndModelService from "../../../../../services/vehicleBrandAndModel.service";
import employeeService from "../../../../../services/employee.service";
import { getEmployeesByCompany } from "../../../../../actions/employee";
import vehicleService from "../../../../../services/vehicle.service";
import vehicleBrandAndModelService from "../../../../../services/vehicleBrandAndModel.service";

import GoogleMap from "../../../shared/googleWithAutoComplete/GoogleMap";

const useStyles = makeStyles((theme) => ({
  PaddingBottom: {
    paddingBottom: "0 !important",
  },
  map: {
    width: "100%",
    height: "300px",
  },
}));

const generateTimeOptions = (startTime = "-01:30") => {
  const times = [];
  const [startHour, startMinute] = startTime.split(":").map(Number);
  let startMinutes = startHour * 60 + startMinute + 30; // Adding 30 minutes

  for (let i = 0; i < 48; i++) {
    let hour = Math.floor(startMinutes / 60);
    let minute = startMinutes % 60;
    if (hour < 24) {
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      times.push(`${hour}:${minute}`);
      startMinutes += 30;
    }
  }
  return times;
};
const FormDetailFlight = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { result: allEmployees } = useSelector((state) => state.employees);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [listVehicles, setListVehicles] = useState([]);
  const [selectTypeVehicle, setSelectTypeVehicle] = useState(null);
  const [dataFrom, setDataFrom] = useState("");
  const [dataTo, setDataTo] = useState("");
  const [endTimeOptions, setEndTimeOptions] = useState(generateTimeOptions());

  useEffect(() => {
    vehicleBrandAndModelService
      .getAllVehicleBrandsAndModels()
      .then((res) => {
        if (res.data.length > 0) {
          setListVehicles(res.data);
        } else {
          setListVehicles([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (!allEmployees) {
      employeeService.getEmployeesById(currentUser.idUser).then((res) => {
        if (res.data) {
          dispatch(getEmployeesByCompany(res.data[0].idCompany));
        }
      });
    }
  }, []);

  useEffect(() => {
    const newEndTimeOptions = generateTimeOptions(props.getValues("startTime"));
    setEndTimeOptions(newEndTimeOptions);
  }, [props.watch("startTime")]);

  const getDataFromGoogleMapFromPlace = (data) => {
    setDataFrom(data);
    props.setValue("fromPlace", data);
  };
  const getDataFromGoogleMapToplace = (data) => {
    setDataTo(data);
    props.setValue("toPlace", data);
  };

  return (
    <Grid
      container
      spacing={3}
      style={{ marginTop: "10px" }}
      sx={(theme) => ({
        display: "flex",
        // justifyContent: "space-between",
        border: "1px solid #EEEEEE",
        backgroundColor: "#FBFCFC",
        boxShadow:
          "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

        marginBottom: "20px",
        padding: "0 25px 25px 0",

        borderRadius: "15px",
      })}
    >
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>{"ประเภทรถรับส่งของ"}</InputLabel>
          <Controller
            render={(propsInController) => (
              <Select
                label="ประเภทรถรับส่งของ"
                error={props.errors.idTypeVehicle ? true : false}
                helperText={
                  props.errors.idTypeVehicle
                    ? "กรุณาเลือกประเภทรถรับส่งของ"
                    : false
                }
                value={propsInController.field.value}
                onChange={(e) => {
                  props.setValue("idVehicleBrandAndModel", "");
                  props.setValue("imageVehicle", "");
                  props.setValue("costBooking", "");
                  setSelectTypeVehicle(e.target.value);
                  propsInController.field.onChange(e);
                }}
                style={{ borderRadius: "15px" }}
              >
                <MenuItem value={2}>รถเก๋ง</MenuItem>
                <MenuItem value={3}>รถกระบะ</MenuItem>
                <MenuItem value={1}>รถตู้</MenuItem>
              </Select>
            )}
            name="idTypeVehicle"
            control={props.control}
            rules={{ required: true }}
            defaultValue=""
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>{"แบรนด์และรุ่นรถ"}</InputLabel>
          <Controller
            render={(propsInController) => (
              <Select
                label="แบรนด์และรุ่นรถ"
                error={props.errors.idVehicleBrandAndModel ? true : false}
                helperText={
                  props.errors.idVehicleBrandAndModel
                    ? "กรุณาเลือกแบรนด์และรุ่นรถ"
                    : false
                }
                value={propsInController.field.value}
                onChange={(e) => {
                  let Vehicle = listVehicles.find(
                    (vehicle) =>
                      parseInt(vehicle.idVehicleBrandsAndModels) ===
                      parseInt(e.target.value)
                  );
                  console.log(Vehicle, "91");
                  props.setValue("costBooking", Vehicle.costBooking);
                  props.setValue("imageVehicle", Vehicle.imagepath);
                  propsInController.field.onChange(e);
                }}
                style={{ borderRadius: "15px" }}
              >
                {listVehicles &&
                  selectTypeVehicle !== null &&
                  listVehicles
                    .filter((vehicle) => vehicle.idType === selectTypeVehicle)
                    .map((vehicle) => (
                      <MenuItem
                        key={vehicle.idVehicleBrandsAndModels}
                        value={`${vehicle.idVehicleBrandsAndModels}`}
                      >
                        {vehicle.model}
                      </MenuItem>
                    ))}
              </Select>
            )}
            name="idVehicleBrandAndModel"
            control={props.control}
            rules={{ required: true }}
            defaultValue=""
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>{"ประเภทของที่ส่ง"}</InputLabel>
          <Controller
            render={(propsInController) => (
              <Select
                label="ประเภทของที่ส่ง"
                error={props.errors.typeProduct ? true : false}
                helperText={
                  props.errors.typeProduct ? "กรุณาเลือกประเภทของที่ส่ง" : false
                }
                value={propsInController.field.value}
                onChange={propsInController.field.onChange}
                style={{ borderRadius: "15px" }}
              >
                <MenuItem value={"PE"}>PE</MenuItem>
                <MenuItem value={"PP"}>PP</MenuItem>
                <MenuItem value={"PVC"}>PVC</MenuItem>
              </Select>
            )}
            name="typeProduct"
            control={props.control}
            rules={{ required: true }}
            defaultValue=""
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} lg={4} className={classes.PaddingBottom}>
        <TextField
          placeholder="ระบุน้ำหนักโดยประมาณ"
          label="น้ำหนักโดยประมาณ"
          variant="outlined"
          error={props.errors.weightProduct ? true : false}
          helperText={props.errors.weightProduct ? "กรุณากรอกน้ำหนัก" : false}
          fullWidth
          type="number"
          // helperText="หน่วยเป็น KG"
          inputProps={{
            ...props.register(`weightProduct`, { required: true }),
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            style: {
              borderRadius: "15px",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>{"วัตถุประสงค์"}</InputLabel>
          <Controller
            render={(propsInController) => (
              <Select
                label="วัตถุประสงค์"
                value={propsInController.field.value}
                onChange={propsInController.field.onChange}
                style={{ borderRadius: "15px" }}
              >
                <MenuItem value={"ส่งของให้ลูกค้า"}>ส่งของให้ลูกค้า</MenuItem>
                <MenuItem value={"ส่งสินค้าข้ามโรงงาน"}>
                  ส่งสินค้าข้ามโรงงาน
                </MenuItem>
                <MenuItem value={"อื่นๆ"}>อื่นๆ</MenuItem>
              </Select>
            )}
            name="purpose"
            control={props.control}
            defaultValue=""
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="รายละเอียด"
          placeholder="ระบุรายละเอียด"
          variant="outlined"
          multiline
          rows={3}
          fullWidth
          inputProps={{ ...props.register("detail") }}
          InputProps={{
            style: {
              borderRadius: "15px",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid item xs={12} sm={12}>
          <Controller
            render={(propsInController) => (
              <TextField
                label="จุดรับ"
                placeholder="ระบุจุดรับ"
                variant="outlined"
                disabled
                error={props.errors.fromPlace ? true : false}
                helperText={props.errors.fromPlace ? "กรุณาระบุจุดรับ" : false}
                fullWidth
                value={propsInController.field.value}
                onChange={propsInController.field.onChange}
                InputProps={{
                  style: {
                    borderRadius: "15px",
                  },
                }}
              />
            )}
            name="fromPlace"
            control={props.control}
            rules={{ required: true }}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={12} sm={12} style={{ height: "380px" }}>
          <GoogleMap
            name="fromPlace"
            inputProps={{
              ...props.setValue("fromPlace", dataFrom),
            }}
            value={dataFrom}
            handleGetDataFromGoogleMap={getDataFromGoogleMapFromPlace}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid item xs={12} sm={12}>
          <Controller
            render={(propsInController) => (
              <TextField
                label="จุดส่ง"
                placeholder="ระบุจุดส่ง"
                variant="outlined"
                disabled
                error={props.errors.toPlace ? true : false}
                helperText={props.errors.toPlace ? "กรุณาระบุจุดส่ง" : false}
                fullWidth
                value={propsInController.field.value}
                onChange={propsInController.field.onChange}
                InputProps={{
                  style: {
                    borderRadius: "15px",
                  },
                }}
              />
            )}
            name="toPlace"
            control={props.control}
            rules={{ required: true }}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={12} sm={12} style={{ height: "380px" }}>
          <GoogleMap
            name="toPlace"
            inputProps={{
              ...props.setValue("toPlace", dataTo),
            }}
            value={dataTo}
            handleGetDataFromGoogleMap={getDataFromGoogleMapToplace}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} lg={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            render={(propsInController) => (
              <DatePicker
                label="วันที่"
                minDate={new Date()}
                value={propsInController.field.value}
                onChange={(date) => {
                  propsInController.field.onChange(date);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      ".MuiInputBase-root": { borderRadius: "15px" },
                    }}
                    fullWidth
                    {...params}
                  />
                )}
              />
            )}
            name={"date"}
            defaultValue={new Date()}
            control={props.control}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          render={(propsInController) => (
            <FormControl fullWidth>
              <InputLabel shrink>{"เวลารับ"}</InputLabel>
              <Select
                native
                label="เวลารับ"
                value={propsInController.field.value}
                onChange={(e) => {
                  propsInController.field.onChange(e.target.value);
                }}
                style={{ borderRadius: "15px" }}
              >
                {generateTimeOptions().map((time, index) => {
                  if (time !== "23:30")
                    return (
                      <option key={index} value={time}>
                        {time}
                      </option>
                    );
                })}
              </Select>
            </FormControl>
          )}
          name="startTime"
          control={props.control}
          defaultValue="00:00"
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Controller
          render={(propsInController) => (
            <FormControl fullWidth>
              <InputLabel shrink>{"เวลาที่ต้องการส่งถึง"}</InputLabel>
              <Select
                native
                label="เวลาที่ต้องการส่งถึง"
                value={propsInController.field.value}
                onChange={(e) => {
                  propsInController.field.onChange(e.target.value);
                }}
                style={{ borderRadius: "15px" }}
              >
                {endTimeOptions.map((time, index) => (
                  <option key={index} value={time}>
                    {time}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          name="endTime"
          control={props.control}
          defaultValue="00:00"
        />
      </Grid>
      {allEmployees && (
        <Grid item xs={12} sm={6}>
          <Controller
            render={(propsInController) => (
              <Autocomplete
                options={allEmployees}
                getOptionLabel={(option) =>
                  `${option.firstname_TH} ${option.lastname_TH}`
                }
                isOptionEqualToValue={(option, value) => option === value}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {`${option.firstname_TH} ${option.lastname_TH}`}
                  </Box>
                )}
                value={propsInController.field.value}
                onChange={(e, value) => {
                  if (value) {
                    props.setValue(
                      "nameRecipient",
                      `${value.firstname_TH} ${value.lastname_TH}`
                    );
                    props.setValue(
                      "telephoneMobileRecipient",
                      value.telephoneMobile
                    );
                    propsInController.field.onChange(value);
                  } else {
                    props.setValue("nameRecipient", "");
                    props.setValue("telephoneMobileRecipient", "");

                    propsInController.field.onChange(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="nameRecipient"
                    label="ชื่อผู้รับ"
                    placeholder="ระบุชื่อผู้รับ"
                    variant="outlined"
                    fullWidth
                    error={props.errors.Recipient ? true : false}
                    helperText={
                      props.errors.Recipient ? "กรุณากรอกชื่อผู้รับ" : false
                    }
                    sx={{
                      ".MuiInputBase-root": { borderRadius: "15px" },
                    }}
                  />
                )}
              />
            )}
            name="Recipient"
            control={props.control}
            rules={{ required: true }}
          />
        </Grid>
      )}
      {allEmployees && (
        <Grid item xs={12} sm={6}>
          <Controller
            render={(propsInController) => (
              <TextField
                label="เบอร์โทรศัพท์ผู้รับ"
                placeholder="ระบุเบอร์โทรศัพท์ผู้รับ"
                variant="outlined"
                error={props.errors.telephoneMobileRecipient ? true : false}
                InputLabelProps={{
                  shrink:
                    props.getValues("telephoneMobileRecipient") === "" ||
                    props.getValues("telephoneMobileRecipient") === null ||
                    props.getValues("telephoneMobileRecipient") === undefined
                      ? false
                      : true,
                }}
                helperText={
                  props.errors.telephoneMobileRecipient
                    ? "กรุณากรอกเบอร์โทรผู้รับ"
                    : false
                }
                fullWidth
                value={propsInController.field.value}
                inputProps={{
                  ...props.register("telephoneMobileRecipient"),
                }}
                InputProps={{
                  style: {
                    borderRadius: "15px",
                  },
                }}
              />
            )}
            name="telephoneMobileRecipient"
            control={props.control}
            rules={{ required: true }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default FormDetailFlight;
