import React, { useState, useEffect } from "react";
import { styled } from "@mui/material";
import "dayjs/locale/th";
import Form from "./Form";
import Result from "./Result";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  maxWidth: 1600,
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const RequestCatering = (props) => {
  const [isSuccess, setIsSuccess] = useState(null);
  const [resultData, setResultDate] = useState(null);
  const [catering, setCatering] = useState(
    props.location.state ? props.location.state.catering : null
  );

  const [additionalOption, setAdditionalOption] = useState(null);

  const onSuccessHandler = (data) => {
    setIsSuccess(true);
    setResultDate(data);
  };

  useEffect(() => {
    let temp = [];
    if (catering && catering.additionalOption) {
      for (let i = 0; i < catering.additionalOption.length; i++) {
        if (catering.additionalOption[i].idCateringAdditionalOption !== null) {
          temp.push(catering.additionalOption[i]);
        }
      }
    }
    setAdditionalOption(temp);
  }, []);

  return (
    <div className="page">
      {/* <p>dddddddddd</p> */}
      <Root>
        {isSuccess === null && additionalOption && (
          <Form
            onSuccessHandler={onSuccessHandler}
            catering={catering}
            additionalOptions={additionalOption}
          />
        )}
        {isSuccess === true && <Result data={resultData} catering={catering} />}
      </Root>
    </div>
  );
};

export default RequestCatering;
