import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Route, Redirect, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Container from "@mui/material/Container";
import { Button, IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SnackbarProvider, useSnackbar } from "notistack";

import Login from "./components/pages/LoginPage";
import { UnauthorizedPage, NotFoundPage } from "./components/pages/error";
import { getEmergency } from "./actions/emergency";
//All user
import Notification from "./components/pages/NotificationPage";
import EditProfile from "./components/pages/shared/EditProfile";

//User
import {
  CarBooking,
  ProfilePage,
  CrossAreaCarBooking,
  CrossAreaCarPoolBooking,
  InAreaCarBooking,
  DeliveryCarBooking,
  BetweenSiteCar,
  MyLists,
  Friend2FriendCar,
  IdentifyProfilePage,
  DashboardUser,
  MapBetweenSite,
  Home,
  Sampleshuttle,
  CardDetailSample,
} from "./components/pages/user";

//NewSample
import { FormDetailFlight } from "./components/pages/user/sampleshuttle/NewSample";

//Friend2FriendDriver
import {
  FormOfDriver,
  ListApprove,
  ListRegisterPage,
} from "./components/pages/user/friend2FriendCar/driver";

//Friend2FriendPassenger
import {
  SelectionDrivers,
  ShowDetailDriver,
  WaitingResponses,
  MyPlan,
} from "./components/pages/user/friend2FriendCar/passenger";

//Friend2FriendLender
import {
  FormAddLentCar,
  DashBoardLender,
  ReceiptPage,
  PendingPage,
} from "./components/pages/user/friend2FriendCar/lender";

//Friend2FriendBorrower
import {
  HomePageBorrower,
  DetailCar,
  FilterPageBorrrower,
  DependabilityBorrower,
  RequestPage,
} from "./components/pages/user/friend2FriendCar/borrower";

//Driver
import {
  HomeDriver,
  ChoosingCar,
  AuthenticationCar,
  Bedtimepage,
  EmergencyPage,
  MapPage,
  DetailEmployeePage,
  StartStopDrivingPage,
  GivingPointsPage,
  PlanPage,
  MapBetweenSiteDriver,
  DriverCard,
  MyListsForDriver,
  DetailBookingPageForDriver,
} from "./components/pages/driver";

//DriverDetailSaample
import { DriverCardSampleDetail } from "./components/pages/driver/Sample/NewSample";

//Manager
import {
  DashboardManagerPage,
  ApprovePage,
  DetailBooking,
  MeetingRoomBooking,
  RequestedListForManager,
  CateringDescriptionPageForManager,
  MaintenaceForManager,
  DriverBookingForManager,
  DetailDriverBookingForManager,
  DetailMeetingRoomBookingForManager,
  AdminMeetingRoomDescriptionPageForManager,
} from "./components/pages/manager";

// Admin
import {
  SettingPage,
  EmployeesPage,
  EmployeesFormPage,
  VehiclePage,
  DashBoard,
  RoutesPage,
  EmployeePlace,
  DriverPage,
  FormAddDriver,
  FormAddCar,
  EmployeePage,
  FormAddUser,
  CarManagement,
  DasboardLentCar,
  AdminTechnician,
  AdminNewTechnician,
  AdminEditTechnician,
  AdminNewMeetingRoomBooking,
  AdminNewMeetingRoomBookingForm,
  AdminNewMeetingRoomBookingFormEdit,
  AdminDetailMeetingRoomBooking,
  AdminMeetingRoomDescriptionPage,
  AdminMeetingRoomEditDescriptionPage,
  AdminNewDriverBooking,
  AdminDetailDriverBooking,
  FormAddRestaurant,
  FormAddMenu,
} from "./components/pages/admin";

//User Catering
import CateringRequestedDescriptionPage from "./components/pages/user/Catering/RequestedDescription";
import CateringRestaurantListPage from "./components/pages/user/Catering/RestaurantList";
import CateringRestaurantDescriptionPage from "./components/pages/user/Catering/RestaurantDescription";
import CateringRequestedCatering from "./components/pages/user/Catering/RequestCatering";

//Admin Catering
import AdminCateringRequestedListPage from "./components/pages/admin/Catering/RequestedList";
import AdminCateringRestaurantListPage from "./components/pages/admin/Catering/RestaurantList";

//DetailBooking
// import DetailCrossAreaCar from "./components/pages/shared/detailBookingPage/detailCrossAreaCar";
import DetailInAreaCar from "./components/pages/shared/detailBookingPage/detailInAreaCar";
import DetailDeliveryCar from "./components/pages/shared/detailBookingPage/detailDeliveryCar";
import DetailCrossAreaCarPool from "./components/pages/shared/detailBookingPage/detailCrossAreaCarPool";

//Temp
import DetailBookingPage from "./components/pages/user/detailBookingPage";
import DetailCrossAreaCar from "./components/pages/user/detailBookingPage/detailCrossAreaCar";

//User Maintenace
import MaintenaceRequest from "./components/pages/user/Maintenances/Request";
import MaintenaceDetail from "./components/pages/user/Maintenances/Detail";
import MaintenaceHistory from "./components/pages/user/Maintenances/History";

//Admin Maintenance
import AdminMaintenance from "./components/pages/admin/Maintenances";

//User MeetingRoom
import MeetingRoom from "./components/pages/user/meetingRoom";
import MeetingRoomDetail from "./components/pages/user/meetingRoom/DetailMeetingRoom";

//User Driver Booking
import DriverBooking from "./components/pages/user/DriverBooking";

//User EvaluateSatisfaction
import EvaluateSatisfaction from "./components/pages/user/evaluateSatisfaction";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    padding: 0,
    height: "100vh",
  },
}));

const Routers = (props) => {
  const classes = useStyles();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: Emergency, isFetching: isFetchingEmergency } = useSelector(
    (state) => state.emergency
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const onClickGoToMap = () => {
    window.location.href = "/admin/car_management/showMapAllDriver";
  };

  const onClickGoToMapForDriver = () => {
    window.location.href = "/map";
  };

  useEffect(() => {
    const wsUrl =
      window.location.hostname === "localhost"
        ? "ws://localhost:8080"
        : "wss://api-dot-uniga-393804.as.r.appspot.com/"; // ใช้ wss สำหรับ Production ที่ใช้ HTTPS
    console.log(window.location.hostname);
    console.log(wsUrl);
    const ws = new WebSocket(wsUrl);
    console.log("เปิดการเชื่อมต่อ");

    ws.onmessage = (event) => {
      console.log("มีการแจ้งเตือน");
      const Data = JSON.parse(event.data);
      console.log(Data);
      if (Data.type === "emergency") {
        EmergencyWebSocket(Data);
      }
      if (Data.type === "betweenSiteCar") {
        BetweenSiteCarForDriverWebSocket(Data);
      }
      // แสดงการแจ้งเตือนไปยังผู้ดูแลระบบหรืออัปเดต UI ตามที่ต้องการ
    };

    ws.onclose = () => {
      console.log("การเชื่อมต่อถูกปิด");
    };
    return () => {
      ws.close();
    };
  }, []);

  const EmergencyWebSocket = (Data) => {
    const emergencyData = JSON.parse(Data.message);
    if (
      emergencyData.length > 0 &&
      !isFetchingEmergency &&
      currentUser.roles.includes("ROLE_ADMIN") === true
    ) {
      console.log("แสดงการแจ้งเตือน");
      enqueueSnackbar("คนขับเกิดเหตุฉุกเฉิน", {
        variant: "error",
        persist: true, // ทำให้ Snackbar ค้างไว้จนกว่าจะถูกปิด
        action: (key) => (
          <>
            <Button onClick={onClickGoToMap} style={{ color: "white" }}>
              ไปที่แผนที่
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => closeSnackbar(key)}
            >
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </>
        ),
      });
      dispatch(getEmergency());
    }
  };

  const BetweenSiteCarForDriverWebSocket = (Data) => {
    const idDriver = parseInt(Data.message);
    console.log("BetweenSiteCarForDriverWebSocket");
    console.log(idDriver, currentUser.idUser);
    console.log(currentUser.roles.includes("ROLE_DRIVER"));
    if (
      idDriver == currentUser.idUser &&
      currentUser.roles.includes("ROLE_DRIVER") === true
    ) {
      enqueueSnackbar("New Between Site Car Booking", {
        variant: "success",
        persist: true, // ทำให้ Snackbar ค้างไว้จนกว่าจะถูกปิด
        action: (key) => (
          <>
            <Button
              onClick={onClickGoToMapForDriver}
              style={{ color: "white" }}
            >
              ไปที่แผนที่
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => closeSnackbar(key)}
            >
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </>
        ),
      });
    }
  };
  const RedirectLogin = () => (
    <Route>
      <Redirect to="/login" />
    </Route>
  );

  const AdminRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const ManagerRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_MANAGER") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const UserRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_USER") === true ? (
              <Component {...props} DrawerOpen={rest.DrawerOpen} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const DriverRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_DRIVER") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  // useEffect(() => {
  //   console.log("isEditList", isEditList, isNewList);
  // });

  return (
    <Container className={classes.content} maxWidth={false}>
      <Switch>
        <Route
          exact
          path={["/"]}
          component={() =>
            !currentUser ? (
              <Redirect to="/login" />
            ) : currentUser.roles.includes("ROLE_DRIVER") === true ? (
              <Redirect to="/driver" />
            ) : (
              <Redirect to="/home" />
            )
          }
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/unauthorized" component={UnauthorizedPage} />

        {/* User Route */}
        <UserRoute exact path="/home" component={Home} />
        <UserRoute exact path="/notification" component={Notification} />
        <UserRoute exact path="/profile" component={ProfilePage} />
        <UserRoute
          exact
          path="/identifyprofile"
          component={IdentifyProfilePage}
        />
        <UserRoute exact path="/carbooking" component={CarBooking} />

        <UserRoute
          exact
          path="/transparent_ShiftCar"
          component={DashboardUser}
        />
        <UserRoute
          exact
          path="/crossAreaCar_Booking"
          component={CrossAreaCarBooking}
        />
        <UserRoute
          exact
          path="/crossAreaCarPool_Booking"
          component={CrossAreaCarPoolBooking}
        />
        <UserRoute
          exact
          path="/inAreaCar_Booking"
          component={InAreaCarBooking}
        />
        <UserRoute
          exact
          path="/deliveryItem_Car"
          component={DeliveryCarBooking}
        />
        <UserRoute exact path="/betweenSite_Car" component={BetweenSiteCar} />
        <UserRoute
          exact
          path="/betweenSite_Car/map"
          component={MapBetweenSite}
        />

        <UserRoute
          exact
          path="/friend2friend_Car"
          component={Friend2FriendCar}
        />
        <UserRoute
          exact
          path="/friend2friend_Car/driver/form"
          component={FormOfDriver}
        />
        <UserRoute
          exact
          path="/friend2friend_Car/driver/listApprove"
          component={ListApprove}
        />
        <UserRoute
          exact
          path="/friend2friend_Car/driver/listRegisterPage"
          component={ListRegisterPage}
        />

        <UserRoute
          exact
          path="/friend2friend_Car/passenger/selectionDrivers"
          component={SelectionDrivers}
        />
        <UserRoute
          exact
          path="/friend2friend_Car/passenger/showDetail_driver"
          component={ShowDetailDriver}
        />
        <UserRoute
          exact
          path="/friend2friend_Car/passenger/waiting_responses"
          component={WaitingResponses}
        />
        <UserRoute
          exact
          path="/friend2friend_Car/passenger/my_plan"
          component={MyPlan}
        />

        <UserRoute
          exact
          path="/friend2friend_Car/lender"
          component={DashBoardLender}
        />
        <UserRoute
          exact
          path="/friend2friend_Car/lender/pending"
          component={PendingPage}
        />
        <UserRoute
          exact
          path="/friend2friend_Car/lender/receipt"
          component={ReceiptPage}
        />
        <UserRoute
          exact
          path="/friend2friend_Car/lender/add_car"
          component={FormAddLentCar}
        />

        <UserRoute
          exact
          path="/friend2friend_Car/borrower"
          component={HomePageBorrower}
        />
        <UserRoute
          exact
          path={[
            "/friend2friend_Car/borrower/car",
            "/friend2friend_Car/lender/car",
            "/admin/detail_lentcar",
          ]}
          component={DetailCar}
        />
        <UserRoute
          exact
          path="/friend2friend_Car/borrower/filter"
          component={FilterPageBorrrower}
        />
        <UserRoute
          exact
          path="/friend2friend_Car/borrower/dependability"
          component={DependabilityBorrower}
        />
        <UserRoute
          exact
          path="/friend2friend_Car/borrower/requestbooking"
          component={RequestPage}
        />

        <Route
          exact
          path="/myListOfRequest"
          component={() => (
            <Redirect to="/myListOfRequest/cross_area_car_booking" />
          )}
        />
        <UserRoute
          exact
          path={[
            "/myListOfRequest/cross_area_car_booking",
            "/myListOfRequest/cross_area_car_pool_booking",
            "/myListOfRequest/in_area_car_booking",
            "/myListOfRequest/delivery_car_booking",
            "/myListOfRequest/meeting_room_booking",
            "/myListOfRequest/driver_booking",
          ]}
          component={MyLists}
        />
        <UserRoute
          exact
          path={["/myListOfRequest/detail_booking", "/manager/detail_booking"]}
          component={DetailBookingPage}
        />
        <UserRoute
          exact
          path="/myListOfRequest/cross_area_car_booking_detail"
          component={DetailCrossAreaCar}
        />
        <UserRoute
          exact
          path="/myListOfRequest/cross_area_car_pool_booking_detail"
          component={DetailCrossAreaCarPool}
        />
        <UserRoute
          exact
          path="/myListOfRequest/in_area_car_booking_detail"
          component={DetailInAreaCar}
        />
        <UserRoute
          exact
          path="/myListOfRequest/delivery_car_booking_detail"
          component={DetailDeliveryCar}
        />

        {/* User Catering Route */}
        <UserRoute
          exact
          path="/catering/requested/:requestedId"
          component={CateringRequestedDescriptionPage}
        />
        <UserRoute
          exact
          path="/catering/restaurant"
          component={CateringRestaurantListPage}
        />
        <UserRoute
          exact
          path="/catering/restaurant/:restaurantId"
          component={CateringRestaurantDescriptionPage}
        />
        <UserRoute
          exact
          path="/catering/request_catering"
          component={CateringRequestedCatering}
        />

        {/* User Maintenance Route */}
        <UserRoute
          exact
          path="/maintenances/request"
          component={MaintenaceRequest}
        />
        <UserRoute
          exact
          path="/maintenances/history"
          component={MaintenaceHistory}
        />
        <UserRoute
          exact
          path="/maintenances/detail/:maintenanceId"
          component={MaintenaceDetail}
        />

        {/* User sampleshuttle Route */}
        <UserRoute exact path="/sampleshuttle" component={Sampleshuttle} />
        <UserRoute exact path="/sample" component={DriverCard} />
        <UserRoute
          exact
          path="/sampleshuttle/NewSample/formDetailFlight"
          component={FormDetailFlight}
          // // render={(routeProps) => (
          // //   <FormDetailFlight
          // //     {...routeProps}
          // //     isEditList={
          // //       routeProps.location.state &&
          // //       routeProps.location.state.isEditList
          // //     }
          // //     isNewList={
          // //       routeProps.location.state && routeProps.location.state.isNewList
          // //     }
          //   />
          //
        />
        <UserRoute
          exact
          path="/sampleshuttle/detail_booking/"
          component={CardDetailSample}
        />
        <UserRoute
          exact
          path="/sample/detail_booking/"
          component={DriverCardSampleDetail}
        />

        {/* Evaluate satisfaction */}

        <UserRoute
          exact
          path="/evaluate_satisfaction"
          component={EvaluateSatisfaction}
        />

        {/* Meeting Room Route */}
        <UserRoute exact path="/meetingroom" component={MeetingRoom} />
        <UserRoute
          exact
          path="/meetingroom/detail/"
          component={MeetingRoomDetail}
        />

        {/* Driver Booking Route */}
        <UserRoute exact path="/driverbooking" component={DriverBooking} />

        {/* Driver Route */}
        <DriverRoute exact path="/driver" component={HomeDriver} />
        <DriverRoute exact path="/chosecar" component={ChoosingCar} />
        <DriverRoute exact path="/authencar" component={AuthenticationCar} />
        <DriverRoute exact path="/emergency" component={EmergencyPage} />
        <DriverRoute exact path="/bedtimelastnight" component={Bedtimepage} />
        <DriverRoute exact path="/plan" component={PlanPage} />
        <DriverRoute exact path="/map" component={MapPage} />
        <DriverRoute
          exact
          path={["/map/profileEmployee/:id", "/plan/profileEmployee/:id"]}
          component={DetailEmployeePage}
        />
        <DriverRoute exact path="/startstop" component={StartStopDrivingPage} />
        <DriverRoute exact path="/reviews" component={GivingPointsPage} />
        <DriverRoute
          exact
          path="/map-between-site"
          component={MapBetweenSiteDriver}
        />
        <Route
          exact
          path="/myListForDriver"
          component={() => (
            <Redirect to="/myListForDriver/cross_area_car_booking" />
          )}
        />
        <DriverRoute
          exact
          path={[
            "/myListForDriver/cross_area_car_booking",
            "/myListForDriver/cross_area_car_pool_booking",
            "/myListForDriver/in_area_car_booking",
            "/myListForDriver/delivery_car_booking",
            "/myListForDriver/between_site_car_booking",
            "/myListForDriver/delivery_sample_shuttle_car_booking",
            "/myListForDriver/driver_booking",
          ]}
          component={MyListsForDriver}
        />

        <DriverRoute
          exact
          path="/myListForDriver/booking_detail"
          component={DetailBookingPageForDriver}
        />

        {/* Manager Route */}
        <ManagerRoute
          exact
          path="/manager/dashboard"
          component={DashboardManagerPage}
        />
        <ManagerRoute exact path="/manager/approved" component={ApprovePage} />
        <ManagerRoute
          exact
          path="/manager/approved/detail_of_order"
          component={DetailBooking}
        />
        <ManagerRoute
          exact
          path="/manager/approved/meeting_room"
          component={MeetingRoomBooking}
        />
        <ManagerRoute
          exact
          path="/manager/catering/requested_list"
          component={RequestedListForManager}
        />
        <ManagerRoute
          exact
          path="/manager/catering/requested/:requestedId"
          component={CateringDescriptionPageForManager}
        />
        <ManagerRoute
          exact
          path="/manager/maintenances"
          component={MaintenaceForManager}
        />
        <ManagerRoute
          exact
          path="/manager/driver_booking"
          component={DriverBookingForManager}
        />
        <ManagerRoute
          exact
          path="/manager/driver_booking/detail"
          component={DetailDriverBookingForManager}
        />
        <ManagerRoute
          exact
          path="/manager/meeting_room_booking"
          component={DetailMeetingRoomBookingForManager}
        />
        <ManagerRoute
          exact
          path="/manager/meeting_room_booking/detail/:requestedId"
          component={AdminMeetingRoomDescriptionPageForManager}
        />

        {/* Admin Route */}
        <AdminRoute exact path="/admin/employees" component={EmployeesPage} />
        <AdminRoute
          exact
          path={["/admin/employees/form", "/admin/employees/form/:id"]}
          component={EmployeesFormPage}
        />
        <AdminRoute exact path="/admin/setting" component={SettingPage} />
        <AdminRoute exact path="/admin/vehicle_page" component={VehiclePage} />
        <AdminRoute exact path="/admin/dashboard" component={DashBoard} />
        <AdminRoute exact path="/admin/routes_page" component={RoutesPage} />
        <AdminRoute
          exact
          path="/admin/employee_page"
          component={EmployeePlace}
        />
        <AdminRoute exact path="/admin/driver" component={DriverPage} />
        <AdminRoute
          exact
          path="/admin/driver/add_driver"
          component={FormAddDriver}
        />
        <AdminRoute
          exact
          path="/admin/vehicle_page/add_car"
          component={FormAddCar}
        />
        <AdminRoute exact path="/admin/employee" component={EmployeePage} />
        <AdminRoute
          exact
          path="/admin/employee/add_user"
          component={FormAddUser}
        />
        <AdminRoute exact path="/admin/edit_profile" component={EditProfile} />
        <AdminRoute path="/admin/car_management" component={CarManagement} />
        <AdminRoute
          path="/admin/dasboard_lentcar"
          component={DasboardLentCar}
        />

        {/* Admin Catering Route */}
        <AdminRoute
          path="/admin/catering/requested_list"
          component={AdminCateringRequestedListPage}
        />
        <AdminRoute
          path="/admin/catering/restaurant_list"
          component={AdminCateringRestaurantListPage}
        />
        <AdminRoute
          path="/admin/catering/addRestaurant"
          component={FormAddRestaurant}
        />
        <AdminRoute path="/admin/catering/addMenu/" component={FormAddMenu} />

        {/* Admin Maintenance Route */}
        <AdminRoute
          exact
          path="/admin/maintenances"
          component={AdminMaintenance}
        />
        <AdminRoute
          exact
          path="/admin/technicians"
          component={AdminTechnician}
        />
        <AdminRoute
          exact
          path="/admin/newtechnician"
          component={AdminNewTechnician}
        />
        <AdminRoute
          exact
          path="/admin/edittechnician/:id"
          component={AdminEditTechnician}
        />

        {/* Admin MeetingRoom Route */}
        <AdminRoute
          exact
          path="/admin/meetingRoom_page"
          component={AdminNewMeetingRoomBooking}
        />
        <AdminRoute
          exact
          path="/admin/Newmeetingroom/form/"
          component={AdminNewMeetingRoomBookingForm}
        />
        <AdminRoute
          exact
          path="/admin/Newmeetingroom/formEdit/:id"
          component={AdminNewMeetingRoomBookingFormEdit}
        />
        <AdminRoute
          exact
          path="/admin/Newmeetingroom/detailMeetingRoomBooking/"
          component={AdminDetailMeetingRoomBooking}
        />
        <AdminRoute
          exact
          path="/admin/Newmeetingroom/requested/:requestedId"
          component={AdminMeetingRoomDescriptionPage}
        />
        <AdminRoute
          exact
          path="/admin/Newmeetingroom/edit_requested/"
          component={AdminMeetingRoomEditDescriptionPage}
        />

        {/* Admin DriverBooking Route */}
        <AdminRoute
          exact
          path="/admin/driverBooking_page"
          component={AdminNewDriverBooking}
        />
        <AdminRoute
          exact
          path="/admin/driverBooking_page/detail_driverBooking/"
          component={AdminDetailDriverBooking}
        />

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Container>
  );
};

export default Routers;
