import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { SnackbarProvider, useSnackbar } from "notistack";

import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import FormInformant from "./formInformat";
import FormDetailFlight from "./formDetailFlight";
import NavButtonForm from "../../../../shared/NavButton/NavButtonForm";
import DialogShowDetailDialog from "./dialogShowDetailBooking";
import deliveryCarBookingService from "../../../../../../services/deliveryCarBooking.service";
import {
  getDeliveryCarBookingByStartDate,
  getDeliveryCarBookingByStartDateAndEndDate,
} from "../../../../../../actions/deliveryCarBooking";

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // paddingInline: "5vw",
    // [theme.breakpoints.down("sm")]: {
    //   paddingInline: "0",
    //   marginLeft: "0",
    //   width: "100%",
    // },
  },
  Grid: {
    width: "80%",
    margin: "0 auto",
    marginTop: "20px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
    },
  },
  Title: {
    textAlign: "center",
    fontSize: "25px",
    margin: "25px 0px",
  },
}));

const DivHead = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // marginBottom: "20px",
  marginTop: "20px",
});

const BackButton = styled(Button)(({ theme }) => ({
  // position: "absolute",
  color: "black",
  fontSize: "20px",
  // left: "30px",
}));

const EditDetailDeliveryitemCar = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [booking, setBooking] = useState(props.location.state.booking);
  const { user: User } = useSelector((state) => state.auth);

  const [openDetailBooking, setOpenDetailBooking] = useState(false);

  useEffect(() => {
    const startTime = SplitTimeReverse(booking.startTime);
    const endTime = SplitTimeReverse(booking.endTime);
    const Approved = {
      firstname_TH: booking.nameRecipient.split(" ")[0],
      lastname_TH: booking.nameRecipient.split(" ")[1],
      telephoneMobileRecipient: booking.telephoneMobileRecipient,
    };
    setValue("idDeliveryCarBooking", booking.idDeliveryCarBooking);
    setValue("idUser", booking.idUser);
    setValue("name", booking.name);
    setValue("telephoneMobile", booking.telephoneMobile);
    setValue("email", booking.email);
    setValue("section", booking.section);
    setValue("department", booking.department);
    setValue("idTypeVehicle", booking.idTypeVehicle);
    setValue("idVehicleBrandAndModel", booking.idVehicleBrandAndModel);
    setValue("imageVehicle", booking.imageVehicle);
    setValue("typeProduct", booking.typeProduct);
    setValue("weightProduct", parseInt(booking.weightProduct));
    setValue("purpose", booking.purpose);
    setValue("detail", booking.detail);
    setValue("fromPlace", booking.fromPlace);
    setValue("toPlace", booking.toPlace);
    setValue("date", booking.date);
    setValue("startTime", startTime);
    setValue("endTime", endTime);
    setValue("costBooking", "");
    setValue("Recipient", Approved);
    setValue(
      "nameRecipient",
      `${Approved.firstname_TH} ${Approved.lastname_TH}`
    );
    setValue("telephoneMobileRecipient", Approved.telephoneMobileRecipient);
  }, []);

  console.log(booking);
  console.log("Recipient", watch("Recipient"));
  const onSubmitHandler = (date) => {
    setOpenDetailBooking(true);
    console.log(date);
  };

  const onCloseDialog = () => {
    setOpenDetailBooking(false);
  };

  const SplitTimeReverse = (dateString) => {
    const date = new Date(dateString);
    let hour = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minutes}`;
  };

  const onClickSubmit = (date) => {
    console.log(date);
    deliveryCarBookingService
      .postUpdateDeliveryCarBooking(date)
      .then((data) => {
        if (data.status === 200) {
          enqueueSnackbar("Update Booking Success", { variant: "success" });
          fetchData();
          props.history.push("/admin/car_management/delivery");
        } else {
          enqueueSnackbar("Update Booking Failed", { variant: "error" });
        }
      });
  };

  const fetchData = () => {
    if (props.endDate) {
      let date = {
        startDate: new Date(props.startDate),
        endDate: new Date(props.endDate),
      };
      dispatch(getDeliveryCarBookingByStartDateAndEndDate(date));
    } else {
      let date = {
        startDate: new Date(props.startDate),
      };
      dispatch(getDeliveryCarBookingByStartDate(date));
    }
  };

  return (
    <div className={classes.DivCoverReturn}>
      <DivHead>
        <BackButton onClick={() => props.history.goBack()}>
          <ArrowBackIosNewIcon /> Back
        </BackButton>

        <Typography className={classes.Title}>แก้ไขการขอรถส่งของ</Typography>
        <div></div>
      </DivHead>
      <form style={{ width: "80%" }} onSubmit={handleSubmit(onSubmitHandler)}>
        <FormInformant
          register={register}
          setValue={setValue}
          errors={errors}
        />
        <FormDetailFlight
          register={register}
          control={control}
          watch={watch}
          fromPlace={booking.fromPlace}
          toPlace={booking.toPlace}
          idTypeVehicle={booking.idTypeVehicle}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
        />
        <NavButtonForm type="submit" numberOfPage={1} />
      </form>
      {openDetailBooking && (
        <DialogShowDetailDialog
          open={openDetailBooking}
          handleClose={onCloseDialog}
          getValues={getValues}
          onClickSubmit={handleSubmit(onClickSubmit)}
        />
      )}
    </div>
  );
};

export default EditDetailDeliveryitemCar;
