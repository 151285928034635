import ExcelJS from "exceljs"; // ติดตั้ง exceljs ก่อน
import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

const CreateExcel = ({ name, headerValues, data }) => {
  const [loading, setLoading] = useState(false);
  const CompensateXlsx = (name, headerValues, data) => {
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(name); // ตั้งชื่อ sheet

    // ชื่อหัวข้อ header
    // const headerValues = [
    //     t("EmployeeID"),
    //     t("FirstName"),
    //     t("LastName"),
    //     t("Position"),
    //     t("Company"),
    //     t("Division"),
    //     t("Department"),
    //     t("Section"),
    //     t("TotalCompensatoryHoursEarned"),
    //     t("AccumulatedCompensatoryLeaveDays"),
    // ];

    const headerRow = worksheet.addRow(headerValues);
    headerRow.height = 50; // ปรับความสุงของ header cell

    const headerStyle1 = {
      font: {
        bold: true,
        size: 18,
        name: "TH SarabunPSK",
        color: { argb: "FFFFFF" },
      },
      alignment: { horizontal: "center", vertical: "middle" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "002060" },
      },
    };

    // ตกแต่งตัวอักษร, สีของ cell แต่ละช่อง
    headerRow.eachCell((cell) => {
      cell.style = headerStyle1; // ตัวแปรตกแต่ง cell จากข้างบน
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    const colWidths = Object.keys(data[0]).map((key) => ({
      key: key,
      width: 30, // setting a fixed width for each key
    }));
    // ปรับความกว้างของ cell
    // const colWidths = [
    //     { key: "employeeID", width: 30 },
    //     { key: "firstname", width: 30 },
    //     { key: "lastname", width: 30 },
    //     { key: "position", width: 30 },
    //     { key: "company", width: 30 },
    //     { key: "division", width: 30 },
    //     { key: "department", width: 30 },
    //     { key: "section", width: 30 },
    //     { key: "totalCompensate", width: 50 },
    //     { key: "CompensateLeaveAccumulated", width: 50 },
    // ];

    // Set column widths
    colWidths.forEach((col, index) => {
      worksheet.getColumn(index + 1).width = col.width;
    });

    // นำค่ามาแสดง เอาค่าที่แสดงใส่ให้ตรงตามตำแหน่งของ header
    data &&
      data.forEach((item) => {
        const row = Object.values(item).map((value) =>
          value === null || value === undefined || value === "" ? "-" : value
        );
        // const row = [
        //     item.employeeID ? item.employeeID : "-",
        //     item.firstname_TH ? item.firstname_TH : "-",
        //     item.lastname_TH ? item.lastname_TH : "-",
        //     item.positionName ? item.positionName : "-",
        //     item.companyName ? item.companyName : "-",
        //     item.divisionName ? item.divisionName : "-",
        //     item.departmentName ? item.departmentName : "-",
        //     item.sectionName ? item.sectionName : "-",
        //     item.amountHour ? item.amountHour : "-",
        //     item.quota ? item.quota : "-"
        // ];

        const excelRow = worksheet.addRow(row);

        const contentStyle = {
          font: { size: 18, name: "TH SarabunPSK" },
          alignment: { horizontal: "center", vertical: "middle" },
        };

        // ตกแต่ง cell เหมือนกับ headerStyle1
        excelRow.eachCell((cell) => {
          cell.style = contentStyle;
          cell.border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
        });
      });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
      setLoading(false);
    });
  };

  const onClick = () => {
    CompensateXlsx(name, headerValues, data);
  };

  return (
    <LoadingButton
      loading={loading}
      loadingPosition="start"
      startIcon={<DownloadRoundedIcon />}
      variant="outlined"
      onClick={onClick}
    >
      ดาวน์โหลด
    </LoadingButton>
  );
};

export default CreateExcel;
