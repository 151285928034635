import {
  DELIVERY_CAR_BOOKINGS_FETCHING,
  DELIVERY_CAR_BOOKINGS_FAILED,
  DELIVERY_CAR_BOOKINGS_SUCCESS,
} from "./types";

import DeliveryCarBookingService from "../services/deliveryCarBooking.service";

export const getAllDeliveryCarBookings = () => async (dispatch) => {
  try {
    dispatch({
      type: DELIVERY_CAR_BOOKINGS_FETCHING,
    });
    const res = await DeliveryCarBookingService.getAllDeliveryCarBookings();
    if (res) {
      dispatch({
        type: DELIVERY_CAR_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DELIVERY_CAR_BOOKINGS_FAILED,
    });
  }
};

export const getDeliveryCarBookingByIdUser = (idUser) => async (dispatch) => {
  try {
    dispatch({
      type: DELIVERY_CAR_BOOKINGS_FETCHING,
    });
    const res = await DeliveryCarBookingService.getDeliveryCarBookingByIdUser(
      idUser
    );
    if (res) {
      dispatch({
        type: DELIVERY_CAR_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DELIVERY_CAR_BOOKINGS_FAILED,
    });
  }
};

export const getDeliveryCarBookingByIdDriver =
  (idDriver) => async (dispatch) => {
    try {
      dispatch({
        type: DELIVERY_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await DeliveryCarBookingService.getDeliveryCarBookingByIdDriver(
          idDriver
        );
      if (res) {
        dispatch({
          type: DELIVERY_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: DELIVERY_CAR_BOOKINGS_FAILED,
      });
    }
  };

export const getDeliveryCarBookingByFilterByDriver =
  (filter) => async (dispatch) => {
    try {
      dispatch({
        type: DELIVERY_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await DeliveryCarBookingService.getDeliveryCarBookingByFilterByDriver(
          filter
        );
      if (res) {
        dispatch({
          type: DELIVERY_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: DELIVERY_CAR_BOOKINGS_FAILED,
      });
    }
  };

export const postNewDeliveryCarBooking =
  (newbooking, idUser) => async (dispatch) => {
    try {
      dispatch({
        type: DELIVERY_CAR_BOOKINGS_FETCHING,
      });
      const res = await DeliveryCarBookingService.postNewDeliveryCarBooking(
        newbooking,
        idUser
      );
      if (res) {
        dispatch({
          type: DELIVERY_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: DELIVERY_CAR_BOOKINGS_FAILED,
      });
    }
  };
export const getDeliveryCarBookingByFilter = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: DELIVERY_CAR_BOOKINGS_FETCHING,
    });
    const res = await DeliveryCarBookingService.getDeliveryCarBookingByFilter(
      filter
    );
    if (res) {
      dispatch({
        type: DELIVERY_CAR_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DELIVERY_CAR_BOOKINGS_FAILED,
    });
  }
};

export const getDeliveryCarBookingByStartDate = (date) => async (dispatch) => {
  try {
    dispatch({
      type: DELIVERY_CAR_BOOKINGS_FETCHING,
    });
    const res =
      await DeliveryCarBookingService.getDeliveryCarBookingByStartDate(date);
    if (res) {
      dispatch({
        type: DELIVERY_CAR_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DELIVERY_CAR_BOOKINGS_FAILED,
    });
  }
};

export const getDeliveryCarBookingByStartDateAndEndDate =
  (date) => async (dispatch) => {
    try {
      dispatch({
        type: DELIVERY_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await DeliveryCarBookingService.getDeliveryCarBookingByStartDateAndEndDate(
          date
        );
      if (res) {
        dispatch({
          type: DELIVERY_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: DELIVERY_CAR_BOOKINGS_FAILED,
      });
    }
  };
