import React, { Fragment, useState, useEffect } from "react";
import { styled, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DeleteModal from "../../../../shared/deleteModal";
import { Grid } from "@mui/material";

import MeetingRoomService from "../../../../../../services/meetingRoom.service";
import meetingRoomService from "../../../../../../services/meetingRoom.service";
import { color } from "@mui/system";

const StyledRoot = styled("div")(({ theme, isActive }) => ({
  "& .Title": {
    fontSize: "26px",
    textAlign: "start",
    color: isActive ? "black" : "#919EAB",
  },
  "& .Place": {
    fontSize: "20px",
    textAlign: "start",
    color: isActive ? "#293A80" : "#919EAB",
  },
  "& .numOfPeople": {
    fontSize: "20px",
    textAlign: "start",
    color: isActive ? "#293A80" : "#919EAB",
  },
  "& .Price": {
    fontSize: "20px",
    textAlign: "end",
    color: isActive ? "#293A80" : "#919EAB",
  },
  "& .Button": {
    fontSize: "15px",
  },
  "& .iconButton": {
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "white",
    color: "gray",
    zIndex: 1,
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
}));

const MediaCard = (props) => {
  const [meeting, setMeeting] = useState({});
  const [meetingImage, setMeetingImage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (props.meeting) {
      setMeeting(props.meeting);
      if (props.meeting.fileUrl.length > 0) {
        setMeetingImage(props.meeting.fileUrl);
      } else {
        MeetingRoomService.getIAllImageNewMeetingRoomById(
          props.meeting.idNewMeetingRoom
        ).then((res) => {
          if (res.data) {
            setMeetingImage(res.data[0].imageBase64);
          } else {
            console.log("error");
          }
        });
      }
    }
  }, [props.meeting]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledRoot isActive={meeting.isActive}>
      <Card style={{ position: "relative" }}>
        {/* IconButton สำหรับแสดงเมนู */}
        <IconButton className="iconButton" onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>

        {/* เมนู */}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            disabled={meeting.isActive ? false : true}
            onClick={() => props.onClickEdit(meeting.idNewMeetingRoom)}
          >
            <EditRoundedIcon style={{ marginRight: "8px" }} />
            แก้ไข
          </MenuItem>
          <MenuItem
            disabled={meeting.isActive ? false : true}
            onClick={() => {
              setOpenDeleteModal(true);
            }}
          >
            <DeleteRoundedIcon style={{ marginRight: "8px", color: "red" }} />
            ลบ
          </MenuItem>
        </Menu>

        <CardMedia
          sx={{
            height: 200,
            filter: meeting.isActive ? "grayscale(0%)" : "grayscale(100%)",
          }}
          image={meetingImage}
          title="green iguana"
        />
        <CardContent>
          <Typography className="Title">{meeting.nameMeetingRoom}</Typography>
          <Grid style={{ display: "flex", marginTop: "8px" }}>
            <img
              style={{ width: "26px", height: "26px", marginRight: "6px" }}
              src={"/assets/Image/MeetingRoom/business.png"}
              alt="Logo"
            />
            <Typography className="Place">สถานที่ {meeting.place}</Typography>
          </Grid>
          <Grid style={{ display: "flex", marginTop: "8px" }}>
            <img
              style={{ width: "26px", height: "26px", marginRight: "6px" }}
              src={"/assets/Image/MeetingRoom/people.png"}
              alt="Logo"
            />
            <Typography className="numOfPeople">
              จำนวนที่จองได้ {meeting.numberOfPeople} คน
            </Typography>
          </Grid>
        </CardContent>
        <CardContent style={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            style={{ width: "30px", height: "30px", marginRight: "6px" }}
            src={"/assets/Image/MeetingRoom/dollar.png"}
            alt="Logo"
          />
          <Typography className="Price">{meeting.price} บาท/ชม</Typography>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            disabled={meeting.isActive ? false : true}
            style={{
              color: meeting.isActive ? "#00bb77" : "gray",
              borderColor: meeting.isActive ? "#00bb77" : "gray",
            }}
            onClick={() => {
              props.onClickCardHandler(meeting.idNewMeetingRoom);
            }}
          >
            รายละเอียดเพิ่มเติม
          </Button>
        </CardActions>
      </Card>
      <DeleteModal
        open={openDeleteModal}
        onCloseDeleteModalHandle={() => setOpenDeleteModal(false)}
        onClickDelete={() => {
          props.onClickDelete(meeting.idNewMeetingRoom);
          setOpenDeleteModal(false);
          setAnchorEl(null);
        }}
        label={"ลบสถานที่ " + meeting.nameMeetingRoom}
      />
    </StyledRoot>
  );
};

export default MediaCard;
