import { httpClient } from "./httpClient";

const getAllCrossAreaCarBookings = () => {
  return httpClient.get("cross_area_car_bookings");
};

const getCrossAreaCarBookingById = (id) => {
  return httpClient.get("cross_area_car_booking/" + id);
};

const getCrossAreaCarBookingByIdUser = (idUser) => {
  return httpClient.get("cross_area_car_booking_byIdUser/" + idUser);
};

const getCrossAreaCarBookingByIdDriver = (idDriver) => {
  return httpClient.get("cross_area_car_booking_byIdDriver/" + idDriver);
};

const getCrossAreaCarBookingByIdApprovedUserForManager = (idApprovedUser) => {
  return httpClient.get(
    "cross_area_car_booking_byIdApprovedUser/" + idApprovedUser
  );
};

const getCrossAreaCarBookingByStartDate = (date) => {
  return httpClient.post("cross_area_car_bookingBystartDate", date);
};

const getCrossAreaCarBookingByStartDateAndEndDate = (date) => {
  return httpClient.post("cross_area_car_bookingByStartDateAndendDate", date);
};

const postNewCrossAreaCarBooking = (newbooking, idUser) => {
  return httpClient.post("add_newcross_area_car_booking", [newbooking, idUser]);
};

const postUpdateCrossAreaCarBooking = (newbooking) => {
  return httpClient.post("update_cross_area_car_booking", [newbooking]);
};

const postManageCarCrossAreaCarBooking = (manageCar) => {
  return httpClient.post("manage_car_cross_area_car_booking", manageCar);
};
const deleteCarCrossAreaCarBooking = (delCar) => {
  return httpClient.post("delete_car_cross_area_car_booking", delCar);
};
const postApprovedCrossAreaCarBooking = (approved) => {
  return httpClient.post("approved_cross_area_car_booking", approved);
};

const getCrossAreaCarBookingByIdUserForRating = (idUser) => {
  return httpClient.get("cross_area_car_booking_byIdUser_ForRating/" + idUser);
};
const getAllCrossAreaCarBookingsByFilter = (filter) => {
  return httpClient.post("cross_area_car_booking_ByFilter", filter);
};

const getAllCrossAreaCarBookingsByFilterByIdDriver = (filter) => {
  return httpClient.post("cross_area_car_booking_ByFilter_byIdDriver", filter);
};

export default {
  getAllCrossAreaCarBookings,
  getCrossAreaCarBookingById,
  getCrossAreaCarBookingByIdUser,
  getCrossAreaCarBookingByIdDriver,
  getCrossAreaCarBookingByIdApprovedUserForManager,
  getCrossAreaCarBookingByStartDate,
  getCrossAreaCarBookingByStartDateAndEndDate,
  postNewCrossAreaCarBooking,
  postUpdateCrossAreaCarBooking,
  postManageCarCrossAreaCarBooking,
  deleteCarCrossAreaCarBooking,
  postApprovedCrossAreaCarBooking,
  getCrossAreaCarBookingByIdUserForRating,
  getAllCrossAreaCarBookingsByFilter,
  getAllCrossAreaCarBookingsByFilterByIdDriver,
};
