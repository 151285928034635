import { httpClient } from "./httpClient";

const getAllDeliveryCarBookings = () => {
  return httpClient.get("delivery_car_bookings");
};

const getDeliveryCarBookingById = (id) => {
  return httpClient.get("delivery_car_booking/" + id);
};

const getDeliveryCarBookingByIdUser = (idUser) => {
  return httpClient.get("delivery_car_booking_byIdUser/" + idUser);
};

const getDeliveryCarBookingByIdDriver = (idDriver) => {
  return httpClient.get("delivery_car_booking_byIdDriver/" + idDriver);
};

const getDeliveryCarBookingByStartDate = (date) => {
  return httpClient.post("delivery_car_bookingBystartDate", date);
};

const getDeliveryCarBookingByStartDateAndEndDate = (date) => {
  return httpClient.post("delivery_car_bookingByStartDateAndendDate", date);
};
const postNewDeliveryCarBooking = (newbooking, idUser) => {
  return httpClient.post("delivery_car_new_booking", [newbooking, idUser]);
};
const postUpdateDeliveryCarBooking = (newbooking) => {
  return httpClient.post("delivery_car_update_booking", [newbooking]);
};
const postManageCarDeliveryCarBooking = (manageCar) => {
  return httpClient.post("manage_car_delivery_car_booking", manageCar);
};
const deleteCarDeliveryCarBooking = (delCar) => {
  return httpClient.post("delete_car_delivery_car_booking", delCar);
};
const postApprovedlDeliveryCarBooking = (approved) => {
  return httpClient.post("approved_delivery_car_booking", approved);
};
const getDeliveryCarBookingByIdUserForRating = (idUser) => {
  return httpClient.get("delivery_car_booking_byIdUser_ForRating/" + idUser);
};
const getDeliveryCarBookingByFilter = (filter) => {
  return httpClient.post("delivery_car_bookin_ByFilter", filter);
};
const getDeliveryCarBookingByFilterByDriver = (filter) => {
  return httpClient.post("delivery_car_booking_ByFilter_ByIdDriver", filter);
};

export default {
  getAllDeliveryCarBookings,
  getDeliveryCarBookingById,
  getDeliveryCarBookingByIdUser,
  getDeliveryCarBookingByIdDriver,
  getDeliveryCarBookingByStartDate,
  getDeliveryCarBookingByStartDateAndEndDate,
  postNewDeliveryCarBooking,
  postUpdateDeliveryCarBooking,
  postManageCarDeliveryCarBooking,
  deleteCarDeliveryCarBooking,
  postApprovedlDeliveryCarBooking,
  getDeliveryCarBookingByIdUserForRating,
  getDeliveryCarBookingByFilter,
  getDeliveryCarBookingByFilterByDriver,
};
