import {
  CROSS_AREA_CAR_BOOKINGS_FETCHING,
  CROSS_AREA_CAR_BOOKINGS_FAILED,
  CROSS_AREA_CAR_BOOKINGS_SUCCESS,
} from "./types";

import CrossAreaCarBookingService from "../services/crossAreaCarBooking.service";

export const getAllCrossAreaCarBookings = () => async (dispatch) => {
  try {
    dispatch({
      type: CROSS_AREA_CAR_BOOKINGS_FETCHING,
    });
    const res = await CrossAreaCarBookingService.getAllCrossAreaCarBookings();
    if (res) {
      dispatch({
        type: CROSS_AREA_CAR_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CROSS_AREA_CAR_BOOKINGS_FAILED,
    });
  }
};

export const getCrossAreaCarBookingByIdUser = (idUser) => async (dispatch) => {
  try {
    dispatch({
      type: CROSS_AREA_CAR_BOOKINGS_FETCHING,
    });
    const res = await CrossAreaCarBookingService.getCrossAreaCarBookingByIdUser(
      idUser
    );
    if (res) {
      dispatch({
        type: CROSS_AREA_CAR_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CROSS_AREA_CAR_BOOKINGS_FAILED,
    });
  }
};

export const getCrossAreaCarBookingByIdDriver =
  (idDriver) => async (dispatch) => {
    try {
      dispatch({
        type: CROSS_AREA_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await CrossAreaCarBookingService.getCrossAreaCarBookingByIdDriver(
          idDriver
        );
      if (res) {
        dispatch({
          type: CROSS_AREA_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CROSS_AREA_CAR_BOOKINGS_FAILED,
      });
    }
  };

export const getAllCrossAreaCarBookingsByFilterByIdDriver =
  (filter) => async (dispatch) => {
    try {
      dispatch({
        type: CROSS_AREA_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await CrossAreaCarBookingService.getAllCrossAreaCarBookingsByFilterByIdDriver(
          filter
        );
      if (res) {
        dispatch({
          type: CROSS_AREA_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CROSS_AREA_CAR_BOOKINGS_FAILED,
      });
    }
  };

export const getCrossAreaCarBookingByIdApprovedUserForManager =
  (idApproveUser) => async (dispatch) => {
    try {
      dispatch({
        type: CROSS_AREA_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await CrossAreaCarBookingService.getCrossAreaCarBookingByIdApprovedUserForManager(
          idApproveUser
        );
      if (res) {
        dispatch({
          type: CROSS_AREA_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CROSS_AREA_CAR_BOOKINGS_FAILED,
      });
    }
  };
export const postNewCrossAreaCarBooking = (newbooking, idUser) => async () => {
  try {
    console.log(newbooking, idUser);
    const res = await CrossAreaCarBookingService.postNewCrossAreaCarBooking(
      newbooking,
      idUser
    );
    if (res) {
      return res;
    }
  } catch (error) {
    return "error";
  }
};

export const getAllCrossAreaCarBookingsByFilter =
  (filter) => async (dispatch) => {
    try {
      dispatch({
        type: CROSS_AREA_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await CrossAreaCarBookingService.getAllCrossAreaCarBookingsByFilter(
          filter
        );
      if (res) {
        dispatch({
          type: CROSS_AREA_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CROSS_AREA_CAR_BOOKINGS_FAILED,
      });
    }
  };

export const getCrossAreaCarBookingByStartDateAndEndDate =
  (date) => async (dispatch) => {
    try {
      dispatch({
        type: CROSS_AREA_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await CrossAreaCarBookingService.getCrossAreaCarBookingByStartDateAndEndDate(
          date
        );
      if (res) {
        dispatch({
          type: CROSS_AREA_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CROSS_AREA_CAR_BOOKINGS_FAILED,
      });
    }
  };

export const getCrossAreaCarBookingByStartDate = (date) => async (dispatch) => {
  try {
    dispatch({
      type: CROSS_AREA_CAR_BOOKINGS_FETCHING,
    });
    const res =
      await CrossAreaCarBookingService.getCrossAreaCarBookingByStartDate(date);
    if (res) {
      dispatch({
        type: CROSS_AREA_CAR_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CROSS_AREA_CAR_BOOKINGS_FAILED,
    });
  }
};
