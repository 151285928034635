import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Box,
} from "@mui/material";

import GoogleMap from "./GoogleMap/GoogleMap.js";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import VehicleBrandAndModelService from "../../../../../services/vehicleBrandAndModel.service";

import EmployeeService from "../../../../../services/employee.service";
import userService from "../../../../../services/user.service.js";

const useStyles = makeStyles((theme) => ({
  FormControl: {
    width: "100%",
    paddingLeft: "10px !important",
    margin: "15px 0px !important",
  },
  PaddingRight: {
    paddingRight: "0px !important",
  },
  GridNameOfApprove: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  DivCoverNameOfApprove: {},
}));

const generateTimeOptions = (startTime = "-01:30") => {
  const times = [];
  const [startHour, startMinute] = startTime.split(":").map(Number);
  let startMinutes = startHour * 60 + startMinute + 30; // Adding 30 minutes

  for (let i = 0; i < 48; i++) {
    let hour = Math.floor(startMinutes / 60);
    let minute = startMinutes % 60;
    if (hour < 24) {
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      times.push(`${hour}:${minute}`);
      startMinutes += 30;
    }
  }
  return times;
};

const FormFlight = (props) => {
  const classes = useStyles();
  const { user: User } = useSelector((state) => state.auth);
  const [listBrandAndType, setListBrandAndType] = useState([]);
  const [isEmployee, setEmployee] = useState(null);
  const [data, setData] = useState("");
  const [dataTo, setDataTo] = useState("");
  const [endTimeOptions, setEndTimeOptions] = useState(generateTimeOptions());

  const getDataFromGoogleMap = (data) => {
    props.setValue("fromePlace", data);
    setData(data);
  };
  const getDataFromGoogleMapToplace = (data) => {
    props.setValue("toPlace", data);
    setDataTo(data);
  };

  useEffect(() => {
    console.log(User);
    if (props.watch("idTypeCar")) {
      VehicleBrandAndModelService.getVehicleBrandAndModelByTypeCarAndUseFor(
        "InAreaCar",
        props.getValues("idTypeCar")
      )
        .then((res) => {
          if (res.data) {
            console.log(res.data);
            setListBrandAndType(res.data);
          }
        })
        .catch((error) => {
          setListBrandAndType([]);
        });
    }
    if (User) {
      console.log("12");
      console.log(User);
      EmployeeService.getEmployeesByIdApproved(User.idUser)
        .then((res) => {
          if (res.status === 200) {
            setEmployee(res.data);
          } else {
            setEmployee([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      userService
        .getUserProfile(User.idUser)
        .then((res) => {
          if (res.data) {
            props.setValue(`company`, res.data.companyName);
            props.setValue(`costCenter`, res.data.costCenterCharge);
            props.setValue(`costElement`, res.data.costElementCharge);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.watch("idTypeCar")]);

  useEffect(() => {
    const newEndTimeOptions = generateTimeOptions(props.getValues("startTime"));
    setEndTimeOptions(newEndTimeOptions);
  }, [props.watch("startTime")]);

  return (
    <>
      <FormControl className={classes.FormControl}>
        <Controller
          rules={{ required: true }}
          control={props.control}
          defaultValue="oneWay"
          name="flight"
          render={(propsInController) => (
            <RadioGroup
              value={propsInController.field.value}
              onChange={propsInController.field.onChange}
            >
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={2}
                  className={classes.PaddingRight}
                >
                  <FormControlLabel
                    value="oneWay"
                    control={
                      <Radio
                        checked={propsInController.field.value === "oneWay"}
                      />
                    }
                    label="เที่ยวเดียว"
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <FormControlLabel
                    value="waiting"
                    control={
                      <Radio
                        checked={propsInController.field.value === "waiting"}
                      />
                    }
                    label="รอรับกลับ"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          )}
        />
      </FormControl>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "5px" }}
        sx={(theme) => ({
          display: "flex",
          // justifyContent: "space-between",
          border: "1px solid #EEEEEE",
          backgroundColor: "#FBFCFC",
          boxShadow:
            "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

          marginBottom: "20px",
          padding: "0 25px 25px 0",

          borderRadius: "15px",
        })}
      >
        <Grid item xs={12} sm={6} lg={6}>
          <InputLabel>{"จุดรับ"}</InputLabel>
          <Grid item xs={12} sm={12} lg={12}>
            <Controller
              render={(propsInController) => (
                <TextField
                  placeholder="ระบุต้นทาง"
                  label="ต้นทาง"
                  variant="outlined"
                  disabled
                  error={props.errors.fromPlace ? true : false}
                  helperText={
                    props.errors.fromPlace ? "กรุณาเลือกจุดต้นทาง" : false
                  }
                  fullWidth
                  multiline
                  value={propsInController.field.value}
                  onChange={propsInController.field.onChange}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              )}
              name="fromPlace"
              control={props.control}
              rules={{ required: true }}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <GoogleMap
              name="fromPlace"
              inputProps={{ ...props.setValue("fromPlace", data) }}
              value={data}
              handleGetDataFromGoogleMap={getDataFromGoogleMap}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <InputLabel>{"จุดส่ง"}</InputLabel>
          <Grid item xs={12} sm={12} lg={12}>
            <Controller
              render={(propsInController) => (
                <TextField
                  placeholder="ระบุปลายทาง"
                  label="ปลายทาง"
                  variant="outlined"
                  multiline
                  disabled
                  error={props.errors.toPlace ? true : false}
                  helperText={
                    props.errors.toPlace ? "กรุณาเลือกจุดปลายทาง" : false
                  }
                  fullWidth
                  value={propsInController.field.value}
                  onChange={propsInController.field.onChange}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              )}
              name="toPlace"
              rules={{ required: true }}
              control={props.control}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <GoogleMap
              name="ToPlace"
              inputProps={{ ...props.setValue("toPlace", dataTo) }}
              value={dataTo}
              handleGetDataFromGoogleMap={getDataFromGoogleMapToplace}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              render={(propsInController) => (
                <DatePicker
                  label="วันที่เดินทาง"
                  minDate={new Date()}
                  value={propsInController.field.value}
                  onChange={(date) => {
                    propsInController.field.onChange(date);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        ".MuiInputBase-root": { borderRadius: "15px" },
                      }}
                      fullWidth
                      {...params}
                    />
                  )}
                />
              )}
              name={"departureDate"}
              defaultValue={new Date()}
              control={props.control}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            render={(propsInController) => (
              <FormControl fullWidth>
                <InputLabel shrink>{"เวลาเริ่มออกรถ"}</InputLabel>
                <Select
                  native
                  label="เวลาเริ่มออกรถ"
                  value={propsInController.field.value}
                  onChange={(e) => {
                    propsInController.field.onChange(e.target.value);
                  }}
                  style={{ borderRadius: "15px" }}
                >
                  {generateTimeOptions().map((time, index) => {
                    if (time !== "23:30")
                      return (
                        <option key={index} value={time}>
                          {time}
                        </option>
                      );
                  })}
                </Select>
              </FormControl>
            )}
            name="startTime"
            control={props.control}
            defaultValue="00:00"
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Controller
            render={(propsInController) => (
              <FormControl fullWidth>
                <InputLabel shrink>{"เวลาที่สิ้นสุด"}</InputLabel>
                <Select
                  native
                  label="เวลาที่สิ้นสุด"
                  value={propsInController.field.value}
                  onChange={(e) => {
                    propsInController.field.onChange(e.target.value);
                  }}
                  style={{ borderRadius: "15px" }}
                >
                  {endTimeOptions.map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
            name="endTime"
            control={props.control}
            defaultValue="00:00"
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="จำนวนผู้โดยสาร"
            variant="outlined"
            error={props.errors.numberOfPassenger ? true : false}
            helperText={
              props.errors.numberOfPassenger ? "กรุณากรอกจำนวนผู้โดยสาร" : false
            }
            placeholder="ระบุจำนวนผู้โดยสาร"
            multiline
            fullWidth
            type="number"
            inputProps={{
              ...props.register("numberOfPassenger", { required: true }),
            }}
            InputProps={{
              style: {
                borderRadius: "15px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{"ประเภทรถ"}</InputLabel>
            <Controller
              render={(propsInController) => (
                <Select
                  label="ประเภทรถ"
                  error={props.errors.idTypeCar ? true : false}
                  helperText={
                    props.errors.idTypeCar ? "กรุณาเลือกประเภทรถ" : false
                  }
                  value={propsInController.field.value}
                  onChange={(e) => {
                    props.setValue("idVehicleBrandAndModel", null);
                    props.setValue("costBooking", "");
                    propsInController.field.onChange(e);
                  }}
                  style={{ borderRadius: "15px" }}
                >
                  <MenuItem value={2}>รถเก๋ง</MenuItem>
                  <MenuItem value={3}>รถกระบะ</MenuItem>
                  <MenuItem value={1}>รถตู้</MenuItem>
                  <MenuItem value={4}>รถบัส</MenuItem>
                </Select>
              )}
              name="idTypeCar"
              control={props.control}
              rules={{ required: true }}
              defaultValue=""
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            label="วัตถุประสงค์"
            variant="outlined"
            multiline
            rows={2}
            fullWidth
            inputProps={{ ...props.register("purpose") }}
            InputProps={{
              style: {
                borderRadius: "15px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Note"
            variant="outlined"
            multiline
            rows={2}
            fullWidth
            inputProps={{ ...props.register("note") }}
            InputProps={{
              style: {
                borderRadius: "15px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            placeholder="ระบุบริษัท"
            label="บริษัท"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink:
                props.getValues(`company`) === "" ||
                props.getValues(`company`) === undefined
                  ? false
                  : true,
            }}
            inputProps={{
              ...props.register(`company`),
            }}
            InputProps={{
              style: {
                borderRadius: "15px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            placeholder="ระบุ cost center"
            label="Cost Center"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink:
                // props.getValues(`costCenter`) === "" ||
                // props.getValues(`costCenter`) === undefined ||
                // props.getValues(`costElement`) === null
                //   ? false
                //   :
                true,
            }}
            inputProps={{
              ...props.register(`costCenter`),
              pattern: "\\d{4}[-]\\d{5}$",
            }}
            InputProps={{
              style: {
                borderRadius: "15px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            placeholder="ระบุ cost Element"
            label="Cost Element"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ...props.register(`costElement`),
              pattern: "\\d{4}[-]\\d{5}$",
            }}
            InputProps={{
              style: {
                borderRadius: "15px",
              },
            }}
          />
        </Grid>
        {isEmployee && (
          <Grid container item spacing={3} xs={12}>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                render={(propsInController) => (
                  <Autocomplete
                    options={isEmployee}
                    getOptionLabel={(option) =>
                      `${option.firstname_TH} ${option.lastname_TH}`
                    }
                    isOptionEqualToValue={(option, value) => option === value}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {`${option.firstname_TH} ${option.lastname_TH}`}
                      </Box>
                    )}
                    value={propsInController.field.value}
                    onChange={(e, value) => {
                      if (value) {
                        props.setValue(
                          "nameApproved",
                          `${value.firstname_TH} ${value.lastname_TH}`
                        );
                        props.setValue(
                          "departmentApproved",
                          value.departmentName
                        );
                        props.setValue("companyApproved", value.companyName);
                        props.setValue("idApproved", value.idEmployees);
                        propsInController.field.onChange(value);
                        // console.log("propsInController.field.textContent : ",propsInController.field.value)
                      } else {
                        props.setValue("nameApproved", "");
                        props.setValue("departmentApproved", "");
                        props.setValue("companyApproved", "");
                        propsInController.field.onChange(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="ระบุชื่อ-นามสกุลของคนที่จะ Approve"
                        label="ชื่อคนที่อนุมัติ"
                        error={props.errors.Approved ? true : false}
                        helperText={
                          props.errors.Approved ? "กรุณาระบุชื่อ" : false
                        }
                        fullWidth
                        sx={{
                          ".MuiInputBase-root": { borderRadius: "15px" },
                        }}
                      />
                    )}
                  />
                )}
                name="Approved"
                control={props.control}
                defaultValue={null}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    disabled
                    label="แผนกของคนอนุมัติ"
                    fullWidth
                    value={propsInController.field.value}
                    InputLabelProps={{
                      shrink:
                        props.getValues("departmentApproved") === ""
                          ? false
                          : true,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "15px",
                      },
                    }}
                  />
                )}
                name="departmentApproved"
                control={props.control}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    disabled
                    label="บริษัทของผู้อนุมัติ"
                    fullWidth
                    value={propsInController.field.value}
                    InputLabelProps={{
                      shrink:
                        props.getValues("companyApproved") === ""
                          ? false
                          : true,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "15px",
                      },
                    }}
                  />
                )}
                name="companyApproved"
                control={props.control}
                defaultValue=""
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default FormFlight;
