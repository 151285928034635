import {
  CROSS_AREA_CAR_POOL_BOOKINGS_FETCHING,
  CROSS_AREA_CAR_POOL_BOOKINGS_FAILED,
  CROSS_AREA_CAR_POOL_BOOKINGS_SUCCESS,
} from "./types";

import CrossAreaCarPoolBookingService from "../services/crossAreaCarPoolBooking.service";

export const getAllCrossAreaCarPoolBookings = () => async (dispatch) => {
  try {
    dispatch({
      type: CROSS_AREA_CAR_POOL_BOOKINGS_FETCHING,
    });
    const res =
      await CrossAreaCarPoolBookingService.getAllCrossAreaCarPoolBookings();
    if (res) {
      dispatch({
        type: CROSS_AREA_CAR_POOL_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CROSS_AREA_CAR_POOL_BOOKINGS_FAILED,
    });
  }
};

export const getCrossAreaCarPoolBookingByIdUser =
  (idUser) => async (dispatch) => {
    try {
      dispatch({
        type: CROSS_AREA_CAR_POOL_BOOKINGS_FETCHING,
      });
      const res =
        await CrossAreaCarPoolBookingService.getCrossAreaCarPoolBookingByIdUser(
          idUser
        );
      if (res) {
        dispatch({
          type: CROSS_AREA_CAR_POOL_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CROSS_AREA_CAR_POOL_BOOKINGS_FAILED,
      });
    }
  };

export const getCrossAreaCarPoolBookingByIdDriver =
  (idDriver) => async (dispatch) => {
    try {
      dispatch({
        type: CROSS_AREA_CAR_POOL_BOOKINGS_FETCHING,
      });
      const res =
        await CrossAreaCarPoolBookingService.getCrossAreaCarPoolBookingByIdDriver(
          idDriver
        );
      if (res) {
        dispatch({
          type: CROSS_AREA_CAR_POOL_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CROSS_AREA_CAR_POOL_BOOKINGS_FAILED,
      });
    }
  };

export const getCrossAreaCarPoolBookingByFilterByIdDriver =
  (filter) => async (dispatch) => {
    try {
      dispatch({
        type: CROSS_AREA_CAR_POOL_BOOKINGS_FETCHING,
      });
      const res =
        await CrossAreaCarPoolBookingService.getCrossAreaCarPoolBookingByFilterByIdDriver(
          filter
        );
      if (res) {
        dispatch({
          type: CROSS_AREA_CAR_POOL_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CROSS_AREA_CAR_POOL_BOOKINGS_FAILED,
      });
    }
  };

export const getCrossAreaCarPoolBookingByStartDate =
  (date) => async (dispatch) => {
    try {
      dispatch({
        type: CROSS_AREA_CAR_POOL_BOOKINGS_FETCHING,
      });
      const res =
        await CrossAreaCarPoolBookingService.getCrossAreaCarPoolBookingByStartDate(
          date
        );
      if (res) {
        dispatch({
          type: CROSS_AREA_CAR_POOL_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CROSS_AREA_CAR_POOL_BOOKINGS_FAILED,
      });
    }
  };

export const getCrossAreaCarPoolBookingByStartDateAndEndDate =
  (date) => async (dispatch) => {
    try {
      dispatch({
        type: CROSS_AREA_CAR_POOL_BOOKINGS_FETCHING,
      });
      const res =
        await CrossAreaCarPoolBookingService.getCrossAreaCarPoolBookingByStartDateAndEndDate(
          date
        );
      if (res) {
        dispatch({
          type: CROSS_AREA_CAR_POOL_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CROSS_AREA_CAR_POOL_BOOKINGS_FAILED,
      });
    }
  };

export const postNewCrossAreaCarPoolBooking = (newpoolbooking) => async () => {
  console.log("ooo", newpoolbooking);
  try {
    const res =
      await CrossAreaCarPoolBookingService.postNewCrossAreaCarPoolBooking(
        newpoolbooking
      );
    if (res) {
      return res;
    }
  } catch (error) {
    return "error";
  }
};
export const getCrossAreaCarPoolBookingByFilter =
  (filter) => async (dispatch) => {
    try {
      dispatch({
        type: CROSS_AREA_CAR_POOL_BOOKINGS_FETCHING,
      });
      const res =
        await CrossAreaCarPoolBookingService.getCrossAreaCarPoolBookingByFilter(
          filter
        );
      if (res) {
        dispatch({
          type: CROSS_AREA_CAR_POOL_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CROSS_AREA_CAR_POOL_BOOKINGS_FAILED,
      });
    }
  };
