import React, { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";

import { styled } from "@mui/styles";

import { Typography, Button, Grid, IconButton } from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import VehicleBrandAndModelService from "../../../../../services/vehicleBrandAndModel.service";
import CrossAreaCarBookingPassengerService from "../../../../../services/crossAreaCarBookingPassenger.service";
import EmployeesServices from "../../../../../services/employee.service.js";
import ModalShowmap from "./showMap/index.js";
import siteService from "../../../../../services/site.service.js";

require("dayjs/locale/th");
dayjs.locale("th");

const DivCoverReturn = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: "20px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const BackButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  color: "black",
  fontSize: "20px",
  left: "40px",
}));

const EditButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  color: "black",
  fontSize: "20px",
  right: "50px",
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: "30px",
}));

const DivContent = styled("div")(({ theme }) => ({
  width: "90%",
  maxWidth: "1100px",
  boxShadow:
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
  border: "1px solid #EEEEEE",
  backgroundColor: "#FBFCFC",
  borderRadius: "15px",
  padding: "20px",
  marginBottom: "50px",
}));

const DivTopic = styled("div")(({ theme }) => ({
  display: "flex",
  borderBottom: "1px solid #1565c0",
  marginTop: "24px",
}));

const TextMainTopic = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "bold",
  color: theme.palette.text.primary,
}));

const TextTopic = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "bold",
}));

const TextData = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  marginLeft: "10px",
  color: theme.palette.text.primary,
}));

const DivTopicAndData = styled("div")(({ theme }) => ({
  display: "flex",
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  marginTop: "0px",
}));

const DivTypeOfFlight = styled("div")(({ theme }) => ({
  width: "calc(100% - 68px)",
  marginTop: "24px",
}));

const DivTextFlight = styled("div")(({ theme }) => ({
  width: "100%",
  borderBottom: "1px solid #DCDCDC",
  marginTop: "24px",
}));

const DivPurpose = styled("div")(({ theme }) => ({
  width: "calc(100% - 68px)",
  marginTop: "24px",
}));

const DivGettingPlace = styled("div")(({ theme }) => ({
  width: "calc(100% - 68px)",
  marginTop: "24px",
}));
const ViewButton = styled(Button)({
  border: "1px solid #00bb77",
  width: "100%",
  fontSize: "20px",
  // backgroundColor: "#3970C2",
  color: "#00bb77",
});

const DetailCrossAreaCar = (props) => {
  const [booking, setBooking] = useState(null);
  const [car, setCar] = useState(null);
  const [passenger, setPassenger] = useState(null);
  const [isUserApproved, setUserApproved] = useState(null);
  const [isEmployee, setEmployee] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (props.location.state === undefined) {
      props.history.push("/admin/car_management");
    } else {
      let booking_temp = props.location.state.booking;
      siteService
        .getLocationDriverById(booking_temp.idDriver)
        .then((res) => {
          if (res.data) {
            booking_temp.LatDriver = res.data[0].LatDriver;
            booking_temp.LngDriver = res.data[0].LngDriver;
            setBooking(booking_temp);
          }
        })
        .catch((error) => {
          booking_temp.LatDriver = 0;
          booking_temp.LngDriver = 0;
          setBooking(booking_temp);
          console.log(error);
        });

      VehicleBrandAndModelService.getVehicleBrandAndModelById(
        props.location.state.booking.idVehicleBrandAndModel
      )
        .then((res) => {
          if (res.data) {
            setCar(res.data[0]);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      CrossAreaCarBookingPassengerService.getCrossAreaCarBookingPassengerByIdBooking(
        props.location.state.booking.idCrossAreaCarBooking
      )
        .then((res) => {
          if (res.data) {
            setPassenger(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    EmployeesServices.getEmployeesById(props.location.state.booking.idApproved)
      .then((res) => {
        if (res.data) {
          setEmployee(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.location.state]);

  const onCloseModalHandle = () => {
    setOpenModal(false);
  };

  const onClickOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      {booking && (!booking.isDriverFromCompany || car) && passenger && (
        <DivCoverReturn>
          <BackButton onClick={() => props.history.goBack()}>
            <ArrowBackIosNewIcon /> Back
          </BackButton>
          <Title>จองรถข้ามพื้นที่เฉพาะ</Title>
          {/* // <DivCoverContent> */}
          <EditButton
            onClick={() =>
              props.history.push(
                "/admin/car_management/cross_area/edit_detail",
                {
                  booking: booking,
                  passenger: passenger,
                }
              )
            }
          >
            <EditRoundedIcon />
          </EditButton>
          <DivContent>
            <DivTopic>
              <TextMainTopic>ผู้จอง</TextMainTopic>
            </DivTopic>
            <GridContainer container spacing={3}>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>ชื่อ :</TextTopic>
                  <TextData>{booking.name}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>เบอร์โทรศัพท์ :</TextTopic>
                  <TextData>{booking.telephoneMobile}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>Email :</TextTopic>
                  <TextData>{booking.email}</TextData>
                </DivTopicAndData>
              </Grid>
            </GridContainer>
            <DivTopic>
              <TextMainTopic>รายละเอียดการจอง</TextMainTopic>
            </DivTopic>
            <DivTypeOfFlight>
              <DivTopicAndData>
                <TextTopic>ประเภทการเดินทาง :</TextTopic>
                <TextData>
                  {booking.flight === "oneWay" ? "เที่ยวเดียว" : "ไป-กลับ"}
                </TextData>
              </DivTopicAndData>
            </DivTypeOfFlight>
            {booking.flight === "twoWay" && (
              <DivTextFlight>
                <TextTopic>ขาไป</TextTopic>
              </DivTextFlight>
            )}
            <GridContainer container spacing={3}>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>ต้นทาง :</TextTopic>
                  <TextData>{booking.fromPlace}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>ปลายทาง :</TextTopic>
                  <TextData>{booking.toPlace}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>จำนวนผู้โดยสาร :</TextTopic>
                  <TextData>{booking.numberOfPassenger}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>วันที่เดินทาง :</TextTopic>
                  <TextData>
                    {dayjs(booking.departureDate).format("DD MMMM YYYY")}
                  </TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>เวลาเริ่มออกรถ :</TextTopic>
                  <TextData>
                    {dayjs(booking.startTime).format("HH.mm น.")}
                  </TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>เวลาคืนรถ :</TextTopic>
                  <TextData>
                    {dayjs(booking.endTime).format("HH.mm น.")}
                  </TextData>
                </DivTopicAndData>
              </Grid>
            </GridContainer>
            {booking.flight === "twoWay" && (
              <>
                <DivTextFlight>
                  <TextTopic>ขากลับ</TextTopic>
                </DivTextFlight>
                <GridContainer container spacing={3}>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>ต้นทาง :</TextTopic>
                      <TextData>{booking.fromPlaceReturn}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>ปลายทาง :</TextTopic>
                      <TextData>{booking.toPlaceReturn}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>จำนวนผู้โดยสาร :</TextTopic>
                      <TextData>{booking.numberOfPassengerReturn}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>วันที่เดินทาง :</TextTopic>
                      <TextData>
                        {dayjs(booking.returnDate).format("DD MMMM YYYY")}
                      </TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>เวลาเริ่มออกรถ :</TextTopic>
                      <TextData>
                        {dayjs(booking.returnStartTime).format("HH.mm น.")}
                      </TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>เวลาคืนรถ :</TextTopic>
                      <TextData>
                        {dayjs(booking.returnEndTime).format("HH.mm น.")}
                      </TextData>
                    </DivTopicAndData>
                  </Grid>
                </GridContainer>
              </>
            )}
            <DivPurpose>
              <DivTopicAndData>
                <TextTopic>วัตถุประสงค์ :</TextTopic>
                <TextData>{booking.purpose}</TextData>
              </DivTopicAndData>
            </DivPurpose>
            {booking.statusManageCar === "Success" ? (
              <>
                <DivTopic>
                  <TextMainTopic>
                    {booking.isDriverFromCompany
                      ? "รายละเอียดรถยนต์ในบริษัท"
                      : "รายละเอียดรถยนต์จากบริษัทอื่น"}
                  </TextMainTopic>
                </DivTopic>
                <GridContainer container spacing={3}>
                  {booking.isDriverFromCompany ? (
                    <>
                      <Grid item md={4}>
                        <DivTopicAndData>
                          <TextTopic>ยี่ห้อ :</TextTopic>
                          <TextData>{car.brand}</TextData>
                        </DivTopicAndData>
                      </Grid>
                      <Grid item md={4}>
                        <DivTopicAndData>
                          <TextTopic>รุ่น :</TextTopic>
                          <TextData>{car.model}</TextData>
                        </DivTopicAndData>
                      </Grid>
                    </>
                  ) : null}
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>ทะเบียนรถ :</TextTopic>
                      <TextData>{booking.plate_No}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  {booking.isDriverFromCompany ? (
                    <Grid item md={4}>
                      <DivTopicAndData>
                        <TextTopic>จำนวนที่นั่ง :</TextTopic>
                        <TextData>{car.capacity}</TextData>
                      </DivTopicAndData>
                    </Grid>
                  ) : null}
                </GridContainer>
              </>
            ) : null}
            <DivTopic>
              <TextMainTopic>รายละเอียดผู้โดยสาร</TextMainTopic>
            </DivTopic>
            <DivGettingPlace>
              <DivTopicAndData>
                <TextTopic>ประเภทจุดรับ :</TextTopic>
                <TextData>
                  {booking.gettingPoint === "onePoint"
                    ? "จุดเดียว"
                    : "รับรายคน"}
                </TextData>
              </DivTopicAndData>
            </DivGettingPlace>

            {passenger.map((passen, index) => (
              <Fragment key={passen.id}>
                {booking.gettingPoint === "manyPoint" && (
                  <TextTopic
                    style={{
                      marginTop: "24px",
                      borderBottom: "1px solid #DCDCDC",
                    }}
                  >
                    ผู้โดยสารคนที่ {index + 1}
                  </TextTopic>
                )}
                <GridContainer container spacing={3}>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>ชื่อ :</TextTopic>
                      <TextData>{passen.name}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>เบอร์โทรศัพท์ :</TextTopic>
                      <TextData>{passen.telephoneMobile}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>Email :</TextTopic>
                      <TextData>{passen.email}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>บริษัท :</TextTopic>
                      <TextData>{passen.company}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>Cost Center :</TextTopic>
                      <TextData>{passen.costCenter}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={4}>
                    <DivTopicAndData>
                      <TextTopic>Cost Element :</TextTopic>
                      <TextData>{passen.costElement}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={6}>
                    <DivTopicAndData>
                      <TextTopic>จุดรับ :</TextTopic>
                      <TextData>{passen.fromPlace}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  {booking.flight === "twoWay" && (
                    <Grid item md={6}>
                      <DivTopicAndData>
                        <TextTopic>จุดรับ :</TextTopic>
                        <TextData>{passen.fromPlaceReturn}</TextData>
                      </DivTopicAndData>
                    </Grid>
                  )}
                </GridContainer>
              </Fragment>
            ))}
            <DivTopic>
              <TextMainTopic>ผู้อนุมัติ</TextMainTopic>
            </DivTopic>
            {isEmployee && (
              <Fragment key={isEmployee[0].idEmployees}>
                <GridContainer container spacing={3}>
                  <Grid item md={6}>
                    <DivTopicAndData>
                      <TextTopic>ชื่อคนอนุมัติ :</TextTopic>
                      <TextData>{isEmployee[0].firstname_TH}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item md={6}>
                    <DivTopicAndData>
                      <TextTopic>แผนกของคนอนุมัติ :</TextTopic>
                      <TextData>{isEmployee[0].departmentName}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item xs={12}>
                    <DivTopicAndData>
                      <TextTopic>บริษัทของคนอนุมัติ :</TextTopic>
                      <TextData>{isEmployee[0].companyName}</TextData>
                    </DivTopicAndData>
                  </Grid>
                  <Grid item xs={12}>
                    <ViewButton onClick={onClickOpenModal}>
                      แสดงแผนที่
                    </ViewButton>
                  </Grid>
                </GridContainer>
              </Fragment>
            )}
          </DivContent>
          <ModalShowmap
            open={openModal}
            onCloseHandle={onCloseModalHandle}
            inforInCar={booking}
          />
        </DivCoverReturn>
      )}
    </>
  );
};

export default DetailCrossAreaCar;
