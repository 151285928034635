import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  styled,
  Grid,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import Container from "@mui/material/Container";

import SearchIcon from "@mui/icons-material/Search";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector, useDispatch } from "react-redux";
import DateAdapter from "@mui/lab/AdapterDayjs";
import Item from "./components/Item";
import maintenanceService from "../../../../../services/maintenance.service";
import { getUserProfile } from "../../../../../actions/user";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  CreateButton: {
    height: "50px",
    width: "200px",
    display: "flex",
    color: "white",
    fontSize: "15px",
    borderRadius: "10px",
    margin: "3px",
    backgroundColor: theme.palette.primary.main,
    ["&:hover"]: {
      backgroundColor: theme.palette.primary.dark,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "170px",
    },
  },
  IconImage: {
    weight: "26px",
    height: "26px",
    marginRight: "3px",
  },
}));

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  maxWidth: 1600,
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const NavTopButton = styled("div")(({ theme }) => ({
  height: "60px",
  // marginTop: "20px",
  "& .CurrentTable": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
}));

const History = () => {
  const classes = useStyles();
  const { register, getValues, control } = useForm();
  const [requestedMaintenances, setRequestedMaintenance] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showHistory, setHistory] = useState(0);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    maintenanceService
      .getAllMyHistoryMaintenanceRequest({ idUser: currentUser.idUser })
      .then((res) => {
        setRequestedMaintenance(res.data.data);
        setLoading(false);
        setError(res.data.error);
      });
  }, []);

  const onClickHistory = () => {
    setHistory(1);
  };
  const onClickStatus = () => {
    setHistory(0);
  };
  const onClickSearch = () => {
    setLoading(true);
    setRequestedMaintenance([]);

    maintenanceService
      .getAllMyHistoryMaintenanceRequest({
        maintenanceType: getValues("maintenanceType") || null,
        status: getValues("status") || null,
        date: getValues("date") || null,
        idUser: currentUser.idUser,
      })
      .then((res) => {
        setRequestedMaintenance(res.data.data);
        setLoading(false);
        setError(res.data.error);
      });
  };

  return (
    <Container maxWidth="xl">
      <div className="page">
        <Root>
          <Grid container spacing={1} marginBottom={2} marginTop={3}>
            <Grid item xs={12} sm={6}>
              <Typography fontSize={34}>ประวัติการแจ้งซ่อมแซม</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NavTopButton>
                {showHistory === 0 && (
                  <Button
                    className={`${classes.CreateButton}`}
                    onClick={onClickHistory}
                  >
                    <img
                      className={`${classes.IconImage}`}
                      src={"/assets/Image/Icon/clock_white.png"}
                      alt="Logo"
                    />
                    ประวัติการทำรายการ
                  </Button>
                )}
                {showHistory === 1 && (
                  <Button
                    className={`${classes.CreateButton}`}
                    onClick={onClickStatus}
                  >
                    <img
                      className={`${classes.IconImage}`}
                      src={"/assets/Image/Icon/list.png"}
                      alt="Logo"
                    />
                    สถานะรายการ
                  </Button>
                )}
              </NavTopButton>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid
              container
              sx={(theme) => ({
                display: "flex",
                justifyContent: "space-between",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                // padding: "25px 30px 25px 30px",

                borderRadius: "15px",

                [theme.breakpoints.down("sm")]: {
                  // padding: "25px 30px 25px 15px",
                },
                [theme.breakpoints.down("md")]: {
                  // padding: "25px 30px 25px 15px",
                },
              })}
            >
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "25px",
                  marginLeft: "15px",
                  marginRight: "35px",
                }}
              >
                <Grid item xs={12} sm={4}>
                  <TextField
                    inputProps={{ ...register("maintenanceType") }}
                    label="ประเภทงานซ่อม"
                    select
                    fullWidth
                    defaultValue={0}
                    InputProps={{
                      style: {
                        borderRadius: "5px",
                      },
                    }}
                  >
                    <MenuItem value={0}>ทั้งหมด</MenuItem>
                    <MenuItem value={1}>ซ่อมแซมทั่วไป</MenuItem>
                    <MenuItem value={2}>งานไฟฟ้า</MenuItem>
                    <MenuItem value={3}>งานประปา</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                    <Controller
                      name="date"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <MobileDatePicker
                          {...field}
                          label="วันที่แจ้ง"
                          inputFormat="DD MMMM YYYY"
                          componentsProps={{
                            actionBar: {
                              actions: ["clear", "cancel", "accept"],
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              InputProps={{
                                style: {
                                  borderRadius: "5px",
                                },
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {showHistory === 0 && (
                    <TextField
                      inputProps={{ ...register("status") }}
                      label="สถานะ"
                      InputProps={{
                        style: {
                          borderRadius: "5px",
                        },
                      }}
                      select
                      fullWidth
                      defaultValue={0}
                    >
                      <MenuItem value={0}>ทั้งหมด</MenuItem>
                      <MenuItem value={1}>กำลังรออนุมัติ</MenuItem>
                      <MenuItem value={2}>กำลังดำเนินการ</MenuItem>
                      <MenuItem value={3}>ดำเนินการเสร็จสิ้น</MenuItem>
                      <MenuItem value={4}>ไม่อนุมัติ</MenuItem>
                      <MenuItem value={5}>ยกเลิก</MenuItem>
                    </TextField>
                  )}
                  {showHistory === 1 && (
                    <TextField
                      inputProps={{ ...register("status") }}
                      label="สถานะ"
                      InputProps={{
                        style: {
                          borderRadius: "5px",
                        },
                      }}
                      select
                      fullWidth
                      defaultValue={0}
                    >
                      <MenuItem value={0}>ทั้งหมด</MenuItem>
                    </TextField>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={(theme) => ({
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",

                  marginBottom: "10px",
                  [theme.breakpoints.down("sm")]: {
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  },
                })}
              >
                <Button
                  variant="contained"
                  onClick={onClickSearch}
                  sx={(theme) => ({
                    maxWidth: "100px",
                    maxHeight: "40px",
                    marginTop: "10px",
                    marginInline: "20px",
                    borderRadius: "15px",
                  })}
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </Button>{" "}
              </Grid>
            </Grid>
            {loading && (
              <Grid item xs={12}>
                <Typography fontSize={20} textAlign="center">
                  กำลังโหลดข้อมูล...
                </Typography>
              </Grid>
            )}
            {(!loading && requestedMaintenances.length === 0) ||
              (showHistory === 0 &&
                requestedMaintenances.filter((data) => data.status !== "3")
                  .length === 0) ||
              (showHistory === 1 &&
                requestedMaintenances.filter((data) => data.status === "3")
                  .length === 0 && (
                  <Grid item xs={12}>
                    <Typography fontSize={20} textAlign="center">
                      ไม่พบประวัติการแจ้งซ่อม
                    </Typography>
                  </Grid>
                ))}
            {showHistory === 0 &&
              requestedMaintenances
                .filter((data) => data.status !== "3")
                .toReversed()
                .map((data, index) => (
                  <Grid
                    key={`maintenance_${index}`}
                    item
                    xs={12}
                    md={6}
                    lg={12}
                    style={{ paddingRight: "0 !important", marginTop: "5px" }}
                  >
                    <Item data={data} />
                  </Grid>
                ))}
            {showHistory === 1 &&
              requestedMaintenances
                .filter((data) => data.status === "3")
                .toReversed()
                .map((data, index) => (
                  <Grid
                    key={`maintenance_${index}`}
                    item
                    xs={12}
                    md={6}
                    lg={12}
                    style={{ paddingRight: "0 !important", marginTop: "5px" }}
                  >
                    <Item data={data} />
                  </Grid>
                ))}
          </Grid>
        </Root>
      </div>
    </Container>
  );
};

export default History;
